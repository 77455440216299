import { Component } from "@angular/core";

@Component({
  selector: "ngx-footer",
  styleUrls: ["./footer.component.scss"],
  template: `
    <span class="created-by"> ©2025 AGRO-METRI, Tous les droits sont réservés.</span>
    <div class="socials">
      <a href="https://www.linkedin.com/company/agro-lora" target="_blank" class="ion ion-social-youtube"></a>
      <a href="https://www.facebook.com/people/AGROLoRa/61564026097571/" target="_blank" class="ion ion-social-facebook"></a>
      <a href="https://www.linkedin.com/company/agro-lora" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
  `,
})
export class FooterComponent {}
