<nb-card class="alert-details-modal">
  <nb-card-header>
    <div class="alert-header-content">
      <img [src]="getAlertImage(alert?.type)" alt="Alert Icon" class="alert-icon" />
      <h3 class="alert-title">{{ getAlertTitle(alert?.type) }}</h3>
    </div>
  </nb-card-header>

  <nb-card-body>
    <p class="alert-description">
      Détection: le débit est inférieur de <b>40%</b> au débit nominal (/24h)
    </p>

    <div class="alert-table">
      <div class="table-row">
        <div class="table-cell">{{ alert?.time | date: 'dd/MM/yyyy' }}</div>
        <div class="table-cell">{{ alert?.value }}</div>
        <div class="table-cell">{{ alert?.nom_groupe }}</div>
        <div class="table-cell">{{ alert?.libelle }}</div>
        <div class="table-cell">{{ alert?.matricule }}</div>
        <div class="table-cell">{{ alert?.diametre }} mm</div>
        <div class="table-cell">{{ alert?.debit_nominal }} m³</div>
      </div>
    </div>
  </nb-card-body>

  <nb-card-footer>
    <button nbButton (click)="closeModal()" class="close-btn">Fermer</button>
  </nb-card-footer>
</nb-card>
