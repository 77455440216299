import { WaterVolumeResponse } from "../types/water-volume-response";

export class DateFormatter {
  public static formatDate(date: Date | null): string {
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  static formatLocalTimePourcentage(timeStr: string): string {
    if (!timeStr) return "";
    const date = new Date(timeStr);
    return !isNaN(date.getTime()) ? date.toISOString() : "";
  }


static  convertToFrenchDate(date: string | Date): string {
    const inputDate = typeof date === 'string' ? new Date(date) : date;

    const formatter = new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });

    return formatter.format(inputDate);
  }

  
  static formatLocalTime(dateTimeString: string | Date): string {
    if (!dateTimeString) return "";
    const localTime = new Date(dateTimeString);
    if (isNaN(localTime.getTime())) return "";

    return localTime.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  }

  static formatDatestr(dateStr: string): string {
    const date = new Date(dateStr);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  static formatChartDataBasin(
    data: WaterVolumeResponse[],
    timeViewMode: string
  ): {
    labels: string[];
    values: number[];
  } {
    if (!Array.isArray(data) || data.length === 0) {
      return { labels: [], values: [] };
    }

    const sortedData = [...data]
      .sort((a, b) => {
        const dateA = new Date(a.time);
        const dateB = new Date(b.time);
        return dateA.getTime() - dateB.getTime();
      })
      .filter((item) => {
        const date = new Date(item.time);
        return !isNaN(date.getTime());
      });

    const formattedData = {
      labels: [] as string[],
      values: [] as number[],
    };

    sortedData.forEach((item) => {
      const { time, volume } = item;
      if (!time || typeof time !== "string") return;

      const date = new Date(time);
      if (isNaN(date.getTime())) return;

      const parsedVolume = volume === null ? 0 : parseFloat(volume.toString());
      if (isNaN(parsedVolume)) return;

      const formattedDate =
        timeViewMode === "hourly"
          ? date.toLocaleTimeString("fr-FR", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })
          : date.toLocaleDateString("fr-FR", {
              day: "2-digit",
              month: "short",
            });

      formattedData.labels.push(formattedDate);
      formattedData.values.push(Number(parsedVolume.toFixed(2)));
    });

    return formattedData;
  }

  static formatChartData(data: any[]) {
    if (!Array.isArray(data) || data.length === 0) {
      return { labels: [], values: [], dates: [] };
    }

    const reversedData = [...data]
      .filter((item) => item.date && !isNaN(new Date(item.date).getTime()))
      .reverse();

    return {
      labels: reversedData.map((item) => {
        const date = new Date(item.date);
        return date.toLocaleDateString("fr-FR", {
          day: "2-digit",
          month: "short",
        });
      }),
      values: reversedData.map((item) =>
        typeof item.difference === "number" ? item.difference / 1000 : 0
      ),
      dates: reversedData.map((item) => item.date.split("T")[0]),
    };
  }
}
