import {
  Component,
  AfterViewInit,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
  EventEmitter,
  Output
} from "@angular/core";
import * as L from "leaflet";
import { BasinService } from "../../../@core/services/basin/basin.service";
import { CounterService } from "../../../@core/services/counter/counter.service";
import { WaterPerformanceComponent } from "./water-performance/water-performance.component";
import {
  BasinResponse,
  CustomMapOptions,
  PlotResponse,
} from "../../../@core/types";
import { DateFormatter } from "../../../@core/utils/format-date";
import { Subscription } from "rxjs";
import { SharedService } from "../../../@shared/services/shared-service.service";

@Component({
  selector: "ngx-basin",
  templateUrl: "./basin.component.html",
  styleUrls: ["./basin.component.scss"],
})
export class BasinComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(WaterPerformanceComponent)
  waterPerformanceComponent: WaterPerformanceComponent;
  @Output() basinSelected = new EventEmitter<number>();
  
  isLoading = true;
  selectedCardId: number | null = null;
  selectedId = 36;
  selectedPiezometreData: any = null;
  private mapReady = false;

  lastNiveauValue: number | null = null;
  lastVolumeValue: string | null = null;
  waterLevel: number | null = null;
  waterLevels: { [key: number]: number } = {};
  currentTime = "";
  selectedVolume = "0 m³";
  selectedPourcentage = "0";
  selectedNiveau = "0 m";
  private lastValueSubscription: Subscription;
  
  @Input() exploitationOptions: string[] = [];
  @Input() selectedExploitation: string = "";
  
  private map1: L.Map | undefined;
  private plotLayerGroup: L.LayerGroup | undefined;
  private markerGroup: {
    [key: string]: { marker: L.Marker; circle: L.Circle };
  } = {};

  private isAlive = true;
  filteredBasins: BasinResponse[] = [];
  BasinStatus: BasinResponse[] = [];
  Ploat: PlotResponse = null;
  private _selectedBasin: number | null = null;

  @Input() selectedBasinMatricule = "";

  responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  @Input()
  set selectedBasin(value: number | null) {
    if (value !== this._selectedBasin) {
      this._selectedBasin = value;
      if (value !== null) {
        this.handleBasinSelection(value);
      }
    }
  }

  constructor(
    private basinService: BasinService,
    private counterService: CounterService,
    private sharedService: SharedService
  ) {
    this.selectedVolume = "N/A";
    this.selectedNiveau = "N/A";
    this.selectedPourcentage = "N/A";
    this.waterLevel = null;
    this.currentTime = null;
    this.isLoading = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["selectedExploitation"]) {
      this.loadBasinStatus();
    }
  }

  ngOnInit(): void {
    this.loadInitialData();
  }

  


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initMap();
      this.mapReady = true;
  
      if (this.selectedPiezometreData?.libelle) {
        this.highlightMarker(this.selectedPiezometreData.libelle);
      }
      if (this.filteredBasins.length > 0) {
        this.addFilteredBasinMarkers();
        this.updateMapBounds();
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    if (this.map1) {
      this.map1.remove();
    }
    if (this.plotLayerGroup) {
      this.plotLayerGroup.clearLayers();
    }
    if (this.lastValueSubscription) {
      this.lastValueSubscription.unsubscribe();
    }
  }

  private loadInitialData(): void {
    this.isLoading = true;
    this.loadBasinStatus();
    this.getPlot();
  }

  private loadBasinStatus(): void {
    if (this.selectedExploitation) {
      this.basinService
        .BasinPerExploitatoin(this.selectedExploitation)
        .subscribe({
          next: (response) => {
            this.BasinStatus = response;
            this.filteredBasins = response.filter(
              (basin) => basin.nom_groupe === this.selectedExploitation
            );
            this.addFilteredBasinMarkers();

            if (this.filteredBasins.length > 0) {
              this.onCardClick(this.filteredBasins[0].id_capteur);
            } else {
              this.resetUI();
            }
            this.isLoading = false;

            this.updateMapBounds();
          },
          error: (err) => {
            console.error("[Basin] Error:", err);
            this.isLoading = false;
          },
        });
    } else {
      this.basinService.BasinStatus().subscribe({
        next: (response) => {
          this.BasinStatus = response;
          this.filteredBasins = response;
          this.addFilteredBasinMarkers();
          if (response.length > 0) {
            this.onCardClick(response[0].id_capteur);
          }
          this.isLoading = false;
        },
        error: (err) => {
          console.error("[Basin] Error:", err);
          this.isLoading = false;
        },
      });
    }
  }


  onCardClick(id: number): void {
    if (this.selectedCardId === id) {
      return;
    }

    this.selectedCardId = id;
    this.selectedId = id;
    this.isLoading = true;

    const selectedBasin = this.BasinStatus.find(
      (basin) => basin.id_capteur === id
    );
    if (selectedBasin) {
      this.selectedBasinMatricule = selectedBasin.matricule;

      if (
        this.isValidCoordinates(selectedBasin.latitude, selectedBasin.longitude)
      ) {
        this.highlightMarker(selectedBasin.libelle);
        this.centerMapOnBasin(selectedBasin);
      }
      this.basinSelected.emit(id);
    }

    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

  onPourcentageUpdated(percentage: string): void {
    this.selectedPourcentage = percentage;
    const numericValue = parseFloat(percentage.replace("%", ""));
    if (!isNaN(numericValue)) {
      this.waterLevel = numericValue;
      this.currentTime = new Date().toISOString();
    }
    this.isLoading = false;
  }

  onVolumeUpdated(volume: string): void {
    this.selectedVolume = volume;
    this.isLoading = false;
  }

  onNiveauUpdated(niveau: string): void {
    this.selectedNiveau = niveau;
    this.isLoading = false;
  }

  private updateBasinData(response: any): void {
    if (response.percentage?.length > 0) {
      const lastNonNullPercentage = response.percentage
        .slice()
        .reverse()
        .find((data) => data.pourcentage !== null);

      if (
        lastNonNullPercentage &&
        lastNonNullPercentage.pourcentage !== undefined
      ) {
        this.updateUIWithNewData(
          Number((lastNonNullPercentage.pourcentage * 100).toFixed(2)),
          DateFormatter.formatLocalTimePourcentage(lastNonNullPercentage.time)
        );
      }
    }

    if (response.volume?.length > 0) {
      const volumeData = response.volume.find((data) => data.volume !== null);
      if (volumeData && volumeData.volume !== undefined) {
        this.lastVolumeValue = `${Number(volumeData.volume).toFixed(2)} m³`;
      }
    }

    if (response.niveau?.length > 0) {
      const niveauData = response.niveau.find((data) => data.niveau !== null);
      if (niveauData && niveauData.niveau !== undefined) {
        this.lastNiveauValue = Number(Number(niveauData.niveau).toFixed(2));
      }
    }
  }

  private resetUI(): void {
    this.selectedId = 36;
    this.selectedVolume = "0 m³";
    this.selectedPourcentage = "0";
    this.selectedNiveau = "0 m";
    this.waterLevel = null;
    this.currentTime = "";
    this.lastNiveauValue = null;
    this.lastVolumeValue = null;
  }

  private resetValues(): void {
    this.waterLevel = null;
    this.currentTime = null;
    this.lastVolumeValue = null;
    this.lastNiveauValue = null;
    this.selectedVolume = "N/A";
    this.selectedNiveau = "N/A";
    this.selectedPourcentage = "N/A";
  }

  private updateUIWithNewData(value: number, currentTime: string): void {
    if (value !== undefined && value !== null) {
      this.waterLevel = value;
      this.currentTime = currentTime;
    } else {
      this.waterLevel = null;
      this.currentTime = null;
    }
  }


private getPlot(): void {
  this.counterService.parcelles().subscribe({
    next: (response) => {
      this.Ploat = response;
      this.addPlotsToMap();
    },
    error: (err) => console.error("Error fetching plot data:", err),
  });
}

private initMap(): void {
  const mapElement = document.getElementById('map1');
  if (!mapElement) {
    console.error('Map container not found');
    return;
  }

  const mapOptions: CustomMapOptions = {
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: "topleft" as L.ControlPosition,
      title: "Plein écran",
      titleCancel: "Quitter le plein écran",
    },
  };

  try {
    this.map1 = L.map('map1', mapOptions).setView([32.2862, -6.5499], 15);
    this.setupMapLayers();
    this.setupMapControls();

    window.addEventListener('resize', () => {
      if (this.map1) {
        this.map1.invalidateSize();
      }
    });
  } catch (error) {
    console.error('Error initializing map:', error);
  }
}

private setupMapLayers(): void {
  if (!this.map1) return;

  L.tileLayer(
    "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    { attribution: "Agro" }
  ).addTo(this.map1);

  L.tileLayer(
    "https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}",
    { attribution: "Lora" }
  ).addTo(this.map1);

  this.plotLayerGroup = L.layerGroup().addTo(this.map1);
}

private setupMapControls(): void {
  this.addPrintControl();
  this.addLegendControl();
  this.addCoordinatesControl();
}

private addPrintControl(): void {
  if (!this.map1) return;
  const PrintControl = L.Control.extend({
    options: { position: "topleft" },
    onAdd: () => this.createPrintButton(),
  });
  new PrintControl().addTo(this.map1);
}

private handleBasinSelection(basinId: number): void {
  const selectedBasin = this.BasinStatus.find(
    (basin) => basin.id_capteur === basinId
  );
  if (selectedBasin) {
    this.selectedBasinMatricule = selectedBasin.matricule;
    this.onCardClick(selectedBasin.id_capteur);
  }
}

private filterBasins(): void {
  if (!this.BasinStatus || this.BasinStatus.length === 0) {
    this.filteredBasins = [];
    return;
  }

  if (!this.selectedExploitation) {
    this.filteredBasins = [...this.BasinStatus];
  } else {
    this.filteredBasins = this.BasinStatus.filter(
      (basin) => basin.nom_groupe === this.selectedExploitation
    );
  }

  this.clearExistingMarkers();
  this.addFilteredBasinMarkers();

  if (this.filteredBasins.length > 0) {
    this.onCardClick(this.filteredBasins[0].id_capteur);
  } else {
    this.resetUI();
  }

  this.updateMapBounds();
}

private addFilteredBasinMarkers(): void {
  this.filteredBasins.forEach((basin) => {
    if (this.isValidCoordinates(basin.latitude, basin.longitude)) {
      this.createBasinMarker(basin);
    }
  });
}

private createBasinMarker(basin: BasinResponse): void {
  if (!this.map1) return;

  const id = basin.libelle || "";
  const icon = this.createBasinIcon(basin);
  const marker = L.marker([basin.latitude, basin.longitude], { icon });

  const popupContent = this.createBasinPopupContent(basin);
  marker.bindPopup(popupContent, {
    maxWidth: 300,
    className: "basin-marker-popup",
  });

  marker.addTo(this.map1);

  const circle = this.createBasinCircle(basin).addTo(this.map1);
  this.markerGroup[id] = { marker, circle };

  marker.on('click', () => this.onCardClick(basin.id_capteur));
}

private createBasinIcon(basin: BasinResponse): L.DivIcon {
  const matriculeClass = this.normalizeString(basin.libelle || "");
  return L.divIcon({
    className: `custom-marker marker-${matriculeClass}`,
    html: `
      <div class="marker-container">
        <img src="assets/images/washbasin.png" alt="marker" class="marker-image">
        <div class="marker-label">${basin.libelle}</div>
      </div>
    `,
    iconSize: [60, 60],
    iconAnchor: [30, 30],
  });
}

private createBasinPopupContent(basin: BasinResponse): string {
  return `
    <div class="basin-popup">
      <h4>${basin.libelle || "N/A"}</h4>
      <ul>
        <li><strong>Matricule:</strong> ${basin.matricule || "N/A"}</li>
        <li><strong>Nom Groupe:</strong> ${basin.nom_groupe || "N/A"}</li>
        <li><strong>Date Installation:</strong> ${basin["date installation"] || "N/A"}</li>
        <li><strong>Volume Total:</strong> ${basin.volume_total || "N/A"}</li>
      </ul>
    </div>
  `;
}

private createBasinCircle(basin: BasinResponse): L.Circle {
  return L.circle([basin.latitude, basin.longitude], {
    color: "#4CAF50",
    fillColor: "#4CAF50",
    fillOpacity: 0.2,
    radius: 30,
    weight: 2,
  });
}

private highlightMarker(libelle: string): void {
  if (!libelle || !this.markerGroup[libelle]) return;

  Object.values(this.markerGroup).forEach(({ circle, marker }) => {
    circle.setStyle({
      fillOpacity: 0.2,
      weight: 2,
    });
    circle.setRadius(30);
    const el = marker.getElement();
    if (el) el.classList.remove("highlighted");
  });

  const { marker, circle } = this.markerGroup[libelle];
  circle.setStyle({
    fillOpacity: 0.4,
    weight: 3,
  });
  circle.setRadius(40);

  const markerElement = marker.getElement();
  if (markerElement) {
    markerElement.classList.add("highlighted");
  }
}

private addPlotsToMap(): void {
  if (!this.map1 || !this.Ploat || !this.plotLayerGroup) return;

  this.plotLayerGroup.clearLayers();

  const plotStyle = {
    color: "#FFFFFF",
    weight: 2,
    opacity: 0.8,
    fillOpacity: 0.2,
    fillColor: "#106A77",
  };

  const geoJsonLayer = L.geoJSON(this.Ploat as GeoJSON.FeatureCollection, {
    style: () => plotStyle,
    onEachFeature: (feature, layer) => {
      const popupContent = this.createPlotPopupContent(feature);
      layer.bindPopup(popupContent);
    },
  }).addTo(this.plotLayerGroup);

  if (geoJsonLayer.getBounds().isValid()) {
    this.map1.fitBounds(geoJsonLayer.getBounds());
  }
}

private createPlotPopupContent(feature: any): string {
  return `
    <div class="plot-popup">
      <h4 class="plot-title">Plot Information</h4>
      <div class="plot-info">
        <p><strong>Culture:</strong> ${feature.properties?.culture || "Unknown"}</p>
      </div>
    </div>
  `;
}

private normalizeString(str: string): string {
  return str.toLowerCase().trim().replace(/\s+/g, "-");
}

private isValidCoordinates(lat: number, lng: number): boolean {
  return (
    lat !== null &&
    lng !== null &&
    !isNaN(lat) &&
    !isNaN(lng) &&
    lat !== 0 &&
    lng !== 0 &&
    lat >= -90 &&
    lat <= 90 &&
    lng >= -180 &&
    lng <= 180
  );
}

private centerMapOnBasin(basin: BasinResponse): void {
  if (this.map1 && basin.latitude && basin.longitude) {
    this.map1.setView([basin.latitude, basin.longitude], 19);
  }
}

private updateMapBounds(): void {
  if (!this.map1 || this.filteredBasins.length === 0) return;

  const validBasins = this.filteredBasins.filter((basin) =>
    this.isValidCoordinates(basin.latitude, basin.longitude)
  );

  if (validBasins.length > 0) {
    const bounds = L.latLngBounds(
      validBasins.map((basin) => [basin.latitude, basin.longitude])
    );
    this.map1.fitBounds(bounds, { padding: [50, 50] });
  }
}

private printMap(): void {
  if (!this.map1) return;

  const style = document.createElement("style");
  style.innerHTML = `
    @media print {
      body * { visibility: hidden; }
      #map1, #map1 * { visibility: visible; }
      #map1 {
        position: fixed !important;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }
      .leaflet-control-container { display: none !important; }
    }
  `;

  document.head.appendChild(style);
  window.print();
  document.head.removeChild(style);
}


private createPrintButton(): HTMLElement {
  const container = L.DomUtil.create(
    "div",
    "leaflet-control-zoom leaflet-bar leaflet-control"
  );
  const button = L.DomUtil.create(
    "a",
    "leaflet-control-print",
    container
  ) as HTMLAnchorElement;
  button.href = "#";
  button.title = "Imprimer";
  button.innerHTML = '<i class="fas fa-print"></i>';

  L.DomEvent.on(button, "click", (e) => {
    L.DomEvent.preventDefault(e);
    L.DomEvent.stopPropagation(e);
    this.printMap();
  });

  return container;
}

private addLegendControl(): void {
  if (!this.map1) return;
  const legend = new L.Control({ position: "bottomleft" });
  legend.onAdd = this.createLegend;
  legend.addTo(this.map1);
}

private createLegend(): HTMLElement {
  const div = L.DomUtil.create("div", "legend");
  div.innerHTML = `
    <div class="legend-content">
      <div class="legend-item">
        <img src="assets/images/water-meter.png" alt="Compteur" />
        <span>Compteur</span>
      </div>
      <div class="legend-item">
        <img src="assets/images/sea-level.png" alt="Piézomètre" />
        <span>Piézomètre</span>
      </div>
      <div class="legend-item">
        <img src="assets/images/washbasin.png" alt="Bassin" />
        <span>Bassin</span>
      </div>
    </div>
  `;
  return div;
}

private addCoordinatesControl(): void {
  if (!this.map1) return;

  const CoordinatesControl = L.Control.extend({
    options: { position: "bottomright" },
    onAdd: () => this.createCoordinatesContainer(),
  });

  new CoordinatesControl().addTo(this.map1);
  this.setupCoordinatesUpdate();
}

private createCoordinatesContainer(): HTMLElement {
  const container = L.DomUtil.create(
    "div",
    "coordinates-control leaflet-control"
  );
  Object.assign(container.style, {
    background: "rgba(255, 255, 255, 0.8)",
    padding: "5px 10px",
    margin: "10px",
    borderRadius: "4px",
    fontSize: "12px",
    fontFamily: "Arial",
  });
  container.innerHTML = "Lng: 0 Lat: 0";
  return container;
}

private setupCoordinatesUpdate(): void {
  this.map1?.on("mousemove", (e: L.LeafletMouseEvent) => {
    const container = document.querySelector(".coordinates-control");
    if (container) {
      container.innerHTML = `Lng: ${e.latlng.lng.toFixed(
        4
      )} Lat: ${e.latlng.lat.toFixed(4)}`;
    }
  });
}

private clearExistingMarkers(): void {
  Object.values(this.markerGroup).forEach(({ marker, circle }) => {
    marker.remove();
    circle.remove();
  });
  this.markerGroup = {};
}

private handleInitialBasinSelection(): void {
  try {
    if (this._selectedBasin) {
      this.handleBasinSelection(this._selectedBasin);
    } else {
      this.handleBasinSelection(36);
    }
  } catch (error) {
    console.error("Error in initial basin selection:", error);
    this.isLoading = false;
  }
}

private updateMapAndPerformance(id: number): void {
  if (this.waterPerformanceComponent) {
    setTimeout(() => {
      this.waterPerformanceComponent.refreshData();
    });
  }

  const selectedBasin = this.BasinStatus.find((b) => b.id_capteur === id);
  if (selectedBasin?.libelle) {
    this.highlightMarker(selectedBasin.libelle);
    this.centerMapOnBasin(selectedBasin);
  }
}
}