import { Component, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { EChartsOption } from "echarts";
import { DataService } from "../../../@core/services/table-data/data.service";
import { CounterVolume } from "../../../@core/types/volume-request";

export interface GraphicModalData {
  component: string;
  duration: string;
  request: CounterVolume;
  matricule: string;
  startDate: Date; 
  endDate: Date;   
}

@Component({
  selector: "ngx-table-data-graphic",
  templateUrl: "./table-data-graphic.component.html",
  styleUrls: ["./table-data-graphic.component.scss"],
})
export class TableDataGraphicComponent implements OnInit {
  chartOption: EChartsOption = {};
  isLoading = true;
  data: GraphicModalData;
  hasNegativeValues = false;

  constructor(
    protected dialogRef: NbDialogRef<TableDataGraphicComponent>,
    private dataService: DataService
  ) {}

  startDate: Date | null = null;
  endDate: Date | null = null;
  
  ngOnInit() {
    if (this.data) {
      this.startDate = this.data.startDate;  
      this.endDate = this.data.endDate;     
      this.fetchAndProcessData(this.data);
    }
  }
  private async fetchAndProcessData(data: GraphicModalData) {
    try {
      const result = await this.dataService.fetchDataForExport(
        data.component,
        data.duration,
        data.request
      );

      this.updateChartOptions(result, data.duration);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.isLoading = false;
    }
  }

  private updateChartOptions(data: any[], duration: string) {
    let chartData;
    const FRENCH_MONTHS = {
      "01": "janvier",
      "02": "février",
      "03": "mars",
      "04": "avril",
      "05": "mai",
      "06": "juin",
      "07": "juillet",
      "08": "août",
      "09": "septembre",
      "10": "octobre",
      "11": "novembre",
      "12": "décembre",
    };

    switch (this.data.component) {
      case "compteur":
        if (duration === "hours") {
          chartData = data[0].data
            .map((item: any) => ({
              date: new Date(item.time).toLocaleString("fr-FR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              }),
              rawDate: new Date(item.time),
              value: item.difference / 1000,
            }))
            .filter((item) => item.value >= 0)
            .sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime());
        } else if (duration === "months") {
          chartData = data
            .map((item) => ({
              date: `${FRENCH_MONTHS[item.month]} ${item.year}`,
              value: item.difference / 1000,
              rawDate: new Date(`${item.year}-${item.month}-01`),
              numericMonth: parseInt(item.month),
              numericYear: parseInt(item.year),
            }))
            .filter((item) => item.value >= 0)
            .sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime());
        } else {
          chartData = data
            .map((item) => ({
              date: new Date(item.date).toLocaleString("fr-FR"),
              rawDate: new Date(item.date),
              value: item.difference / 1000,
            }))
            .filter((item) => item.value >= 0)
            .sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime());
        }
        break;

      case "basin":
        chartData = data
          .flatMap(
            (basinData) =>
              basinData.recharge?.map((item) => ({
                date: new Date(item.time).toLocaleString("fr-FR"),
                rawDate: new Date(item.time),
                value: item.diff_jr_volume || 0,
              })) || []
          )
          .filter((item) => item.value >= 0)
          .sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime());
        break;

      case "piezometre":
        chartData = data
          .map((item) => ({
            date: new Date(item.date_mesure).toLocaleString("fr-FR"),
            rawDate: new Date(item.date_mesure),
            value: item.niveau_eau || 0,
          }))
          .filter((item) => item.value >= 0)
          .sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime());
        break;
    }

    const sortedData = chartData;

    if (sortedData.length > 0) {
      const yAxisName =
        this.data.component === "piezometre" ? "Niveau (m)" : "Volume (m³)";

      this.chartOption = {
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              title: {
                zoom: "Zoom de zone",
                back: "Restaurer le zoom de la zone",
              },
              yAxisIndex: "none",
            },
            restore: {
              title: "Actualisé",
            },
            saveAsImage: {
              title: "Enregistré image",
            },
            mark: { show: true },
            dataView: {
              lang: ["Tableau des valeurs", "Retour", "Actualisé"],
              title: "Voir les données",
              readOnly: true,
              optionToContent: (opt: any) => {
                let table =
                  '<table style="width:100%;text-align:center;border-collapse:collapse;">';

                if (duration === "months") {
                  table +=
                    "<thead><tr>" +
                    '<th style="border:1px solid #ddd;padding:8px;">Mois</th>' +
                    `<th style="border:1px solid #ddd;padding:8px;">${yAxisName}</th>` +
                    "</tr></thead><tbody>";

                  opt.series[0].data.forEach((value: number, index: number) => {
                    table +=
                      "<tr>" +
                      `<td style="border:1px solid #ddd;padding:8px;">${sortedData[index].date}</td>` +
                      `<td style="border:1px solid #ddd;padding:8px;">${value.toFixed(
                        2
                      )}</td>` +
                      "</tr>";
                  });
                } else {
                  table +=
                    "<thead><tr>" +
                    '<th style="border:1px solid #ddd;padding:8px;">Date</th>' +
                    '<th style="border:1px solid #ddd;padding:8px;">Heure</th>' +
                    `<th style="border:1px solid #ddd;padding:8px;">${yAxisName}</th>` +
                    "</tr></thead><tbody>";

                  opt.series[0].data.forEach((value: number, index: number) => {
                    const date = new Date(sortedData[index].date);
                    const formattedDate = date.toLocaleDateString("fr-FR");
                    const formattedTime = date.toLocaleTimeString("fr-FR", {
                      hour: "2-digit",
                      minute: "2-digit",
                    });

                    table +=
                      "<tr>" +
                      `<td style="border:1px solid #ddd;padding:8px;">${formattedDate}</td>` +
                      `<td style="border:1px solid #ddd;padding:8px;">${formattedTime}</td>` +
                      `<td style="border:1px solid #ddd;padding:8px;">${value.toFixed(
                        2
                      )}</td>` +
                      "</tr>";
                  });
                }

                table += "</tbody></table>";
                return table;
              },
            },
            magicType: {
              show: true,
              type: ["line", "bar"],
              title: {
                bar: "Passer au graphique à barres",
                line: "Passer au graphique linéaire",
              },
            },
          },
          itemSize: 15,
          itemGap: 10,
          right: "10%",
        },
        xAxis: {
          type: "category",
          data: sortedData.map((item) => item.date),
          axisLabel: {
            rotate: 45,
            interval: Math.floor(sortedData.length / 15),
            fontSize: 10,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: sortedData.map((item) => item.value),
            type: "line",
            smooth: true,
            areaStyle: {
              opacity: 0.3,
            },
            itemStyle: {
              color: "#006d6d",
            },
          },
        ],
        grid: {
          left: "10%",
          right: "5%",
          bottom: "15%",
          top: "10%",
          containLabel: true,
        },
      };
    } else {
      this.hasNegativeValues = true;
    }
  }

  getModalTitle(): string {
    if (!this.data?.component) return "Graphique";
    
    const matriculeDisplay = this.data?.matricule || "N/A";
    
    switch (this.data.component) {
      case "compteur":
        return `Graphique Compteur - ${matriculeDisplay}`;
      case "basin":
        return `Graphique Bassin - ${matriculeDisplay}`;
      case "piezometre":
        return `Graphique Piézomètre - ${matriculeDisplay}`;
      default:
        return "Graphique";
    }
  }

  private getTitleText(component: string, duration: string): string {
    switch (component) {
      case "compteur":
        return `Volume par ${
          duration === "hours"
            ? "heure"
            : duration === "months"
            ? "mois"
            : "jour"
        }`;
      case "basin":
        return "Volume de bassin";
      case "piezometre":
        return "Niveau piézométrique";
      default:
        return "";
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
