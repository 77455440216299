import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Chart } from "chart.js";
import { CounterService } from "../../../../@core/services/counter/counter.service";
import { DateRangeValidator } from "../../../../@core/validators/data-range-validator";
import {
  CounterDataRespose,
  CounterHourlyResponse,
  VolumeRequest,
} from "../../../../@core/types";
import { ExcelExportService } from "../../../../@core/services/excel/excel-export.service";
import { ViewMode } from "../../../../@core/types/view-mode";

@Component({
  selector: "ngx-counter-history",
  templateUrl: "./counter-history.component.html",
  styleUrls: ["./counter-history.component.scss"],
})
export class CounterHistoryComponent implements OnInit, OnChanges {
  @Input() Counter_Id: number;
  @Input() compteurMatricule: string = "";

  dateRange = {
    start: new Date(),
    end: new Date(),
  };
  maxDate: Date = new Date();
  protected DateRangeValidator = DateRangeValidator;

  activeView: ViewMode = "variation";
  @Input() lastCommunicationDate: string = "Inconnue";

  isLitersPerSecond: boolean = false;
  loading: boolean = false;
  selectedBarDate: string | null = null;
  private currentId: number | null = null;
  private selectedChartIndex: number | null = null;
  private currentLoadedCounterId: number | null = null;
  isDailyZeroData: boolean = false;
  isHourlyZeroData: boolean = false;
  hasNegativeValues: boolean = false;
  isLineChart: boolean = false;

  private dailyChart: Chart;
  private hourlyChart: Chart;

  counterData: CounterDataRespose[] = [];
  counterDataCumul: CounterDataRespose[] = [];
  counterDaily: CounterHourlyResponse[] = [];

  constructor(
    private counterService: CounterService,
    private excelService: ExcelExportService
  ) {}

  ngOnInit() {
    this.initializeDates();
    if (this.Counter_Id) {
      this.currentId = this.Counter_Id;
      this.loadDataForDateRange();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["Counter_Id"]?.currentValue) {
      this.currentId = changes["Counter_Id"].currentValue;
      this.resetData();
      this.initializeDates();
      this.loadDataForDateRange();
    }
  }

  private resetData() {
    this.counterData = [];
    this.counterDataCumul = [];
    this.counterDaily = [];
    this.isDailyZeroData = false;
    this.isHourlyZeroData = false;
    this.selectedBarDate = null;
    if (this.dailyChart) {
      this.dailyChart.destroy();
      this.dailyChart = null;
    }
    if (this.hourlyChart) {
      this.hourlyChart.destroy();
      this.hourlyChart = null;
    }
  }

  ngOnDestroy() {
    if (this.dailyChart) this.dailyChart.destroy();
    if (this.hourlyChart) this.hourlyChart.destroy();
  }
  toggleLineChart(): void {
    this.isLineChart = !this.isLineChart;
    this.updateCharts();
  }

  private initializeDates() {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    sevenDaysAgo.setHours(0, 0, 0, 0);
    today.setHours(23, 59, 59, 999);

    this.maxDate = new Date(today);
    this.dateRange = {
      start: new Date(sevenDaysAgo),
      end: new Date(today),
    };
  }

  onDateRangeChange(): void {
    if (!this.dateRange?.start || !this.dateRange?.end || !this.currentId)
      return;

    const startDate = new Date(this.dateRange.start);
    const endDate = new Date(this.dateRange.end);

    if (startDate > endDate) return;

    this.loading = true;
    const request = {
      startDate,
      endDate,
      id: this.currentId,
    };

    this.loadDataForDateRange();
  }

  setActiveView(view: ViewMode) {
    this.activeView = view;
    if (view === 'cumule') {
      this.getCounterDataCumul();
    } else {
      this.updateCharts();
    }
  }
  private checkForNegativeValues(data: any[]): boolean {
    return data.some((item) => item.difference < 0);
  }

  public loadDataForDateRange(): void {
    if (!this.currentId) return;

    this.loading = true;
    const request = {
      startDate: this.dateRange.start,
      endDate: this.dateRange.end,
      id: this.currentId,
    };

    this.counterService.counterData(request).subscribe({
      next: (response: CounterDataRespose[]) => {
        if (Array.isArray(response) && response.length > 0) {
          this.counterData = response;
          this.hasNegativeValues = response.some((item) => item.difference < 0);

          if (this.hasNegativeValues) {
            this.counterData = response;
          } else {
            this.counterData = response;
            const firstDate = response[0].date.split("T")[0];
            this.selectedBarDate = firstDate;

            if (this.activeView !== "cumule") {
              this.getCounterDataHourly(firstDate);
            }
          }

          if (this.activeView === "cumule") {
            this.getCounterDataCumul();
          } else {
            this.updateCharts();
          }
        }
        this.loading = false;
      },
      error: (error) => {
        console.error("Error fetching counter data:", error);
        this.loading = false;
      },
    });
  }

  getCounterDataHourly(selectedDate: string) {
    if (!selectedDate || !this.currentId) return;

    const request = {
      date: selectedDate,
      id: this.currentId,
    };

    this.loading = true;

    this.counterService.hourlyCounterData(request).subscribe({
      next: (response: CounterHourlyResponse[]) => {
        this.counterDaily = response;
        this.selectedBarDate = selectedDate;
        setTimeout(() => {
          if (this.hourlyChart) {
            this.hourlyChart.destroy();
          }
          if (this.getHourlyMaxValue() >= 0) {
            this.initHourlyChart();
          }
        }, 0);
        this.loading = false;
      },
      error: (error) => {
        console.error("Error:", error);
        this.loading = false;
      },
    });
  }

  getCounterDataCumul() {
    this.loading = true;
    const request = {
      startDate: this.dateRange.start,
      endDate: this.dateRange.end,
      id: this.currentId,
    };

    this.counterService.counterDataCumul(request).subscribe({
      next: (response: CounterDataRespose[]) => {
        if (Array.isArray(response)) {
          this.counterDataCumul = response;
          this.updateCharts();
        }
        this.loading = false;
      },
      error: (error) => {
        console.error("Error fetching cumulative data:", error);
        this.loading = false;
      },
    });
  }

  private updateCharts(): void {
    if (this.dailyChart) {
      this.dailyChart.destroy();
      this.dailyChart = null;
    }
    if (this.hourlyChart) {
      this.hourlyChart.destroy();
      this.hourlyChart = null;
    }

    setTimeout(() => {
      if (this.getDailyMaxValue() >= 0) {
        this.initDailyChart();
      }
      if (this.getHourlyMaxValue() >= 0) {
        this.initHourlyChart();
      }
    }, 100);
  }

  private reinitializeHourlyChart() {
    if (this.hourlyChart) {
      this.hourlyChart.destroy();
    }
    const hourlyMaxValue = this.getHourlyMaxValue();
    if (hourlyMaxValue >= 0) {
      this.initHourlyChart();
    }
  }

  updateHourlyChartUnits() {
    this.reinitializeHourlyChart();
  }

  private initDailyChart() {
    const ctx = document.getElementById("dailyChart") as HTMLCanvasElement;
    if (!ctx) return;

    const displayData =
      this.activeView === "variation"
        ? [...this.counterData].reverse()
        : [...this.counterDataCumul].reverse();

    this.isDailyZeroData = displayData.every((item) => item.difference === 0);

    const formattedData = {
      labels: [],
      values: [],
      dates: [],
    };

    displayData.forEach((item) => {
      const date = new Date(item.date);
      formattedData.labels.push(
        date.toLocaleDateString("fr-FR", {
          day: "2-digit",
          month: "short",
        })
      );
      formattedData.values.push(item.difference / 1000);
      formattedData.dates.push(item.date.split("T")[0]);
    });

    const maxValue = Math.max(...formattedData.values);
    const yAxisMax = maxValue === 0 ? 1 : maxValue * 1.1;

    const initialColors = Array(formattedData.values.length).fill("#5ea3a3");

    if (this.selectedBarDate) {
      const selectedIndex = formattedData.dates.findIndex(
        (date) => date === this.selectedBarDate
      );
      if (selectedIndex !== -1) {
        initialColors[selectedIndex] = "#9cd3db";
      }
    } else {
      initialColors[formattedData.values.length - 1] = "#9cd3db";
      this.selectedBarDate =
        formattedData.dates[formattedData.dates.length - 1];
      this.getCounterDataHourly(this.selectedBarDate);
    }

    if (this.dailyChart) {
      this.dailyChart.destroy();
      this.dailyChart = null;
    }

    this.dailyChart = new Chart(ctx, {
      type: this.isLineChart ? "line" : "bar",
      data: {
        labels: formattedData.labels,
        datasets: [
          {
            label: this.activeView === 'variation' ? 'Volume (m³)' : 'Volume Cumulé (m³)',
            data: formattedData.values,
            backgroundColor: this.isLineChart
              ? "rgba(94, 163, 163, 0.4)"
              : initialColors,
            borderColor: this.isLineChart ? "#5ea3a3" : undefined,
            borderWidth: this.isLineChart ? 2 : 0,
            pointBackgroundColor: this.isLineChart ? "#fff" : undefined,
            pointBorderColor: this.isLineChart ? "#5ea3a3" : undefined,
            pointBorderWidth: this.isLineChart ? 2 : undefined,
            pointRadius: this.isLineChart ? 4 : undefined,
            pointHoverRadius: this.isLineChart ? 6 : undefined,
            borderRadius: this.isLineChart ? undefined : 4,
            hoverBackgroundColor: this.isLineChart ? undefined : "#4a8f8f",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        onHover: (event: any, elements: any[]) => {
          event.target.style.cursor = elements.length ? "pointer" : "default";
        },
        hover: {
          mode: "nearest",
          intersect: true,
          animationDuration: 200,
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            padding: 20,
            boxWidth: 12,
            fontColor: "#666",
          },
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem: any) => {
              const value = formattedData.values[tooltipItem.index];
              const date = new Date(formattedData.dates[tooltipItem.index]);
              return [
                `Date: ${date.toLocaleDateString("fr-FR")}`,
                `Volume: ${value.toFixed(2)} m³`,
              ];
            },
          },
        },
        onClick: (evt: any, elements: Array<any>) => {
          if (elements?.[0]) {
            const clickedIndex = elements[0]._index;
            const selectedDate = formattedData.dates[clickedIndex];

            if (selectedDate) {
              this.loading = true;
              this.selectedBarDate = selectedDate;

              if (!this.isLineChart) {
                const newColors = Array(formattedData.values.length).fill(
                  "#5ea3a3"
                );
                newColors[clickedIndex] = "#9cd3db";
                this.dailyChart.data.datasets[0].backgroundColor = newColors;
              }

              this.dailyChart.update();
              this.getCounterDataHourly(selectedDate);
            }
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: yAxisMax,
                padding: 10,
                fontColor: "#666",
                callback: (value) => {
                  if (value === 0) return "0";
                  if (value < 0.01) return value.toExponential(2);
                  return value.toFixed(2);
                },
              },
              gridLines: {
                drawBorder: false,
                color: "#f0f0f0",
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: "#666",
              },
            },
          ],
        },
      },
    });

    ctx.style.cursor = "pointer";
  }

  private initHourlyChart() {
    const ctx = document.getElementById("hourlyChart") as HTMLCanvasElement;
    if (!ctx) return;

    const hourlyData =
      Array.isArray(this.counterDaily) && this.counterDaily[0]
        ? this.counterDaily[0]
        : [];

    const formattedData = {
      labels: [],
      values: [],
      dates: [],
      differences: [],
    };

    if (Array.isArray(hourlyData)) {
      const filteredData = hourlyData.filter((entry) => entry.difference >= 0);

      const hourlyEntries = filteredData.reduce((acc, entry) => {
        const hour = parseInt(entry.hour);
        if (!isNaN(hour) && hour >= 0 && hour < 24) {
          if (!acc[hour] || new Date(entry.time) > new Date(acc[hour].time)) {
            acc[hour] = entry;
          }
        }
        return acc;
      }, {} as { [key: number]: any });

      Object.keys(hourlyEntries)
        .map(Number)
        .sort((a, b) => a - b)
        .forEach((hour) => {
          const entry = hourlyEntries[hour];
          const hourLabel = `${hour.toString().padStart(2, "0")}:00`;
          const value = this.isLitersPerSecond
            ? (entry.difference / 1000) * 0.277778
            : entry.difference / 1000;

          formattedData.labels.push(hourLabel);
          formattedData.values.push(value);
          formattedData.dates.push(entry.time);
          formattedData.differences.push(entry.difference);
        });
    }

    if (formattedData.values.length === 0) {
      return;
    }

    this.isHourlyZeroData = formattedData.values.every((value) => value === 0);
    this.hasNegativeValues = formattedData.values.some((value) => value < 0);

    const initialColors = Array(formattedData.values.length).fill("#5ea3a3");

    this.hourlyChart = new Chart(ctx, {
      type: this.isLineChart ? "line" : "bar",
      data: {
        labels: formattedData.labels,
        datasets: [
          {
            label: this.isLitersPerSecond ? "Débit (L/s)" : "Débit (m³/h)",
            data: formattedData.values,
            backgroundColor: this.isLineChart
              ? "rgba(94, 163, 163, 0.4)"
              : initialColors,
            borderColor: this.isLineChart ? "#5ea3a3" : undefined,
            borderWidth: this.isLineChart ? 2 : 0,
            pointBackgroundColor: this.isLineChart ? "#fff" : undefined,
            pointBorderColor: this.isLineChart ? "#5ea3a3" : undefined,
            pointBorderWidth: this.isLineChart ? 2 : undefined,
            pointRadius: this.isLineChart ? 4 : undefined,
            pointHoverRadius: this.isLineChart ? 6 : undefined,
            borderRadius: this.isLineChart ? undefined : 4,
            hoverBackgroundColor: this.isLineChart ? undefined : "#4a8f8f",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom",
          labels: {
            padding: 20,
            boxWidth: 12,
            fontColor: "#666",
          },
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const index = tooltipItem.index || 0;
              if (index < 0 || index >= formattedData.values.length) {
                return "No data available";
              }

              const value = formattedData.values[index];
              const date = new Date(formattedData.dates[index]);
              const difference = formattedData.differences[index];

              const formattedDate = date.toLocaleString("fr-FR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              });

              return [
                `Date: ${formattedDate}`,
                `Débit: ${value.toFixed(2)} ${
                  this.isLitersPerSecond ? "L/s" : "m³/h"
                }`,
                `Difference: ${(difference / 1000).toFixed(2)} m³`,
              ];
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: Math.max(...formattedData.values) * 1.1 || 100,
                padding: 10,
                fontColor: "#666",
              },
              gridLines: {
                color: "#f0f0f0",
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
                color: "#f0f0f0",
              },
              ticks: {
                fontColor: "#666",
              },
            },
          ],
        },
      },
    });
  }

  public getDailyMaxValue(): number {
    const displayData =
      this.activeView === "variation"
        ? this.counterData
        : this.counterDataCumul;
    return !displayData?.length
      ? 0
      : Math.max(...displayData.map((item) => item.difference / 1000));
  }

  public getHourlyMaxValue(): number {
    if (!this.counterDaily?.[0]) return 0;
    const maxValue = Math.max(
      ...(Array.isArray(this.counterDaily[0])
        ? this.counterDaily[0].map((item) => item.difference / 1000)
        : [0])
    );
    return this.isLitersPerSecond ? maxValue * 0.277778 : maxValue;
  }

  public getDebitValue(entry: any): number {
    if (!entry?.difference) return 0;
    const baseValue = entry.difference / 1000;
    return this.isLitersPerSecond ? baseValue * 0.277778 : baseValue;
  }

  public formatNumber(value: number): string {
    return typeof value === "number" ? value.toFixed(2) : "0.00";
  }

  public formatDateTime(time: string): string {
    if (!time) return "";
    const date = new Date(time);
    return date.toLocaleString("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  exportToExcel() {
    const request: VolumeRequest = {
      startDate: this.dateRange.start,
      endDate: this.dateRange.end,
      id: this.currentId,
    };

    this.counterService.VolumeHourly(request).subscribe({
      next: (volumeHourlyData) => {
        this.excelService.exportCounterToExcel({
          volumeHourly: Array.isArray(volumeHourlyData)
            ? volumeHourlyData
            : [volumeHourlyData],
          dailyData: this.counterData,
          startDate: this.dateRange.start,
          endDate: this.dateRange.end,
          matricule: this.compteurMatricule,
        });
      },
      error: (error) => {
        console.error("Error fetching volume hourly data:", error);
        this.excelService.exportCounterToExcel({
          volumeHourly: [],
          dailyData: this.counterData,
          startDate: this.dateRange.start,
          endDate: this.dateRange.end,
          matricule: this.compteurMatricule,
        });
      },
    });
  }
}
