import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { EChartsOption } from "echarts";
import * as echarts from "echarts";
import { BasinService } from "../../../../@core/services/basin/basin.service";
import { WaterVolumeResponse } from "../../../../@core/types/water-volume-response";
import { VolumeRequest } from "../../../../@core/types/volume-rq";
import { WaterPourcentageResponse } from "../../../../@core/types/water-pourcentage";
import { NiveauBasinResponse } from "../../../../@core/types/niveau-response";
import { ExcelExportService } from "../../../../@core/services/excel/excel-export.service";
import * as XLSX from "xlsx";

@Component({
  selector: "ngx-water-performance",
  templateUrl: "./water-performance.component.html",
  styleUrls: ["./water-performance.component.scss"],
})
export class WaterPerformanceComponent implements OnInit {
  dateRange: { start: Date; end: Date };
  chartOptions: EChartsOption = {};
  activeTab = "volume";
  chartTitle: string;
  @Input() basinMatricule: string = "";

  private isBarChart(): boolean {
    if (this.activeTab === "pourcentage") {
      return true;
    }
    return this.activeTab === "niveau";
  }

  private chartStyles = {
    volume: false,
    niveau: true,
    pourcentage: true,
  };

  waterVolumeData: WaterVolumeResponse[] = [];
  waterPourcentageData: WaterPourcentageResponse[] = [];
  basinNiveauData: NiveauBasinResponse[] = [];

  waterVolumeDataHourly: WaterVolumeResponse[] = [];
  waterNiveauDataHourly: NiveauBasinResponse[] = [];
  waterPourcentageDataHourly: WaterPourcentageResponse[] = [];

  @Output() volumeUpdated = new EventEmitter<string>();
  @Output() pourcentageUpdated = new EventEmitter<string>();
  @Output() niveauUpdated = new EventEmitter<string>();

  timeViewMode: "daily" | "hourly" = "hourly";

  tabs = [
    { id: "volume", label: "Volume", icon: "fas fa-water" },
    { id: "niveau", label: "Niveau d'eau", icon: "fas fa-level-up-alt" },
    {
      id: "pourcentage",
      label: "Taux de remplissage ",
      icon: "fas fa-percentage",
    },
  ];

  private _id: number;

  @Input()
  set id(value: number) {
    if (this._id !== value) {
      this._id = value;
      if (value) {
        this.refreshData();
      }
    }
  }
  get id(): number {
    return this._id;
  }

  data = {
    volume: [] as [number, number][],
    niveau: [] as [number, number][],
    pourcentage: [] as [number, number[]][],
  };

  maxDate: Date = new Date();

  constructor(
    private basinService: BasinService,
    private excelService: ExcelExportService
  ) {
    const end = new Date();
    const start = new Date();
    start.setDate(end.getDate() - 7);
    this.dateRange = { start, end };

    this.maxDate = new Date();
    this.maxDate.setHours(23, 59, 59, 999);
  }

  private volumeProcessed = false;
  private niveauProcessed = false;
  private pourcentageProcessed = false;

  ngOnInit() {
    if (this.id) {
      this.volumeProcessed = false;
      this.niveauProcessed = false;
      this.pourcentageProcessed = false;

      this.activeTab = "volume";
      this.fetchVolumeData().then(() => {
        this.fetchNiveauData().then(() => {
          this.fetchPourcentageData();
        });
      });
    }
  }

  private fetchDailyData(): void {
    if (!this._id) return;

    if (this.activeTab === "volume" || !this.volumeProcessed) {
      this.fetchVolumePerDay(this._id);
    }
    if (this.activeTab === "niveau" || !this.niveauProcessed) {
      this.fetchNiveauPerDay(this._id);
    }
    if (this.activeTab === "pourcentage" || !this.pourcentageProcessed) {
      this.fetchPourcentagePerDay(this._id);
    }
  }

  private async fetchVolumeData(): Promise<void> {
    const request: VolumeRequest = {
      startDate: this.dateRange.start,
      endDate: this.dateRange.end,
      id: this.id,
    };

    return new Promise((resolve) => {
      this.basinService.fetchVolumePerHours(request).subscribe({
        next: (response) => {
          if (response?.length > 0) {
            this.waterVolumeDataHourly = response;
            if (this.timeViewMode === "hourly") {
              this.data.volume = this.formatApiData(response);
              this.emitLatestVolume();
              this.updateChart();
            }
            this.volumeProcessed = true;
          }
          resolve();
        },
        error: (error) => {
          console.error("Error fetching volume:", error);
          resolve();
        },
      });
    });
  }

  private async fetchNiveauData(): Promise<void> {
    const request: VolumeRequest = {
      startDate: this.dateRange.start,
      endDate: this.dateRange.end,
      id: this.id,
    };

    return new Promise((resolve) => {
      this.basinService.fetchNiveauPerHours(request).subscribe({
        next: (response) => {
          if (response?.length > 0) {
            this.waterNiveauDataHourly = response;
            if (this.timeViewMode === "hourly") {
              this.data.niveau = this.formatNiveauData(response);
              this.emitLatestNiveau();
            }
            this.niveauProcessed = true;
          }
          resolve();
        },
        error: (error) => {
          console.error("Error fetching niveau:", error);
          resolve();
        },
      });
    });
  }

  private async fetchPourcentageData(): Promise<void> {
    const request: VolumeRequest = {
      startDate: this.dateRange.start,
      endDate: this.dateRange.end,
      id: this.id,
    };

    return new Promise((resolve) => {
      this.basinService.fetchPourcentagePerHours(request).subscribe({
        next: (response) => {
          if (response?.length > 0) {
            this.waterPourcentageDataHourly = response;
            if (this.timeViewMode === "hourly") {
              this.data.pourcentage = this.formatPourcentageData(response);
              this.emitLatestPourcentage();
            }
            this.pourcentageProcessed = true;
          }
          resolve();
        },
        error: (error) => {
          console.error("Error fetching pourcentage:", error);
          resolve();
        },
      });
    });
  }

  refreshData(): void {
    if (!this._id) return;

    this.volumeProcessed = false;
    this.niveauProcessed = false;
    this.pourcentageProcessed = false;

    if (this.timeViewMode === "hourly") {
      this.fetchVolumeData().then(() => {
        this.fetchNiveauData().then(() => {
          this.fetchPourcentageData().then(() => {
            this.updateChart();
          });
        });
      });
    }
  }

  fetchVolumePerDay(id: number): Promise<void> {
    const request: VolumeRequest = {
      startDate: this.dateRange.start,
      endDate: this.dateRange.end,
      id: id,
    };
    return new Promise((resolve) => {
      this.basinService.PerformanceVolumeWaterPerDay(request).subscribe({
        next: (response) => {
          if (response?.length > 0) {
            this.waterVolumeData = response;
            this.data.volume = this.formatApiData(response);
            this.volumeProcessed = true;
            this.emitLatestVolume();
            this.updateChart();
          }
          resolve();
        },
        error: (error) => {
          console.error("Error fetching daily volume:", error);
          resolve();
        },
      });
    });
  }

  fetchNiveauPerDay(id: number): Promise<void> {
    const request: VolumeRequest = {
      startDate: this.dateRange.start,
      endDate: this.dateRange.end,
      id: id,
    };
    return new Promise((resolve) => {
      this.basinService.PerformanceNiveauBasinPerDay(request).subscribe({
        next: (response) => {
          if (response?.length > 0) {
            this.basinNiveauData = response;
            this.data.niveau = this.formatNiveauData(response);
            this.niveauProcessed = true;
            this.emitLatestNiveau();
            this.updateChart();
          }
          resolve();
        },
        error: (error) => {
          console.error("Error fetching daily niveau:", error);
          resolve();
        },
      });
    });
  }

  fetchPourcentagePerDay(id: number): Promise<void> {
    const request: VolumeRequest = {
      startDate: this.dateRange.start,
      endDate: this.dateRange.end,
      id: id,
    };
    return new Promise((resolve) => {
      this.basinService.PerformancePourcentageBasinPerDay(request).subscribe({
        next: (response) => {
          if (response?.length > 0) {
            this.waterPourcentageData = response;
            this.data.pourcentage = this.formatPourcentageData(response);
            this.pourcentageProcessed = true;
            this.emitLatestPourcentage();
            this.updateChart();
          }
          resolve();
        },
        error: (error) => {
          console.error("Error fetching daily pourcentage:", error);
          resolve();
        },
      });
    });
  }
  changeTab(tabId: string) {
    this.onDisplayClick(tabId);
  }

  toggleTimeView() {
    this.timeViewMode = this.timeViewMode === "daily" ? "hourly" : "daily";
    this.volumeProcessed = false;
    this.niveauProcessed = false;
    this.pourcentageProcessed = false;

    if (this.timeViewMode === "daily") {
      setTimeout(() => {
        this.fetchVolumePerDay(this._id).then(() => {
          setTimeout(() => {
            this.fetchNiveauPerDay(this._id).then(() => {
              setTimeout(() => {
                this.fetchPourcentagePerDay(this._id).then(() => {
                  this.updateChart();
                });
              }, 100);
            });
          }, 100);
        });
      }, 100);
    } else {
      if (this.waterVolumeDataHourly.length > 0) {
        this.data.volume = this.formatApiData(this.waterVolumeDataHourly);
      }
      if (this.waterNiveauDataHourly.length > 0) {
        this.data.niveau = this.formatNiveauData(this.waterNiveauDataHourly);
      }
      if (this.waterPourcentageDataHourly.length > 0) {
        this.data.pourcentage = this.formatPourcentageData(
          this.waterPourcentageDataHourly
        );
      }
      this.updateChart();
      this.refreshData();
    }
  }
  onDateRangeChange() {
    if (!this.dateRange?.start || !this.dateRange?.end) {
      return;
    }

    if (this.dateRange.start > this.dateRange.end) {
      console.error("Start date cannot be after end date.");
      return;
    }

    if (this._id) {
      this.refreshData();
    }
  }

  emitLatestVolume() {
    const data =
      this.timeViewMode === "hourly"
        ? this.waterVolumeDataHourly
        : this.waterVolumeData;

    if (data?.length > 0) {
      const firstValidData = data.find((item) => item.volume !== null);

      if (firstValidData) {
        this.volumeUpdated.emit(
          `${Number(firstValidData.volume).toFixed(2)} m³`
        );
      } else {
        this.volumeUpdated.emit("N/A");
      }
    } else {
      this.volumeUpdated.emit("N/A");
    }
  }

  emitLatestPourcentage() {
    const data =
      this.timeViewMode === "hourly"
        ? this.waterPourcentageDataHourly
        : this.waterPourcentageData;

    if (data?.length > 0) {
      const firstValidData = data.find((item) => item.pourcentage !== null);

      if (firstValidData) {
        const pourcentage = Number(firstValidData.pourcentage) * 100;
        this.pourcentageUpdated.emit(`${pourcentage.toFixed(2)}%`);
      } else {
        this.pourcentageUpdated.emit("N/A");
      }
    } else {
      this.pourcentageUpdated.emit("N/A");
    }
  }

  emitLatestNiveau() {
    const data =
      this.timeViewMode === "hourly"
        ? this.waterNiveauDataHourly
        : this.basinNiveauData;

    if (data?.length > 0) {
      const firstValidData = data.find((item) => item.niveau !== null);

      if (firstValidData) {
        this.niveauUpdated.emit(
          `${Number(firstValidData.niveau).toFixed(2)} m`
        );
      } else {
        this.niveauUpdated.emit("N/A");
      }
    } else {
      this.niveauUpdated.emit("N/A");
    }
  }

  formatApiData(data: WaterVolumeResponse[]): [number, number][] {
    const points: [number, number][] = [];
    const sortedData = [...data].sort(
      (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
    );

    sortedData.forEach((item) => {
      if (item.volume !== null && item.time) {
        const date = new Date(item.time);
        if (date > this.dateRange.end) return;

        const time = date.getTime();
        const volume = parseFloat(item.volume);
        if (!isNaN(volume) && volume > 0) {
          points.push([time, volume]);
        }
      }
    });
    return points;
  }

  formatPourcentageData(data: WaterPourcentageResponse[]): any[] {
    if (!data || data.length === 0) {
      return [];
    }

    const sortedData = [...data].sort(
      (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
    );

    const formattedData: any[] = [];
    let lastValidPourcentage: number | null = null;

    for (let i = 0; i < sortedData.length; i++) {
      const item = sortedData[i];
      if (!item.time) continue;

      const date = new Date(item.time);
      if (date > this.dateRange.end) continue;

      const currentTime = date.getTime();
      let currentPourcentage =
        item.pourcentage !== null
          ? parseFloat(item.pourcentage.toString())
          : null;

      if (currentPourcentage !== null && !isNaN(currentPourcentage)) {
        if (currentPourcentage <= 0 || currentPourcentage > 1) {
          if (lastValidPourcentage !== null) {
            currentPourcentage = lastValidPourcentage;
          } else {
            continue;
          }
        } else {
          lastValidPourcentage = currentPourcentage;
        }
      } else if (lastValidPourcentage !== null) {
        currentPourcentage = lastValidPourcentage;
      } else {
        continue;
      }

      const remainingValue = 1 - currentPourcentage;

      formattedData.push({
        time: currentTime,
        originalValue: currentPourcentage * 100,
        reducedValue: remainingValue * 100,
      });
    }

    return formattedData;
  }

  formatNiveauData(data: NiveauBasinResponse[]): [number, number][] {
    if (!data || data.length === 0) {
      return [];
    }

    const sortedData = [...data].sort(
      (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
    );

    const points: [number, number][] = [];
    let lastValidNiveau: number | null = null;

    for (let i = 0; i < sortedData.length; i++) {
      const item = sortedData[i];
      if (!item.time) continue;

      const date = new Date(item.time);
      if (date > this.dateRange.end) continue;

      const currentTime = date.getTime();
      const currentNiveau =
        item.niveau !== null ? parseFloat(item.niveau.toString()) : null;

      if (currentNiveau !== null && !isNaN(currentNiveau)) {
        if (currentNiveau <= 0) {
          if (lastValidNiveau !== null) {
            points.push([currentTime, lastValidNiveau]);
          }
        } else {
          points.push([currentTime, currentNiveau]);
          lastValidNiveau = currentNiveau;
        }
      } else if (lastValidNiveau !== null) {
        points.push([currentTime, lastValidNiveau]);
      }
    }

    return points;
  }

  private onInitialDisplay(): void {
    this.activeTab = "volume";
    this.chartStyles[this.activeTab] =
      this.activeTab === "niveau" || this.activeTab === "pourcentage";
    this.updateChart();
  }

  onDisplayClick(tabId: string) {
    this.activeTab = tabId;
    this.chartStyles[tabId] = tabId === "niveau" || tabId === "pourcentage";

    if (this.id) {
      if (this.timeViewMode === "daily") {
        this.volumeProcessed = false;
        this.niveauProcessed = false;
        this.pourcentageProcessed = false;

        this.fetchVolumePerDay(this._id).then(() => {
          this.fetchNiveauPerDay(this._id).then(() => {
            this.fetchPourcentagePerDay(this._id).then(() => {
              this.updateChart();
            });
          });
        });
      } else {
        this.refreshData();
      }
    }
  }
  hoveredTab: string | null = null;

  onTabHover(tabId: string) {
    this.hoveredTab = tabId;
  }

  onTabLeave() {
    this.hoveredTab = null;
  }

  updateChart() {
    const activeData = this.data[this.activeTab];
    this.chartTitle = `${
      this.tabs.find((t) => t.id === this.activeTab)?.label
    } du bassin sur la période sélectionnée (${this.basinMatricule})`;

    if (!activeData || activeData.length === 0) {
      return;
    }

    if (this.activeTab === "pourcentage") {
      this.chartOptions = this.createStackedBarOptions(
        activeData as [number, number[]][]
      );
      return;
    }

    let minValue = Infinity;
    let maxValue = -Infinity;

    activeData.forEach(([_, value]) => {
      if (value !== null && !isNaN(value)) {
        minValue = Math.min(minValue, typeof value === "number" ? value : 0);
        maxValue = Math.max(maxValue, typeof value === "number" ? value : 100);
      }
    });

    if (minValue === Infinity || maxValue === -Infinity) {
      minValue = 0;
      maxValue = 100;
    }

    const range = maxValue - minValue;
    const padding = range * 0.1;
    const effectiveMin = Math.max(0, minValue - padding);
    const effectiveMax = maxValue + padding;

    const barStyle = {
      type: "bar",
      barWidth: "40%",
      barGap: "30%",
      connectNulls: true,
      showSymbol: true,
      itemStyle: {
        color: "#629EA7",
        borderRadius: [4, 4, 0, 0],
      },
      emphasis: {
        itemStyle: {
          color: "#629EA7",
        },
      },
    };

    const lineStyle = {
      type: "line",
      smooth: true,
      symbol: "circle",
      symbolSize: 6,
      connectNulls: true,
      showSymbol: true,
      lineStyle: {
        width: 2,
        color: "#0f5e9c",
      },
      itemStyle: {
        color: "#ffffff",
        borderColor: "#0f5e9c",
        borderWidth: 2,
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgb(128, 182, 246)" },
          { offset: 1, color: "rgb(235, 245, 255)" },
        ]),
      },
    };

    this.chartOptions = {
      title: {
        text: this.chartTitle,
        textStyle: {
          fontSize: 16,
        },
      },
      backgroundColor: "#ffffff",
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          const date = new Date(params[0].value[0]);
          return `${date.toLocaleString()}<br/>
                  ${this.tabs.find((t) => t.id === this.activeTab)?.label}: 
                  ${params[0].value[1].toFixed(2)}${
            this.activeTab === "pourcentage" ? "%" : ""
          }`;
        },
        axisPointer: {
          type: "none",
        },
      },
      xAxis: {
        type: "time",
        boundaryGap: this.chartStyles[this.activeTab],
        axisLine: {
          lineStyle: {
            color: "#e0e0e0",
            width: 1,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: "#eef5f9",
            width: 1,
            type: "solid",
          },
        },
        axisLabel: {
          color: "#666",
          formatter: (value: number) => {
            const date = new Date(value);
            const monthName = date.toLocaleString("default", {
              month: "short",
            });
            if (this.timeViewMode === "hourly") {
              return `${date.getDate()} ${monthName} ${date
                .getHours()
                .toString()
                .padStart(2, "0")}:00`;
            }
            return `${date.getDate()} ${monthName}`;
          },
        },
      },
      yAxis: {
        type: "value",
        min: effectiveMin,
        max: effectiveMax,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#eef5f9",
            type: "solid",
          },
        },
        axisLabel: {
          color: "#666",
          formatter: (value: number) => {
            switch (this.activeTab) {
              case "volume":
                return `${value.toFixed(2)} m³`;
              case "niveau":
                return `${value.toFixed(2)} m`;
              default:
                return value.toFixed(2);
            }
          },
        },
      },
      series: [
        {
          data: activeData,
          ...(this.chartStyles[this.activeTab] ? barStyle : lineStyle),
        },
      ],

      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            title: {
              zoom: "Zoom de zone",
              back: "Restaurer le zoom de la zone",
            },
            yAxisIndex: "none",
          },
          restore: {
            title: "Actualisé",
          },
          saveAsImage: {
            title: "Enregistré image",
          },
          mark: { show: true },
          dataView: {
            lang: ["Tableau des valeurs", "Retour", "Actualisé"],
            title: "Voir les données",
            readOnly: true,
            optionToContent: (opt: any) => {
              let table =
                '<table style="width:100%;text-align:center;border-collapse:collapse;">';

              table +=
                "<thead><tr>" +
                '<th style="border:1px solid #ddd;padding:8px;">Date</th>' +
                '<th style="border:1px solid #ddd;padding:8px;">Heure</th>';

              if (this.activeTab === "volume") {
                table +=
                  '<th style="border:1px solid #ddd;padding:8px;">Volume (m³)</th>';
              } else if (this.activeTab === "niveau") {
                table +=
                  '<th style="border:1px solid #ddd;padding:8px;">Niveau (m)</th>';
              } else if (this.activeTab === "pourcentage") {
                table +=
                  '<th style="border:1px solid #ddd;padding:8px;">Pourcentage (%)</th>';
              }
              table += "</tr></thead>";

              table += "<tbody>";
              const data = opt.series[0].data;
              data.forEach((item: any) => {
                const date = new Date(
                  this.activeTab === "pourcentage" ? item[0] : item[0]
                );
                const formattedDate = date.toLocaleDateString("fr-FR");
                const formattedTime = date.toLocaleTimeString("fr-FR", {
                  hour: "2-digit",
                  minute: "2-digit",
                });

                table +=
                  "<tr>" +
                  `<td style="border:1px solid #ddd;padding:8px;">${formattedDate}</td>` +
                  `<td style="border:1px solid #ddd;padding:8px;">${formattedTime}</td>`;

                if (this.activeTab === "pourcentage") {
                  const value = item[1];
                  const remainingValue = 100 - value;
                  table +=
                    `<td style="border:1px solid #ddd;padding:8px;">${value.toFixed(
                      2
                    )}</td>` +
                    `<td style="border:1px solid #ddd;padding:8px;">${remainingValue.toFixed(
                      2
                    )}</td>`;
                } else {
                  const value = this.activeTab === "volume" ? item[1] : item[1];
                  table += `<td style="border:1px solid #ddd;padding:8px;">${value.toFixed(
                    2
                  )}</td>`;
                }
                table += "</tr>";
              });
              table += "</tbody></table>";

              return table;
            },
          },
          magicType: {
            show: true,
            type: ["line", "bar"],
            title: {
              bar: "Passer au graphique à barres",
              line: "Passer au graphique linéaire",
            },
          },
        },
        itemSize: 15,
        itemGap: 10,
        right: "10%",
      },
      dataZoom: [
        {
          type: "slider",
          start: 0,
          end: 100,
          height: 20,
          bottom: 10,
          borderColor: "#ccc",
          backgroundColor: "#f7f7f7",
          fillerColor: "rgba(24, 144, 255, 0.1)",
          handleStyle: {
            color: "#1890ff",
          },
          moveHandleSize: 6,
          textStyle: {
            color: "#666",
          },
        },
        {
          type: "inside",
          start: 0,
          end: 100,
        },
      ],
      animation: true,
      animationDuration: 300,
      animationEasing: "cubicOut",
    };
  }

  private createStackedBarOptions(activeData: any[]): EChartsOption {
    return {
      title: {
        text: this.chartTitle,
        textStyle: {
          fontSize: 16,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (params: any) => {
          const date = new Date(Number(params[0].axisValue));
          return `${date.toLocaleDateString("fr-FR")} ${date.toLocaleTimeString(
            "fr-FR",
            {
              hour: "2-digit",
              minute: "2-digit",
            }
          )}<br/>
          Taux de remplissage : ${params[0].data[1].toFixed(2)}%<br/>`;
        },
      },
      legend: {
        data: ["Valeur", "1 - Valeur"],
        top: 0,
      },
      xAxis: {
        type: "time",
        boundaryGap: true,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#eef5f9",
            type: "solid",
          },
        },
        axisLabel: {
          formatter: (value: number) => {
            const date = new Date(value);
            if (this.timeViewMode === "hourly") {
              return `${date.getDate()}/${
                date.getMonth() + 1
              }\n${date.getHours()}:00`;
            }
            return `${date.getDate()}/${date.getMonth() + 1}`;
          },
        },
      },
      yAxis: {
        type: "value",
        min: 0,
        max: 100,
        axisLabel: {
          formatter: "{value}%",
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#eef5f9",
            type: "solid",
          },
        },
      },

      series: [
        {
          type: "bar",

          stack: "total",
          connectNulls: true,
          itemStyle: {
            color: "#5CA0A9",
          },
          emphasis: {
            focus: "series",
          },
          data: activeData.map((item) => [item.time, item.originalValue]),
        },
        {
          type: "bar",
          stack: "total",
          connectNulls: true,
          showSymbol: true,
          itemStyle: {
            color: "#2069A3",
            opacity: 0.2,
          },
          emphasis: {
            focus: "series",
          },
          data: activeData.map((item) => [item.time, item.reducedValue]),
        },
      ],
      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            title: {
              zoom: "Zoom de zone",
              back: "Restaurer le zoom de la zone",
            },
            yAxisIndex: "none",
          },
          restore: {
            title: "Actualisé",
          },
          saveAsImage: {
            title: "Enregistré image",
          },
          mark: { show: true },
          dataView: {
            lang: ["Tableau des valeurs", "Retour", "Actualisé"],
            title: "Voir les données",
            readOnly: true,
            optionToContent: (opt: any) => {
              let table =
                '<table style="width:100%;text-align:center;border-collapse:collapse;">';

              table +=
                "<thead><tr>" +
                '<th style="border:1px solid #ddd;padding:8px;">Date</th>' +
                '<th style="border:1px solid #ddd;padding:8px;">Heure</th>';

              if (this.activeTab === "volume") {
                table +=
                  '<th style="border:1px solid #ddd;padding:8px;">Volume (m³)</th>';
              } else if (this.activeTab === "niveau") {
                table +=
                  '<th style="border:1px solid #ddd;padding:8px;">Niveau (m)</th>';
              } else if (this.activeTab === "pourcentage") {
                table +=
                  '<th style="border:1px solid #ddd;padding:8px;">Taux de remplissage (%)</th>';
              }
              table += "</tr></thead>";

              table += "<tbody>";
              const data = opt.series[0].data;
              data.forEach((item: any) => {
                const date = new Date(
                  this.activeTab === "pourcentage" ? item[0] : item[0]
                );
                const formattedDate = date.toLocaleDateString("fr-FR");
                const formattedTime = date.toLocaleTimeString("fr-FR", {
                  hour: "2-digit",
                  minute: "2-digit",
                });

                table +=
                  "<tr>" +
                  `<td style="border:1px solid #ddd;padding:8px;">${formattedDate}</td>` +
                  `<td style="border:1px solid #ddd;padding:8px;">${formattedTime}</td>`;

                if (this.activeTab === "pourcentage") {
                  const value = item[1];
                  const remainingValue = 100 - value;
                  table += `<td style="border:1px solid #ddd;padding:8px;">${value.toFixed(
                    2
                  )}</td>`;
                } else {
                  const value = this.activeTab === "volume" ? item[1] : item[1];
                  table += `<td style="border:1px solid #ddd;padding:8px;">${value.toFixed(
                    2
                  )}</td>`;
                }
                table += "</tr>";
              });
              table += "</tbody></table>";

              return table;
            },
          },
          magicType: {
            show: true,
            type: ["line", "bar"],
            title: {
              bar: "Passer au graphique à barres",
              line: "Passer au graphique linéaire",
            },
          },
        },
        itemSize: 15,
        itemGap: 10,
        right: "10%",
      },
      dataZoom: [
        {
          type: "slider",
          start: 0,
          end: 100,
          height: 20,
          bottom: 10,
          borderColor: "#ccc",
          backgroundColor: "#f7f7f7",
          fillerColor: "rgba(24, 144, 255, 0.1)",
          handleStyle: {
            color: "#1890ff",
          },
          moveHandleSize: 6,
          textStyle: {
            color: "#666",
          },
        },
        {
          type: "inside",
          start: 0,
          end: 100,
        },
      ],
    };
  }

  private formatDateForFileName(date: Date): string {
    if (!date) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}${month}${year}`;
  }

  exportToExcel() {
    const startDate = this.formatDateForFileName(this.dateRange.start);
    const endDate = this.formatDateForFileName(this.dateRange.end);
    const fileName = `${this.basinMatricule}_${startDate}_${endDate}.xls`;
    const workbook = XLSX.utils.book_new();

    const request: VolumeRequest = {
      startDate: this.dateRange.start,
      endDate: this.dateRange.end,
      id: this._id,
    };

    if (this.timeViewMode === "hourly") {
      this.basinService.fetchVolumePerHours(request).subscribe({
        next: (volumeData) => {
          if (volumeData && volumeData.length > 0) {
            const volumeSheet = this.formatDataForExcel(volumeData, "volume");
            const volumeWorksheet = XLSX.utils.json_to_sheet(volumeSheet);
            XLSX.utils.book_append_sheet(workbook, volumeWorksheet, "Volume");
          }

          this.basinService.fetchNiveauPerHours(request).subscribe({
            next: (niveauData) => {
              if (niveauData && niveauData.length > 0) {
                const niveauSheet = this.formatDataForExcel(
                  niveauData,
                  "niveau"
                );
                const niveauWorksheet = XLSX.utils.json_to_sheet(niveauSheet);
                XLSX.utils.book_append_sheet(
                  workbook,
                  niveauWorksheet,
                  "Niveau"
                );
              }

              this.basinService.fetchPourcentagePerHours(request).subscribe({
                next: (pourcentageData) => {
                  if (pourcentageData && pourcentageData.length > 0) {
                    const pourcentageSheet = this.formatDataForExcel(
                      pourcentageData,
                      "pourcentage"
                    );
                    const pourcentageWorksheet =
                      XLSX.utils.json_to_sheet(pourcentageSheet);
                    XLSX.utils.book_append_sheet(
                      workbook,
                      pourcentageWorksheet,
                      "Pourcentage"
                    );
                  }

                  if (workbook.SheetNames.length > 0) {
                    XLSX.writeFile(workbook, fileName);
                  }
                },
                error: (error) =>
                  console.error("Error fetching pourcentage data:", error),
              });
            },
            error: (error) =>
              console.error("Error fetching niveau data:", error),
          });
        },
        error: (error) => console.error("Error fetching volume data:", error),
      });
    } else {
      this.basinService.PerformanceVolumeWaterPerDay(request).subscribe({
        next: (volumeData) => {
          if (volumeData && volumeData.length > 0) {
            const volumeSheet = this.formatDataForExcel(volumeData, "volume");
            const volumeWorksheet = XLSX.utils.json_to_sheet(volumeSheet);
            XLSX.utils.book_append_sheet(workbook, volumeWorksheet, "Volume");
          }

          this.basinService.PerformanceNiveauBasinPerDay(request).subscribe({
            next: (niveauData) => {
              if (niveauData && niveauData.length > 0) {
                const niveauSheet = this.formatDataForExcel(
                  niveauData,
                  "niveau"
                );
                const niveauWorksheet = XLSX.utils.json_to_sheet(niveauSheet);
                XLSX.utils.book_append_sheet(
                  workbook,
                  niveauWorksheet,
                  "Niveau"
                );
              }

              this.basinService
                .PerformancePourcentageBasinPerDay(request)
                .subscribe({
                  next: (pourcentageData) => {
                    if (pourcentageData && pourcentageData.length > 0) {
                      const pourcentageSheet = this.formatDataForExcel(
                        pourcentageData,
                        "pourcentage"
                      );
                      const pourcentageWorksheet =
                        XLSX.utils.json_to_sheet(pourcentageSheet);
                      XLSX.utils.book_append_sheet(
                        workbook,
                        pourcentageWorksheet,
                        "Pourcentage"
                      );
                    }

                    if (workbook.SheetNames.length > 0) {
                      XLSX.writeFile(workbook, fileName);
                    }
                  },
                  error: (error) =>
                    console.error(
                      "Error fetching daily pourcentage data:",
                      error
                    ),
                });
            },
            error: (error) =>
              console.error("Error fetching daily niveau data:", error),
          });
        },
        error: (error) =>
          console.error("Error fetching daily volume data:", error),
      });
    }
  }

  private formatDataForExcel(data: any[], type: string) {
    return data.map((item) => {
      const date = new Date(item.time);
      const baseFormat = {
        Date: date.toLocaleDateString("fr-FR"),
      };

      if (this.timeViewMode === "hourly") {
        baseFormat["Heure"] = date.toLocaleTimeString("fr-FR", {
          hour: "2-digit",
          minute: "2-digit",
        });
      }

      switch (type) {
        case "volume":
          return {
            ...baseFormat,
            "Volume (m³)": parseFloat(item.volume).toFixed(2),
          };
        case "niveau":
          return {
            ...baseFormat,
            "Niveau (m)": parseFloat(item.niveau).toFixed(2),
          };
        case "pourcentage":
          return {
            ...baseFormat,
            "Pourcentage (%)": (parseFloat(item.pourcentage) * 100).toFixed(2),
          };
        default:
          return baseFormat;
      }
    });
  }
}
