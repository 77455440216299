<div class="modal-overlay" (click)="closeModal()">
  <nb-card class="modal-content" (click)="$event.stopPropagation()">
    <nb-card-header class="modal-header">
      <h2>{{ getModalTitle() }}</h2>
      <button class="close-btn" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </nb-card-header>
    <nb-card-body class="modal-body">
      <div *ngIf="isLoading" class="loading-spinner">
        <nb-spinner></nb-spinner>
      </div>
      <div *ngIf="!isLoading" class="chart-container">
        <div *ngIf="hasNegativeValues" class="negative-data-overlay">
          <i class="fas fa-exclamation-triangle warning-icon"></i>
          <span class="error-message">
            Une erreur de mesure a été détectée pendant cette période.<br />
            Veuillez vérifier le fonctionnement du compteur entre 
            <strong>{{ startDate | date: 'dd/MM/yyyy' }}</strong> et 
            <strong>{{ endDate | date: 'dd/MM/yyyy' }}</strong>.
          </span>
        </div>
        <div *ngIf="!hasNegativeValues" echarts [options]="chartOption" class="chart"></div>
      </div>
    </nb-card-body>
  </nb-card>
</div>
