<div *ngIf="isOpen" class="modal-overlay" (click)="close()">
  <div class="modal-container" (click)="$event.stopPropagation()">
    <div class="modal-header">
      <div class="header-content">
        <h2>{{ title }}</h2>
        <div class="header-actions">
          <button class="icon-button" (click)="viewImage()">
            <i class="fas fa-image"></i>
            <span>Voir l'image</span>
          </button>
          <button class="icon-button close-button" (click)="close()">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="modal-content">
      <div *ngIf="!counterData" class="loading-state">
        <div class="spinner"></div>
        <p>Chargement des informations...</p>
      </div>
      <ng-container *ngIf="counterData">
        <div class="info-section">
          <div class="section-header">
            <i class="fas fa-tachometer-alt section-icon"></i>
            <h3>Informations sur le compteur</h3>
          </div>
          <div class="info-grid">
            <div class="info-item">
              <label>
                <i class="fas fa-ruler-horizontal"></i>
                DN
              </label>
              <span>{{ counterData.diametre || "-" }} mm</span>
            </div>
            <div class="info-item">
              <label>
                <i class="fas fa-calendar-alt"></i>
                Date d'installation
              </label>
              <span>{{ counterData.date_installation || "-" }}</span>
            </div>
            <div class="info-item">
              <label>
                <i class="fas fa-wave-square"></i>
                Pulsation
              </label>
              <span>{{ counterData.pulsation || "-" }} L</span>
            </div>
            <div class="info-item">
              <label>
                <i class="fas fa-clock"></i>
                Fréquence d'envoi
              </label>
              <span>{{ counterData.freq || "-" }} sur 24h</span>
            </div>
          </div>
        </div>
        <div class="info-section">
          <div class="section-header">
            <i class="fas fa-water section-icon"></i>
            <h3>Informations sur le puit</h3>
          </div>
          <div class="info-grid">
            <div class="info-item">
              <label>
                <i class="fas fa-pump"></i>
                Type de pompe
              </label>
              <span>{{ counterData.type_pompe || "-" }}</span>
            </div>
            <div class="info-item">
              <label>
                <i class="fas fa-tachometer"></i>
                Débit nominal
              </label>
              <span>{{ counterData.debit_nominal || "-" }} m³/h</span>
            </div>
            <div class="info-item">
              <label>
                <i class="fas fa-hashtag"></i>
                Matricule
              </label>
              <span>{{ counterData.matricule || "-" }}</span>
            </div>
            <div class="info-item">
              <label>
                <i class="fas fa-barcode"></i>
                N° de série
              </label>
              <span>{{ counterData.s_n || "-" }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="showImageViewer" class="image-overlay" (click)="closeImage()">
    <div class="image-container" (click)="$event.stopPropagation()">
      <img [src]="image" [alt]="title" />
      <button class="close-button" (click)="closeImage()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</div>
