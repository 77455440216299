import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { EChartsOption, graphic } from "echarts";
import { PiezometreService } from "../../../../@core/services/piezometre/piezometre.service";
import { ExcelExportService } from "../../../../@core/services/excel/excel-export.service";
import { ChartData } from "../../../../@core/types/chart-data";
import { MinMaxData } from "../../../../@core/types/max-min";

@Component({
  selector: "ngx-water-history",
  templateUrl: "./water-history.component.html",
  styleUrls: ["./water-history.component.scss"],
})
export class WaterHistoryComponent implements OnInit, OnChanges {
  @Input() selectedPiezoId: number;
  @Input() selectedPiezometreData: any;
  @Input() selectedMatricule: string = "";
  startDate: Date;
  endDate: Date;
  private combinedChart: any;
  combinedChartOption: EChartsOption;
  showCombined: boolean = false;

  private piezoChart: any;
  private counterChart: any;

  piezoChartOption: EChartsOption;
  counterChartOption: EChartsOption;

  piezoData: ChartData[] = [];
  counterData: ChartData[] = [];
  minMaxPiezoData: MinMaxData[] = [];
  minMaxCounterData: MinMaxData[] = [];

  first: number = 0;
  rows: number = 9;
  totalRecords: number = 0;

  constructor(
    private piezoService: PiezometreService,
    private excelService: ExcelExportService
  ) {
    this.endDate = new Date("2024-08-02");
    this.startDate = new Date("2024-08-01");
    this.initChartOptions();
  }

  ngOnInit(): void {
    if (this.selectedPiezoId) {
      this.fetchPiezometreData();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedPiezoId"] && this.selectedPiezoId) {
      this.fetchPiezometreData();
    }
  }

  onPiezoChartInit(event: any) {
    this.piezoChart = event;
    this.setupChartSync();
  }

  onCounterChartInit(event: any) {
    this.counterChart = event;
    this.setupChartSync();
  }

  private setupChartSync() {
    if (this.piezoChart && this.counterChart && this.showCombined) {
      this.piezoChart.on("mouseover", (params: any) => {
        const xIndex = params.dataIndex;

        this.counterChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: xIndex,
        });
        this.counterChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: xIndex,
        });
      });

      this.piezoChart.on("mouseout", () => {
        this.counterChart.dispatchAction({
          type: "hideTip",
        });
        this.counterChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
        });
      });

      this.counterChart.on("mouseover", (params: any) => {
        const xIndex = params.dataIndex;

        this.piezoChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: xIndex,
        });
        this.piezoChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: xIndex,
        });
      });

      this.counterChart.on("mouseout", () => {
        this.piezoChart.dispatchAction({
          type: "hideTip",
        });
        this.piezoChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
        });
      });

      this.piezoChart.connect("waterData");
      this.counterChart.connect("waterData");
    }
  }

  toggleView() {
    this.showCombined = !this.showCombined;
    setTimeout(() => {
      this.updateChartOptions();
    });
  }

  onDateRangeChange(event: any) {
    if (event.value) {
      this.startDate = event.value.start;
      this.endDate = event.value.end;
      this.fetchPiezometreData();
    }
  }

  private calculateMinMaxAvg(
    data: ChartData[],
    type: string,
    unit: string
  ): MinMaxData {
    if (!data.length)
      return {
        title: type,
        min: "0",
        max: "0",
        average: "0",
        unit: unit,
      };

    const values = data.map((item) => item.value);
    const min = Math.min(...values);
    const max = Math.max(...values);
    const avg = values.reduce((a, b) => a + b, 0) / values.length;

    return {
      title: type,
      min: min.toFixed(2),
      max: max.toFixed(2),
      average: avg.toFixed(2),
      unit: unit,
    };
  }

  formatDateRange(start: Date, end: Date): string {
    return `${start.toLocaleDateString("fr-FR")} - ${end.toLocaleDateString(
      "fr-FR"
    )}`;
  }
  combinedMinMaxData: MinMaxData[] = [];

  private fetchPiezometreData() {
    if (!this.selectedPiezoId || !this.startDate || !this.endDate) return;

    const request = {
      startDate: this.startDate,
      endDate: this.endDate,
      id: this.selectedPiezoId,
    };

    this.piezoService.fetchPiezoData(request).subscribe({
      next: (response: any) => {
        if (response) {
          const piezoPairs = response.piezometre
            .filter((item: any) => item.value !== null && item.value > -100)
            .map((item: any) => ({
              date: new Date(item.time),
              displayTime: new Date(item.time).toLocaleString("fr-FR", {
                day: "2-digit",
                month: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                year: "numeric",
              }),
              value: Number(item.value),
            }))
            .sort((a, b) => a.date.getTime() - b.date.getTime());

          const counterPairs = response.compteur
            .map((item: any) => ({
              date: new Date(item.time),
              displayTime: new Date(item.time).toLocaleString("fr-FR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }),
              value: Number(item.difference) / 1000,
            }))
            .sort((a, b) => a.date.getTime() - b.date.getTime());

          this.piezoData = piezoPairs.map((item) => ({
            time: item.displayTime,
            value: item.value,
          }));

          this.counterData = counterPairs.map((item) => ({
            time: item.displayTime,
            value: item.value,
          }));

          this.minMaxPiezoData = [
            this.calculateMinMaxAvg(this.piezoData, "Piézomètre", " m"),
          ];
          this.minMaxCounterData = [
            this.calculateMinMaxAvg(this.counterData, "Compteur", " m³"),
          ];

          this.combinedMinMaxData = [
            this.calculateMinMaxAvg(this.piezoData, "Piézomètre", " m"),
            this.calculateMinMaxAvg(this.counterData, "Compteur", " m³"),
          ];

          this.updateChartOptions();
        }
      },
      error: (error) => {
        console.error("Error fetching data:", error);
        this.piezoData = [];
        this.counterData = [];
        this.minMaxPiezoData = [];
        this.minMaxCounterData = [];
        this.combinedMinMaxData = [];
      },
    });
  }

  private initChartOptions() {
    const baseOptions: EChartsOption = {
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
          animation: false,
          snap: true,
        },
        backgroundColor: "rgba(49, 38, 38, 0.9)",
        borderColor: "#ccc",
        borderWidth: 1,
        padding: [5, 10],
        textStyle: {
          color: "#333",
        },
        formatter: (params: any) => {
          if (Array.isArray(params)) {
            const date = params[0].name;
            const values = params.reduce((acc: any, curr: any) => {
              acc[curr.seriesName] = curr.value;
              return acc;
            }, {});

            if (this.showCombined) {
              return `
                <div style="font-weight: bold; margin-bottom: 3px;">${date}</div>
                <div style="color: #1890ff">
                  <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #1890ff; margin-right: 10px"></span>
                  Profondeur: ${values["Profondeur"]?.toFixed(2)} m
                </div>
                <div style="color: #45a0d1">
                  <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #45a0d1; margin-right: 10px"></span>
                  Compteur: ${values["Compteur"]?.toFixed(2)} m³
                </div>
              `;
            } else {
              const value = params[0].value;
              const unit = params[0].seriesName === "Profondeur" ? "m" : "m³";
              return `
                <div style="font-weight: bold; margin-bottom: 3px;">${date}</div>
                <div style="color: ${params[0].color}">
                  <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: ${
                    params[0].color
                  }; margin-right: 5px"></span>
                  ${params[0].seriesName}: ${value?.toFixed(2)} ${unit}
                </div>
              `;
            }
          }
          return "";
        },
      },

      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            title: {
              zoom: "Zoom",
              back: "Retour",
            },
          },
          magicType: {
            show: true,
            type: ["line", "bar"],
            title: {
              line: "Ligne",
              bar: "Barre",
            },
          },
          restore: {
            show: true,
            title: "Réinitialiser",
          },
          saveAsImage: {
            show: true,
            title: "Télécharger",
            type: "png",
            pixelRatio: 2,
            excludeComponents: ["toolbox"],
          },
          dataView: {
            lang: ["-", "Retour", "Actualisé"],
            title: "Voir les données",
            readOnly: true,
          },
        },
        right: 20,
        top: 20,
      },
      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 100,
          zoomOnMouseWheel: true,
        },
        {
          type: "slider",
          start: 0,
          end: 100,
          borderColor: "#ccc",
          backgroundColor: "#f7f7f7",
          fillerColor: "rgba(24, 144, 255, 0.1)",
          handleStyle: {
            color: "#1890ff",
          },
          moveHandleSize: 6,
          textStyle: {
            color: "#666",
          },
          bottom: 10,
        },
      ],
      animation: true,
      animationDuration: 300,
      animationEasing: "cubicOut",
    };

    this.piezoChartOption = {
      ...baseOptions,
      xAxis: {
        type: "category",
        boundaryGap: true,
        axisLabel: {
          show: false,
          rotate: 45,
          formatter: (value: string) => value.split(",")[0],
        },
      },
      yAxis: {
        type: "value",
        name: "Piézomètre (m)",
        nameLocation: "middle",
        nameGap: 40,
        nameTextStyle: {
          color: "#666",
          fontSize: 12,
          fontWeight: "bold",
        },
        axisLabel: {
          formatter: "{value} m",
          color: "#666",
        },
        min: (value: number) => value - 5,
      },
    };

    this.counterChartOption = {
      ...baseOptions,
      xAxis: {
        type: "category",
        boundaryGap: true,
        axisLabel: {
          show: false,
          rotate: 45,
          formatter: (value: string) => value.split(",")[0],
        },
      },
      yAxis: {
        type: "value",
        name: "Compteur (m³/h)",
        nameLocation: "middle",
        nameGap: 40,
        nameTextStyle: {
          color: "#666",
          fontSize: 12,
          fontWeight: "bold",
        },
        axisLabel: {
          formatter: "{value} m³",
          color: "#666",
        },
      },
    };

    this.combinedChartOption = {
      ...baseOptions,

      title: {
        text: "Piézomètre et Compteur",
        left: "center",
        textStyle: {
          fontSize: 16,
          fontWeight: "normal",
        },
      },
      legend: {
        data: ["Piézomètre", "Compteur"],
        left: 10,
        top: 10,
      },
      xAxis: {
        type: "category",
        show: false,
        boundaryGap: true,
        axisLabel: {
          rotate: 45,
          formatter: (value: string) => {
            const date = new Date(value);
            return `${
              date.getMonth() + 1
            }/${date.getDate()}\n${date.getHours()}:00`;
          },
        },
      },
      yAxis: [
        {
          name: "Piézomètre (m)",
          type: "value",
          position: "left",
          axisLine: {
            show: true,
            lineStyle: { color: "#5470c6" },
          },
          axisLabel: {
            formatter: "{value} m",
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#5470c6",
            },
          },
        },
        {
          name: "Compteur (m³/h)",
          nameLocation: "start",
          type: "value",
          position: "right",
          axisLine: {
            show: true,
            lineStyle: { color: "#5CA0A9" },
          },
          axisLabel: {
            formatter: "{value} m³/h",
          },
          splitLine: {
            show: false,
          },
        },
      ],
    };
  }

  private updateChartOptions() {
    const lineStyle = {
      type: "line",
      areaStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgb(128, 182, 246)" },
          { offset: 1, color: "rgb(235, 245, 255)" },
        ]),
      },
      lineStyle: {
        color: "#1890ff",
        width: 3,
      },
      symbol: "circle",
      symbolSize: 6,
      smooth: true,
      emphasis: {
        focus: "series",
        itemStyle: {
          borderWidth: 2,
        },
      },
    };

    if (this.showCombined) {
      this.combinedChartOption = {
        series: [
          {
            name: "Profondeur",
            type: "line",
            data: this.piezoData.map((item) => item.value),
            showSymbol: true,
            areaStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgb(128, 182, 246)" },
                { offset: 1, color: "rgb(235, 245, 255)" },
              ]),
            },
            lineStyle: {
              color: "#1890ff",
              width: 3,
            },
            symbol: "circle",
            symbolSize: 6,
            itemStyle: {
              color: "#4169E1",
              borderWidth: 2,
              borderColor: "#4169E1",
            },
            smooth: true,
            emphasis: {
              focus: "series",
              itemStyle: {
                borderColor: "#fff",
                borderWidth: 2,
              },
            },
          },
          {
            name: "Compteur",
            type: "line",
            yAxisIndex: 1,
            data: this.counterData.map((item) => item.value),
            showSymbol: true,
            symbol: "circle",
            symbolSize: 6,
            itemStyle: {
              color: "#008080",
              borderWidth: 2,
              borderColor: "#008080",
            },
            smooth: true,
            areaStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgb(100,165,173)" },
                { offset: 1, color: "rgb(235, 245, 255)" },
              ]),
            },
            lineStyle: {
              width: 3,
              color: "#64A5AD",
            },
            emphasis: {
              focus: "series",
              itemStyle: {
                borderWidth: 3,
                borderColor: "#fff",
              },
            },
          },
        ],
        title: {
          text: "Piézomètre et Compteur",
          left: "center",
          textStyle: {
            fontSize: 16,
            fontWeight: "normal",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              backgroundColor: "#505765",
            },
          },
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: "none",
              title: {
                zoom: "Zoom",
                back: "Retour",
              },
            },
            magicType: {
              show: true,
              type: ["line", "bar"],
              title: {
                line: "Ligne",
                bar: "Barre",
              },
            },
            restore: {
              show: true,
              title: "Réinitialiser",
            },
            saveAsImage: {
              show: true,
              title: "Télécharger",
              type: "png",
              pixelRatio: 2,
              excludeComponents: ["toolbox"],
            },
            dataView: {
              lang: ["Données", "Retour", "Actualisé"],
              title: "Voir les données",
              readOnly: true,
              optionToContent: (opt: any) => {
                let table =
                  '<table style="width:100%;text-align:center;border-collapse:collapse;">';

                table +=
                  "<thead><tr>" +
                  '<th style="border:1px solid #ddd;padding:8px;">Date</th>' +
                  '<th style="border:1px solid #ddd;padding:8px;">Heure</th>' +
                  '<th style="border:1px solid #ddd;padding:8px;">Profondeur (m)</th>' +
                  '<th style="border:1px solid #ddd;padding:8px;">Compteur (m³/h)</th>' +
                  "</tr></thead>";

                table += "<tbody>";
                const data = opt.series[0].data;
                const counterData = opt.series[1].data;

                for (let i = 0; i < data.length; i++) {
                  const date = new Date(this.piezoData[i].time);
                  const formattedDate = date.toLocaleDateString("fr-FR");
                  const formattedTime = date.toLocaleTimeString("fr-FR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  });

                  table +=
                    "<tr>" +
                    `<td style="border:1px solid #ddd;padding:8px;">${formattedDate}</td>` +
                    `<td style="border:1px solid #ddd;padding:8px;">${formattedTime}</td>` +
                    `<td style="border:1px solid #ddd;padding:8px;">${data[
                      i
                    ].toFixed(2)}</td>` +
                    `<td style="border:1px solid #ddd;padding:8px;">${counterData[
                      i
                    ].toFixed(2)}</td>` +
                    "</tr>";
                }
                table += "</tbody></table>";

                return table;
              },
            },
          },
          right: 20,
          top: 20,
        },
        legend: {
          data: ["Profondeur", "Compteur"],
          left: 10,
          top: 10,
        },

        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0],
          },
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0],
          },
        ],
        xAxis: {
          type: "category",
          boundaryGap: false,
          show: false,
          data: this.piezoData.map((item) => item.time),
          axisLine: { onZero: false },
          axisLabel: {
            formatter: (value: string) => {
              const date = new Date(value);
              return `${
                date.getMonth() + 1
              }/${date.getDate()}\n${date.getHours()}:00`;
            },
          },
        },
        yAxis: [
          {
            // name: "Profondeur (m)",
            type: "value",
            position: "left",
            axisLine: {
              show: true,
              lineStyle: { color: "#5470c6" },
            },
            axisLabel: {
              formatter: "{value} m",
              color: "#000000",
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#5470c6",
              },
            },
            min: (value: number) => {
              const minValue = Math.min(
                ...this.piezoData.map((item) => item.value)
              );
              return minValue - 5;
            },
          },
          {
            // name: "Compteur (m³/h)",
            nameLocation: "start",
            type: "value",
            position: "right",
            axisLine: {
              show: true,
              lineStyle: { color: "#5CA0A9" },
            },
            axisLabel: {
              formatter: "{value} m³/h",
              color: "#000000",
            },
            splitLine: {
              show: false,
            },
          },
        ],
      };
    } else {
      const piezoSeries = {
        name: "Profondeur",
        data: this.piezoData.map((item) => item.value),
        ...lineStyle,
      };

      this.piezoChartOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          formatter: (params: any) => {
            if (Array.isArray(params)) {
              const param = params[0];
              return `
                <div style="margin: 0px 0 0;line-height:1;">
                  <div style="font-size:14px;color:#FFFFF;font-weight:400;line-height:1;">
                    ${param.name}
                  </div>
                  <div style="margin: 10px 0 0;line-height:1;">
                    <div style="margin-left:2px;display:inline-block;width:10px;height:10px;background-color:${
                      param.color
                    };border-radius:5px;"></div>
                    <span style="margin-left:5px;font-size:14px;color:#FFFFF;font-weight:400;margin-left:2px;">
                      ${param.seriesName}
                    </span>
                    <span style="float:right;margin-left:20px;font-size:14px;color:#FFFFF;font-weight:900;">
                      ${param.value.toFixed(2)} m
                    </span>
                  </div>
                </div>
              `;
            }
            return "";
          },
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: "none",
              title: {
                zoom: "Zoom",
                back: "Retour",
              },
            },
            magicType: {
              show: true,
              type: ["line", "bar"],
              title: {
                line: "Ligne",
                bar: "Barre",
              },
            },
            restore: {
              show: true,
              title: "Réinitialiser",
            },
            saveAsImage: {
              show: true,
              title: "Télécharger",
              type: "png",
              pixelRatio: 2,
              excludeComponents: ["toolbox"],
            },
            dataView: {
              lang: ["Tableau des valeurs", "Retour", "Actualisé"],
              title: "Voir les données",
              readOnly: true,
              optionToContent: (opt: any) => {
                let table =
                  '<table style="width:100%;text-align:center;border-collapse:collapse;">';

                table +=
                  "<thead><tr>" +
                  '<th style="border:1px solid #ddd;padding:8px;">Date</th>' +
                  '<th style="border:1px solid #ddd;padding:8px;">Heure</th>' +
                  '<th style="border:1px solid #ddd;padding:8px;">Profondeur (m)</th>' +
                  "</tr></thead>";

                table += "<tbody>";
                const data = opt.series[0].data;

                for (let i = 0; i < data.length; i++) {
                  const date = new Date(this.piezoData[i].time);
                  const formattedDate = date.toLocaleDateString("fr-FR");
                  const formattedTime = date.toLocaleTimeString("fr-FR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  });

                  table +=
                    "<tr>" +
                    `<td style="border:1px solid #ddd;padding:8px;">${formattedDate}</td>` +
                    `<td style="border:1px solid #ddd;padding:8px;">${formattedTime}</td>` +
                    `<td style="border:1px solid #ddd;padding:8px;">${data[
                      i
                    ].toFixed(2)}</td>` +
                    "</tr>";
                }
                table += "</tbody></table>";

                return table;
              },
            },
          },
          right: 20,
          top: 20,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          show: false,
          data: this.piezoData.map((item) => item.time),
          axisLabel: {
            rotate: 45,
            formatter: (value: string) => value.split(",")[0],
          },
        },
        yAxis: {
          type: "value",
          name: "Profondeur (m)",
          nameLocation: "middle",
          nameGap: 50,
          nameTextStyle: {
            color: "#666",
            fontSize: 14,
            fontWeight: "bold",
          },
          axisLabel: {
            formatter: "{value} m",
          },
          min: (value: number) => {
            const minValue = Math.min(
              ...this.piezoData.map((item) => item.value)
            );
            return minValue - 5;
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            show: true,
            type: "slider",
            bottom: "2%",
            start: 0,
            end: 100,
          },
        ],
        series: [piezoSeries],
      };

      this.counterChartOption = {
        ...this.counterChartOption,
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: "none",
              title: {
                zoom: "Zoom",
                back: "Retour",
              },
            },
            magicType: {
              show: true,
              type: ["line", "bar"],
              title: {
                line: "Ligne",
                bar: "Barre",
              },
            },
            restore: {
              show: true,
              title: "Réinitialiser",
            },
            saveAsImage: {
              show: true,
              title: "Télécharger",
              type: "png",
              pixelRatio: 2,
              excludeComponents: ["toolbox"],
            },
            dataView: {
              lang: ["Tableau des valeurs", "Retour", "Actualisé"],
              title: "Voir les données",
              readOnly: true,
              optionToContent: (opt: any) => {
                let table =
                  '<table style="width:100%;text-align:center;border-collapse:collapse;">';

                table +=
                  "<thead><tr>" +
                  '<th style="border:1px solid #ddd;padding:8px;">Date</th>' +
                  '<th style="border:1px solid #ddd;padding:8px;">Heure</th>' +
                  '<th style="border:1px solid #ddd;padding:8px;">Compteur (m³/h)</th>' +
                  "</tr></thead>";

                table += "<tbody>";
                const data = opt.series[0].data;

                for (let i = 0; i < data.length; i++) {
                  const date = new Date(this.counterData[i].time);
                  const formattedDate = date.toLocaleDateString("fr-FR");
                  const formattedTime = date.toLocaleTimeString("fr-FR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  });

                  table +=
                    "<tr>" +
                    `<td style="border:1px solid #ddd;padding:8px;">${formattedDate}</td>` +
                    `<td style="border:1px solid #ddd;padding:8px;">${formattedTime}</td>` +
                    `<td style="border:1px solid #ddd;padding:8px;">${data[
                      i
                    ].toFixed(2)}</td>` +
                    "</tr>";
                }
                table += "</tbody></table>";

                return table;
              },
            },
          },

          top: "5px",
        },
      };
    }

    setTimeout(() => {
      this.setupChartSync();
    });
  }

  exportToExcel() {
    if (
      !this.selectedPiezoId ||
      (!this.piezoData.length && !this.counterData.length)
    )
      return;

    const piezoData = this.piezoData.map((item) => ({
      "Date et Heure": item.time,
      "Profondeur (m)": item.value,
    }));

    if (this.showCombined && this.counterData.length > 0) {
      const counterData = this.counterData.map((item) => ({
        "Date et Heure": item.time,
        "Compteur (m³)": item.value,
      }));

      this.excelService.exportPiezoToExcel({
        piezoData,
        counterData,
        startDate: this.startDate,
        endDate: this.endDate,
        matricule:
          this.selectedPiezometreData?.matricule || this.selectedMatricule,
      });
    } else {
      this.excelService.exportPiezoToExcel({
        piezoData,
        startDate: this.startDate,
        endDate: this.endDate,
        matricule:
          this.selectedPiezometreData?.matricule || this.selectedMatricule,
      });
    }
  }
}
