export const environment = {
  production: false,
  LOGIN_API: "http://196.206.231.215:3030/login/",
  LOGOUT_API: "http://196.206.231.215:3030/logout/",
  PIEZOMETRE_API: "http://196.206.231.215:3030/user_piezos_new/",
  PIEZOMETRE_VOLUME_API: "http://196.206.231.215:3030/niveau_volume_data/",
  PIEZOMETRE_HOUR_DATA_API: "http://196.206.231.215:3030/piezo_comp_Pt/",
  PIEZOMETRE_DATA_API: "http://196.206.231.215:3030/piezoPt/",
  LAST_PIEZO: "http://196.206.231.215:3030/Last_piezo/",
  BASIN_API: "http://196.206.231.215:3030/user_capt_niv1",
  COUNTER_API: "http://196.206.231.215:3030/compteurs_mob_2",
  COUNTERS_INFOS: "http://196.206.231.215:3030/user_counters",
  COUNTER_PER_DAY: "http://196.206.231.215:3030/counter_data1/",
  COUNTER_PER_MONTH: "http://196.206.231.215:3030/counter_month",
  COUNTER_PER_HOUR: "http://196.206.231.215:3030/one_counter",
  COUNTER_PER_ID: "http://196.206.231.215:3030/user_counter",
  COUNTERS_API: "http://196.206.231.215:3030/user_counters",
  BILAN_STOCKAGE_API: "http://196.206.231.215:3030/bilan_stockage",
  PERFORMANCE_WATER_API: "http://196.206.231.215:3030/niveau_volume_data",
  PERFORMANCE_PER_DAY_WATER_API: "http://196.206.231.215:3030/niveau_volume_data_by_day",
  LAST_WATER_API: "http://196.206.231.215:3030/last_niveau_volume_data",
  PERFORMANCE_POURCENTAGE_PER_DAY_WATER_API: "http://196.206.231.215:3030/niveau_volume_data_by_day",
  BILAN_DAY_API: "http://196.206.231.215:3030/bilan_entrees/day",
  BILAN_MONTH_PI: "http://196.206.231.215:3030/bilan_entrees/month",
  REFOULEMENT_API: "http://196.206.231.215:3030/bilan_refoulement",
  
  BILAN_ENTRIES_API : "http://196.206.231.215:3030/bilan_entrees/",
  CAPT_IMAGES : "http://196.206.231.215:3030/capt-image/",
  COUNTER_HOURLY : "http://196.206.231.215:3030/counter_date/",
  PARCELLES_api : "http://196.206.231.215:3030/parcelles/",
  PARCELLES_api_2 : "http://196.206.231.215:3030/parcelles_mobile/",  
  WATER_SOURCE_MULTIPLE_API : "  http://196.206.231.215:3030/bilan_entrees_ensemble/"  
};
