<div class="water-performance-container">
  <div class="dashboard-fixed-bar">
    <div class="control-group">
      <div class="date-picker-group">
        <label>Choisir l'intervalle de temps :</label>
        <div class="input-wrapper">
          <input
            nbInput
            [nbDatepicker]="rangepicker"
            [(ngModel)]="dateRange"
            (ngModelChange)="onDateRangeChange()"
            class="date-input"
            placeholder="Select a date range"
          />
          <nb-rangepicker
            #rangepicker
            [(range)]="dateRange"
            [max]="maxDate"
            (rangeChange)="onDateRangeChange()"
          ></nb-rangepicker>
        </div>
      </div>
      <div class="btns">
        <div class="excel">
          <button class="export-btn" (click)="exportToExcel()">
            <i class="fas fa-file-excel"></i> XLS
          </button>
        </div>
        <div class="switch-group">
          <span class="switch-label">Heures</span>
          <label class="switch">
            <input
              type="checkbox"
              [checked]="timeViewMode === 'daily'"
              (change)="toggleTimeView()"
            />
            <span class="slider round"></span>
          </label>
          <span class="switch-label">Jours</span>
        </div>
      </div>
    </div>
  </div>

  <div class="chart-container">
    <div class="chart-header">
      <div class="metric-cards">
        <div
          class="metric-card"
          *ngFor="let tab of tabs"
          (click)="changeTab(tab.id)"
          (mouseenter)="onTabHover(tab.id)"
          (mouseleave)="onTabLeave()"
          [class.active]="activeTab === tab.id"
          [class.hover]="hoveredTab === tab.id && activeTab !== tab.id"
        >
          <i [class]="tab.icon"></i>
          <div class="metric-info">
            <span class="metric-label">{{ tab.label }}</span>
            <span class="metric-value">
              {{ tab.id === 'volume' ? (volumeUpdated | async) : tab.id === 'niveau' ? (niveauUpdated | async) : (pourcentageUpdated | async) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="chart-wrapper">
      <div echarts [options]="chartOptions" class="chart"></div>
    </div>
  </div>
</div>
