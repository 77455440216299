<div class="water-history-container">
  <h1>Historique des profondeurs de l'eau</h1>

  <div class="header">
    <div class="date-control">
      <span>Choisir l'intervalle de temps:</span>
      <div class="date-inputs">
        <nb-form-field>
          <input
            nbInput
            [nbDatepicker]="rangepicker"
            [value]="formatDateRange(startDate, endDate)"
            (dateChange)="onDateRangeChange($event)"
            placeholder="01/08/2024 - 15/08/2024"
          />
          <nb-rangepicker #rangepicker></nb-rangepicker>
        </nb-form-field>
      </div>
    </div>

    <div class="button-group">
      <button nbButton status="primary" (click)="toggleView()">
        {{ showCombined ? "AFFICHER PIÉZOMÈTRE" : "GRAPHIQUES CROISÉS" }}
      </button>
      <button class="export-btn" (click)="exportToExcel()">
        <i class="fas fa-file-excel"></i>
        XLS
      </button>
    </div>
  </div>

  <div class="content">
    <div class="main-section" [class.combined-view]="showCombined">
      <div class="chart-section" *ngIf="!showCombined">
        <h2>Piézomètre (m)</h2>
        <div
          echarts
          [options]="piezoChartOption"
          class="chart"
          (chartInit)="onPiezoChartInit($event)"
        ></div>

        <p-table [value]="minMaxPiezoData" styleClass="min-max-table">
          <ng-template pTemplate="header">
            <tr>
              <th>Min</th>
              <th>Max</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data>
            <tr>
              <td>{{ data.min }} m</td>
              <td>{{ data.max }} m</td>
              <!-- <td>{{ data.average }} m</td> -->
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="chart-section" *ngIf="showCombined">
        <h2>Graphiques CROISÉS</h2>
        <div
          echarts
          [options]="combinedChartOption"
          class="chart"
          (chartInit)="onCombinedChartInit($event)"
        ></div>

        <div class="stats-table-container">
          <p-table [value]="combinedMinMaxData" styleClass="min-max-table">
            <ng-template pTemplate="header">
              <tr>
                <th>Type</th>
                <th>Min</th>
                <th>Max</th>
                <!-- <th>Moyenne</th> -->
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
              <tr>
                <td>{{ data.title }}</td>
                <td>{{ data.min }}{{ data.unit }}</td>
                <td>{{ data.max }}{{ data.unit }}</td>
                <!-- <td>{{ data.average }}{{ data.unit }}</td> -->
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <div class="data-table-section">
        <h2>Tableau des valeurs</h2>
        <p-table
          [value]="piezoData"
          [paginator]="true"
          [rows]="rows"
          styleClass="values-table"
          [tableStyle]="{ width: '100%' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Date</th>
              <th>Profondeur (m)</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data>
            <tr>
              <td>{{ data.time }}</td>
              <td>{{ data.value }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
