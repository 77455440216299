import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LoginRequest } from "../../types/login-request";
import { LoginResponse } from "../../types/login-response";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment.prod";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private readonly LOGIN_API = environment.LOGIN_API;
  private readonly LOGOUT_API = environment.LOGOUT_API;
  private readonly TOKEN_KEY = "token";
  private readonly TOKEN_EXPIRY_KEY = "auth_token_expiry";
  private readonly EXPIRY_DURATION_MS = 60 * 60 * 1000; 

  private logoutTimer: any;

  constructor(private http: HttpClient, private router: Router) {}

  login(request: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(this.LOGIN_API, request);
  }

  logout(): Observable<any> {
    return this.http.delete(environment.LOGOUT_API, { responseType: "text" });
  }

  setToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token);
    const expirationTime = Date.now() + this.EXPIRY_DURATION_MS;
    localStorage.setItem(this.TOKEN_EXPIRY_KEY, expirationTime.toString());
    this.startAutoLogoutTimer();
  }

  getToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  private isTokenExpired(): boolean {
    const expirationTime = localStorage.getItem(this.TOKEN_EXPIRY_KEY);
    return expirationTime ? Date.now() > +expirationTime : true;
  }

  private startAutoLogoutTimer(): void {
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
    }

    const expirationTime = localStorage.getItem(this.TOKEN_EXPIRY_KEY);
    if (expirationTime) {
      const timeLeft = +expirationTime - Date.now();

      this.logoutTimer = setTimeout(() => {
        this.logout();
      }, timeLeft);
    }
  }

  clearToken(): void {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.TOKEN_EXPIRY_KEY);
    localStorage.removeItem("welcome_toast_shown");
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
    }
  }

  isAuthenticated(): boolean {
    return !!this.getToken() && !this.isTokenExpired();
  }
}
