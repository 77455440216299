<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img
        src="../../../../assets/images/logo-metri.png"
        alt="logo-metri"
        style="width: 150px"
      />
    </a>
  </div>
  <div class="current-date">{{ currentDate }} - {{ currentTime }}</div>
</div>

<div class="header-container">
  <div class="notifications" (click)="toggleNotifications()">
    <nb-icon icon="bell-outline" ></nb-icon>
    <span class="notification-badge" *ngIf="alertsCount > 0">{{
      alertsCount
    }}</span>
  </div>

  <div class="profile-container">
    <div class="profile-image" (click)="toggleProfileMenu()">
      <img
        [src]="user?.picture || '../../../../assets/images/user.png'"
        alt="Profile"
      />
    </div>
    <div class="profile-menu" *ngIf="isProfileMenuOpen">
      <div class="menu-item" (click)="onProfileClick()">
        <nb-icon icon="person-outline"></nb-icon>
        <span>Profile</span>
      </div>
      <div class="menu-item" (click)="onProfileClick()">
        <nb-icon icon="sun-outline"></nb-icon>
        <a href="http://start.agrolora.ma" target="_blank"
          ><span>Agro Start</span></a
        >
      </div>
      <div class="menu-item logout" (click)="logout()">
        <nb-icon icon="log-out-outline"></nb-icon>
        <span>Log out</span>
      </div>
    </div>
  </div>
</div>
