<div class="charts-container">
  <div class="history-section">
    <div>
      <div class="actions-group">
        <h2 class="history-title">Historique des Volumes prélevés</h2>
      </div>
    </div>

    <div class="control-bar">
      <div class="time-selector">
        <span class="label">Choisir l'intervalle de temps :</span>
        <input
          nbInput
          [nbDatepicker]="rangepicker"
          [(ngModel)]="dateRange"
          (ngModelChange)="onDateRangeChange()"
          class="date-input"
          placeholder="Select a date range"
        />
        <nb-rangepicker
          #rangepicker
          [(range)]="dateRange"
          [max]="maxDate"
          (rangeChange)="onDateRangeChange()"
        ></nb-rangepicker>
      </div>

      <div class="view-toggle">
        <button
          [class.active]="activeView === 'variation'"
          (click)="setActiveView('variation')"
          class="toggle-btn left"
        >
          Variation
        </button>
        <button
          [class.active]="activeView === 'cumule'"
          (click)="setActiveView('cumule')"
          class="toggle-btn right"
        >
          Cumulé
        </button>
      </div>

      <div class="excel">
        <button class="export-btn" (click)="exportToExcel()">
          <i class="fas fa-file-excel"></i>
          XLS
        </button>
        <button class="line-chart-btn" (click)="toggleLineChart()">
          <i
            class="fas"
            [ngClass]="{
              'fa-chart-line': !isLineChart,
              'fa-chart-bar': isLineChart
            }"
          ></i>
          {{ isLineChart ? "Bar Chart" : "Line Chart" }}
        </button>
      </div>
    </div>

    <div
      *ngIf="dateRange?.end && !DateRangeValidator.validateDateRange(dateRange)"
      class="error-message"
    >
      La date de fin ne peut pas être supérieure à aujourd'hui
    </div>
  </div>

  <div class="chart-section" *ngIf="Counter_Id">
    <div class="chart-card">
      <div class="chart-header">
        <h3>
          {{ activeView === 'variation' ? 'Volume journalier sur la période sélectionnée' : 'Volume Cumulé sur la période sélectionnée' }}
        </h3>
        <div class="chart-info">
          <span class="unit">
            {{ activeView === 'variation' ? 'm³/jour' : 'Cumulé (m³)' }}
          </span>
        </div>
      </div>

      <div class="chart-container">
        <div *ngIf="loading" class="loading-overlay">
          <span>Chargement des données...</span>
        </div>

        <div
          class="chart-wrapper"
          [class.has-zero-data]="isDailyZeroData"
          [class.has-negative-data]="hasNegativeValues"
        >
          <canvas id="dailyChart"></canvas>

          <div *ngIf="isDailyZeroData" class="zero-data-overlay">
            <i class="fas fa-info-circle"></i>
            <span>
              Aucun flux n'a été détecté pendant la période du
              {{ dateRange.start | date : "dd-MM-yyyy" }} au
              {{ dateRange.end | date : "dd-MM-yyyy" }}
            </span>
          </div>

          <div *ngIf="hasNegativeValues" class="negative-data-overlay">
            <i class="fas fa-exclamation-triangle"></i>
            <span>
              Une erreur de mesure a été détectée pendant cette période.
              Veuillez vérifier le fonctionnement du compteur entre le
              <br />
              <strong>
                ({{ dateRange.start | date : "dd-MM-yyyy" }} -
                {{ dateRange.end | date : "dd-MM-yyyy" }})
              </strong>
            </span>
          </div>

          <div class="max-value-table" *ngIf="!hasNegativeValues">
            <table>
              <tr>
                <th>Débit maximal atteint</th>
              </tr>
              <tr>
                <td>{{ getDailyMaxValue() | number : "1.2-2" }} m³/jour</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="chart-card">
      <div class="chart-header">
        <h3>
          Volumes horaires du jour sélectionné
        </h3>
        <div class="chart-info">
          <span class="unit">
            m³/heure
          </span>
        </div>
      </div>

      <div class="chart-container">
        <div *ngIf="loading" class="loading-overlay">
          <span>Chargement des données...</span>
        </div>

        <div
          class="chart-wrapper"
          [class.has-zero-data]="isHourlyZeroData"
          [class.has-negative-data]="hasNegativeValues"
        >
          <canvas id="hourlyChart"></canvas>

          <div *ngIf="isHourlyZeroData" class="zero-data-overlay">
            <i class="fas fa-info-circle"></i>
            <span>
              Aucun flux n'a été détecté le
              {{ selectedBarDate | date : "dd-MM-yyyy" }}
            </span>
          </div>
          <div *ngIf="hasNegativeValues" class="negative-data-overlay">
            <i class="fas fa-exclamation-triangle"></i>
            <span>
              Une erreur de mesure a été détectée pendant cette période.
              Veuillez vérifier le fonctionnement du compteur entre le
              <br />
              <strong>
                ({{ dateRange.start | date : "dd-MM-yyyy" }} -
                {{ dateRange.end | date : "dd-MM-yyyy" }})
              </strong>
            </span>
          </div>

          <div class="max-value-table" *ngIf="!hasNegativeValues">
            <table>
              <tr>
                <th>Débit maximal atteint</th>
              </tr>
              <tr>
                <td>
                  {{ getHourlyMaxValue() | number : "1.2-2" }}
                  {{ isLitersPerSecond ? "L/s" : "m³/h" }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="tables-section"
    *ngIf="Counter_Id && getDailyMaxValue() >= 0 && getHourlyMaxValue() >= 0"
  >
    <div class="table-card">
      <div class="table-header">
        <h3>
          {{ activeView === 'variation' ? 'Volume journalier sur la période sélectionnée' : 'Volume Cumulé sur la période sélectionnée' }}
        </h3>
        <div class="table-info">
          <span class="unit">
            {{ activeView === 'variation' ? 'm³/jour' : 'Cumulé (m³)' }}
          </span>
        </div>
      </div>
      <div class="table-container">
        <p-table [value]="counterData" [paginator]="true" [rows]="6">
          <ng-template pTemplate="header">
            <tr>
              <th>Date</th>
              <th>{{ activeView === 'variation' ? 'Volume (m³/j)' : 'Volume Cumulé (m³)' }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-i="rowIndex">
            <tr>
              <td>{{ item.date | date : "dd-MM-yyyy" }}</td>
              <td>{{ item.difference / 1000 | number : "1.2-2" }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="table-card">
      <div class="table-header">
        <h3>Volumes horaires du jour sélectionné</h3>
        <div class="table-info">
          <span class="unit">m³/heure</span>
        </div>
      </div>
      <div class="table-container">
        <p-table
          [value]="counterDaily[0]?.slice().reverse()"
          [paginator]="true"
          [rows]="6"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Heure</th>
              <th>Date et Heure</th>
              <th>Débit {{ isLitersPerSecond ? "(L/s)" : "(m³/h)" }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-entry let-i="rowIndex">
            <tr>
              <td>{{ entry.hour }}:00</td>
              <td>{{ formatDateTime(entry.time) }}</td>
              <td [ngClass]="{ highlight: entry.difference >= 0 }">
                {{ formatNumber(getDebitValue(entry)) }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>