import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PiezometreStatusResponse } from '../../types/piezometre-response';
import { Observable } from 'rxjs';
import { DateFormatter } from '../../utils/format-date';
import { VolumeRequest } from '../../types/volume-rq';
import { PiezometreVolumeResponse } from '../../types/piezometre-volume-response';
import { PiezometreData } from '../../types/piezometre-data-hour';
import { LastPiezometreResponse } from '../../types/piezo-last-response';
import { environment } from '../../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PiezometreService {

  private readonly PIEZOMETRE_API = environment.PIEZOMETRE_API;
  private readonly PIEZOMETRE_VOLUME_API = environment.PIEZOMETRE_VOLUME_API;
  private readonly PIEZOMETRE__HOUR_DATA_API = environment.PIEZOMETRE_HOUR_DATA_API;
  private readonly PIEZOMETRE_DATA_API = environment.PIEZOMETRE_DATA_API;
  private readonly LAST_PIEZO_API = environment.LAST_PIEZO;
  private readonly PIEZOMETRE_PER_EXPLOITATION = environment.PIEZO_PER_EXPLOITATION;

  constructor(private http: HttpClient) {}

  PiezometreStatus(): Observable<PiezometreStatusResponse[]> {
    return this.http.get<PiezometreStatusResponse[]>(this.PIEZOMETRE_API);
  }

  PiezoPerExploitation(exp: string): Observable<PiezometreStatusResponse[]> {
    return this.http.get<PiezometreStatusResponse[]>(`${this.PIEZOMETRE_PER_EXPLOITATION}/${exp}`);
  }

  PiezometreDataVolume(request: VolumeRequest): Observable<PiezometreVolumeResponse[]> {
    const startDate = DateFormatter.formatDate(request.startDate);
    const endDate = DateFormatter.formatDate(request.endDate);
    return this.http.get<PiezometreVolumeResponse[]>(`${this.PIEZOMETRE_VOLUME_API}${request.id}/${startDate}/${endDate}/volume`);
  }

  
  PiezometreData(request: VolumeRequest): Observable<PiezometreData[]> {
    const startDate = DateFormatter.formatDate(request.startDate);
    const endDate = DateFormatter.formatDate(request.endDate);
    return this.http.get<PiezometreData[]>(`${this.PIEZOMETRE_DATA_API}${startDate}/${endDate}/${request.id}`);
  }


    
  LastPiezoValue(matricule: string): Observable<LastPiezometreResponse> {
    return this.http.get<LastPiezometreResponse>(`${this.LAST_PIEZO_API}${matricule}`);
  }


  fetchPiezoData(request: VolumeRequest) : Observable<PiezometreVolumeResponse[]>{
    const startDate = DateFormatter.formatDate(request.startDate);
    const endDate = DateFormatter.formatDate(request.endDate);
    return this.http.get<PiezometreVolumeResponse[]>(`${this.PIEZOMETRE__HOUR_DATA_API}${startDate}/${endDate}/${request.id}`);
  }

}
