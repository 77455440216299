import { Component } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: "ngx-alertdetails",
  templateUrl: "./alertdetails.component.html",
  styleUrls: ["./alertdetails.component.scss"],
})
export class AlertdetailsComponent {
  alert: any;

  constructor(private dialogRef: NbDialogRef<AlertdetailsComponent>) {}

  closeModal() {
    this.dialogRef.close();
  }

  getAlertImage(type: string): string {
    if (type === "20") {
      return "../../../assets/images/alert-warning.png";
    } else if (type === "40") {
      return "../../../assets/images/alert-error.png";
    } else {
      return "../../../assets/images/default-alert.png";
    }
  }

  getAlertTitle(type: string): string {
    if (type === "20") {
      return "Performance insuffisante de la pompe";
    } else if (type === "40") {
      return "Débit anormal";
    } else {
      return "Alerte inconnue";
    }
  }

  getAlertType(type: string) {
    if (type === "20") {
      return "Sous-performance de la pompe";
    } else if (type === "40") {
      return "Débit anormal";
    }
    return "";
  }
}
