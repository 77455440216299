import * as XLSX from "xlsx";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ExcelExportService {
  exportCounterToExcel(data: {
    volumeHourly: any[];
    dailyData: any[];
    startDate: Date;
    endDate: Date;
    matricule: string;
  }) {
    const workbook = XLSX.utils.book_new();
    const startDate = this.formatDateForFileName(data.startDate);
    const endDate = this.formatDateForFileName(data.endDate);
    const fileName = `Flow_${data.matricule}_${startDate}_${endDate}.xls`;

    const dailySheet = data.dailyData.map((item) => ({
      Date: new Date(item.date).toLocaleDateString("fr-FR"),
      "Flow Volume (m³/j)": (item.difference / 1000).toFixed(2),
    }));

    const dailyWorksheet = XLSX.utils.json_to_sheet(dailySheet);
    XLSX.utils.book_append_sheet(workbook, dailyWorksheet, "Journaliers");

    if (
      data.volumeHourly &&
      data.volumeHourly.length > 0 &&
      data.volumeHourly[0].data
    ) {
      const volumeHourlySheet = data.volumeHourly[0].data.map((item) => {
        const date = new Date(item.time);
        return {
          Date: date.toLocaleDateString("fr-FR"),
          Heure: `${item.hour}:00`,
          "Flow Volume (m³/h)": (item.difference / 1000).toFixed(2),
        };
      });

      const volumeHourlyWorksheet = XLSX.utils.json_to_sheet(volumeHourlySheet);
      XLSX.utils.book_append_sheet(workbook, volumeHourlyWorksheet, "Horaire");
    }

    XLSX.writeFile(workbook, fileName);
  }

  exportBasinToExcel(data: {
    basinData: any[];
    volumeData: any[];
    startDate: Date;
    endDate: Date;
    basinName: string;
  }) {
    const workbook = XLSX.utils.book_new();
    const startDate = this.formatDateForFileName(data.startDate);
    const endDate = this.formatDateForFileName(data.endDate);
    const fileName = `Basin_${data.basinName}_${startDate}_${endDate}.xls`;

    if (data.volumeData && data.volumeData.length > 0) {
      const volumeSheet = data.volumeData.map((item) => {
        const date = new Date(item.date);
        return {
          Date: date.toLocaleDateString("fr-FR"),
          "Volume (m³)": (item.volume / 1000).toFixed(2),
          "Débit (m³/h)": (item.flow / 1000).toFixed(2),
          "Date et Heure": date.toLocaleString("fr-FR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
      });

      const volumeWorksheet = XLSX.utils.json_to_sheet(volumeSheet);
      XLSX.utils.book_append_sheet(workbook, volumeWorksheet, "Volume");
    }

    // Basin Data sheet
    if (data.basinData && data.basinData.length > 0) {
      const basinSheet = data.basinData.map((item) => {
        const date = new Date(item.date);
        return {
          Date: date.toLocaleDateString("fr-FR"),
          "Niveau (m)": item.level.toFixed(2),
          "Volume (m³)": (item.volume / 1000).toFixed(2),
          "Date et Heure": date.toLocaleString("fr-FR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
      });

      const basinWorksheet = XLSX.utils.json_to_sheet(basinSheet);
      XLSX.utils.book_append_sheet(workbook, basinWorksheet, "Niveau");
    }

    XLSX.writeFile(workbook, fileName);
  }

 
 
  exportPiezoToExcel(data: {
    piezoData: any[];
    counterData?: any[];
    startDate: Date;
    endDate: Date;
    matricule: string;
  }) {
    const workbook = XLSX.utils.book_new();
    const startDate = this.formatDateForFileName(data.startDate);
    const endDate = this.formatDateForFileName(data.endDate);
    const fileName = `Piezometre_${data.matricule}_${startDate}_${endDate}.xls`;

    
    if (data.piezoData && data.piezoData.length > 0) {
      const piezoWorksheet = XLSX.utils.json_to_sheet(data.piezoData);
      
    
      piezoWorksheet['!cols'] = [
        { wch: 20 },
        { wch: 15 } 
      ];
      
      XLSX.utils.book_append_sheet(workbook, piezoWorksheet, "Piezometre");

      const piezoValues = data.piezoData.map(row => parseFloat(row["Profondeur (m)"]));
      const piezoStats = [
        ["Statistiques Piézomètre"],
        [""],
        ["Matricule", data.matricule],
        ["Période", `${this.formatDate(data.startDate)} - ${this.formatDate(data.endDate)}`],
        [""],
        ["Analyse des profondeurs"],
        ["Minimum (m)", Math.min(...piezoValues).toFixed(2)],
        ["Maximum (m)", Math.max(...piezoValues).toFixed(2)],
        ["Moyenne (m)", (piezoValues.reduce((a, b) => a + b, 0) / piezoValues.length).toFixed(2)]
      ];
      
      const piezoStatsWorksheet = XLSX.utils.aoa_to_sheet(piezoStats);
      
      piezoStatsWorksheet['!cols'] = [
        { wch: 25 }, 
        { wch: 20 }  
      ];
      
      piezoStatsWorksheet['A1'] = { 
        v: "Statistiques Piézomètre",
        s: { font: { bold: true, sz: 14 } }
      };
      
      XLSX.utils.book_append_sheet(workbook, piezoStatsWorksheet, "Piezometre Statistiques");
    }

    if (data.counterData && data.counterData.length > 0) {
      const counterWorksheet = XLSX.utils.json_to_sheet(data.counterData);
      
      counterWorksheet['!cols'] = [
        { wch: 20 },
        { wch: 15 }  
      ];
      
      XLSX.utils.book_append_sheet(workbook, counterWorksheet, "Compteur");

      const counterValues = data.counterData.map(row => parseFloat(row["Volume (m³)"]));
      const counterStats = [
        ["Statistiques Compteur"],
        [""],
        ["Matricule", data.matricule],
        ["Période", `${this.formatDate(data.startDate)} - ${this.formatDate(data.endDate)}`],
        [""],
        ["Analyse des volumes"],
        ["Minimum (m³/h)", Math.min(...counterValues).toFixed(2)],
        ["Maximum (m³/h)", Math.max(...counterValues).toFixed(2)],
        ["Moyenne (m³/h)", (counterValues.reduce((a, b) => a + b, 0) / counterValues.length).toFixed(2)]
      ];
      
      const counterStatsWorksheet = XLSX.utils.aoa_to_sheet(counterStats);
      
    
      counterStatsWorksheet['!cols'] = [
        { wch: 25 }, 
        { wch: 20 }  
      ];
    
      counterStatsWorksheet['A1'] = {
        v: "Statistiques Compteur",
        s: { font: { bold: true, sz: 14 } }
      };
      
      XLSX.utils.book_append_sheet(workbook, counterStatsWorksheet, "Compteur Statistiques");
    }

    XLSX.writeFile(workbook, fileName);
  }


  private formatDateForFileName(date: Date): string {
    if (!date) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}${month}${year}`;
  }

  private formatDate(date: Date): string {
    if (!date) return "";
    return date.toLocaleDateString("fr-FR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }


  

 
}
