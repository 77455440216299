import { Component, OnDestroy, OnInit, HostListener } from "@angular/core";
import { NbMenuService, NbSidebarService } from "@nebular/theme";
import { Router } from "@angular/router";
import { AuthService } from "../../../@core/services/auth/auth.service";
import { Subscription } from "rxjs";
import { AlarmsService } from "../../../@core/services/alarms.service";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: any;
  isProfileMenuOpen = false;
  currentDate: string;
  currentTime: string;
  private timerInterval: any;

  alertsCount: number = 0;
  private subscription: Subscription;
  constructor(
    private router: Router,
    private menuService: NbMenuService,
    private sidebarService: NbSidebarService,
    private authService: AuthService,
    private alertService: AlarmsService
  ) {}

  ngOnInit() {
    this.user = {
      name: "User Name",
      picture: "../../../../assets/images/user.png",
    };

    this.subscription = this.alertService.alertsCount$.subscribe(
      (count) => (this.alertsCount = count)
    );

    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "Europe/Paris",
    };
    this.currentDate = new Date().toLocaleDateString("fr-MA", options);

    this.startTimer();
  }

  ngOnDestroy(): void {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  toggleNotifications() {
    console.log("route called here !!");
    this.router.navigate(["/pages/alertes "]);
  }

  startTimer(): void {
    this.timerInterval = setInterval(() => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      this.currentTime = `${hours}:${minutes}:${seconds} GMT + 1`;
    }, 1000);
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    const profileContainer = document.querySelector(".profile-container");
    if (profileContainer && !profileContainer.contains(event.target as Node)) {
      this.isProfileMenuOpen = false;
    }
  }

  toggleProfileMenu() {
    this.isProfileMenuOpen = !this.isProfileMenuOpen;
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onProfileClick() {
    this.isProfileMenuOpen = false;
    this.router.navigate(["/pages/profile"]);
  }

  logout() {
    this.isProfileMenuOpen = false;
    this.authService.logout().subscribe({
      next: (response) => {
        this.authService.clearToken();
        this.router.navigate(["/auth/login"]);
      },
      error: (error) => {
        console.error("API Error:", error);
      },
    });
  }
}
