<div class="dashboard-fixed-bar">
  <div class="icon-group">
    <div
      *ngFor="let item of navigationItems"
      class="icon-item"
      [class.active]="selectedComponent === item.id"
      [class.disabled]="item.disabled"
      (click)="!item.disabled && selectComponent(item.id)"
    >
      <img [src]="'../../../assets/images/' + item.icon" [alt]="item.label" />
      <span class="tooltip" [class.disabled]="item.disabled">{{
        item.label
      }}</span>
    </div>
  </div>

  <div class="select-group">
    <ng-container *ngIf="!isLoading">
      <ng-container *ngIf="exploitationOptions.length > 0">
        <label class="select-title">Sélectionner une exploitation :</label>
        <select
          class="select-control"
          (change)="onExploitationSelect($event)"
          [(ngModel)]="selectedExploitation"
          name="exploitation"
        >
          <option
            *ngFor="let exploitation of exploitationOptions"
            [value]="exploitation"
          >
            {{ exploitation }}
          </option>
        </select>
      </ng-container>

      <ng-container [ngSwitch]="selectedComponent">
        <ng-container *ngSwitchCase="'compteur'">
          <ng-container *ngIf="counters.length > 0">
            <label class="select-title">Sélectionner un compteur :</label>
            <select
              class="select-control"
              name="counter"
              (change)="onItemSelect($event)"
              [(ngModel)]="selectedCounterId"
              [disabled]="navigationItems[0].disabled"
            >
              <!-- <option value="" disabled>Sélectionner un compteur</option> -->
              <option
                *ngFor="let counter of counters"
                [value]="counter.id_compteur"
              >
                {{ counter.matricule }}
              </option>
            </select>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'basin'">
          <ng-container *ngIf="basins.length > 0">
            <label class="select-title">Sélectionner un bassin :</label>
            <select
              class="select-control"
              name="basin"
              (change)="onItemSelect($event)"
              [(ngModel)]="selectedBasinId"
              [disabled]="navigationItems[2].disabled"
            >
              <!-- <option value="" disabled>Sélectionner un bassin</option> -->
              <option *ngFor="let basin of basins" [value]="basin.id_capteur" [hidden]="basin.nom_groupe !== selectedExploitation">
                {{ basin.libelle }}
              </option>
            </select>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'piezometre'">
          <ng-container *ngIf="piezos.length > 0">
            <label class="select-title">Sélectionner un piézomètre :</label>
            <select
              class="select-control"
              (change)="onItemSelect($event)"
              [value]="selectedPiezoId || ''"
              [disabled]="navigationItems[1].disabled"
            >
              <!-- <option value="" disabled>Sélectionner un piézomètre</option> -->
              <option
                *ngFor="let piezo of piezos"
                [value]="piezo.id_piezometre"
              >
                {{ piezo.libelle }}
              </option>
            </select>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="component-container" *ngIf="!isLoading">
  <ng-container [ngSwitch]="selectedComponent">
    <ngx-compteur
      *ngSwitchCase="'compteur'"
      [selectedCounter]="selectedCounterId"
      [selectedExploitation]="selectedExploitation"
      [exploitationOptions]="exploitationOptions"
      (counterSelected)="updateSelectedCounter($event)"
    ></ngx-compteur>

    <ngx-piezometre
      *ngSwitchCase="'piezometre'"
      [selectedPiezometre]="selectedPiezoId"
    ></ngx-piezometre>

    <ngx-basin
      *ngSwitchCase="'basin'"
      [selectedBasinMatricule]="selectedBasinMatricule"
      [selectedExploitation]="selectedExploitation"
      [selectedBasin]="selectedBasinId"
      (basinSelected)="updateSelectedBasin($event)"
    ></ngx-basin>
  </ng-container>
</div>
