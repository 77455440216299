import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import {NbActionsModule,NbButtonModule,NbCardModule,NbTabsetModule,NbUserModule,NbRadioModule,NbSelectModule,NbListModule,NbIconModule,NbDatepickerModule} from "@nebular/theme";
import { NbSpinnerModule } from '@nebular/theme';
import { NgxEchartsModule } from "ngx-echarts";
import { CarouselModule } from "primeng/carousel";
import { ButtonModule } from "primeng/button";
import { CompteurComponent } from "./compteur/compteur.component";
import { TableModule } from "primeng/table";
import { SharedModule } from "../../@shared/shared.module";
import { DashboardComponent } from "./dashboard.component";
import { FormsModule } from "@angular/forms";
import { PiezometreComponent } from "./piezometre/piezometre.component";
import { SelectButtonModule } from "primeng/selectbutton";
import { NbFormFieldModule, NbInputModule } from "@nebular/theme";
import { ModalsModule } from "../_modals/modals.module";
import { WaterHistoryComponent } from "./piezometre/water-history/water-history.component";
import { CounterHistoryComponent } from "./compteur/counter-history/counter-history.component";
import { SkeletonModule } from 'primeng/skeleton';
import { PaginatorModule } from 'primeng/paginator';
import { BasinComponent } from "./basin/basin.component";
import { WaterPerformanceComponent } from "./basin/water-performance/water-performance.component";

@NgModule({
  imports: [
    PaginatorModule,
    FormsModule,
    SharedModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbSpinnerModule,
    NbTabsetModule,
    NbActionsModule,
    NgxEchartsModule,
    NbRadioModule,
    NbSelectModule,
    NbListModule,
    NbIconModule,
    NbButtonModule,
    TableModule,
    SelectButtonModule,
    CarouselModule,
    ButtonModule,
    NbFormFieldModule,
    NbInputModule,
    ModalsModule,
    NbDatepickerModule,
    SkeletonModule,
    
  ],
  declarations: [
    DashboardComponent,
    BasinComponent,
    WaterPerformanceComponent,
    PiezometreComponent,
    CompteurComponent,
    WaterHistoryComponent,
    CounterHistoryComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  exports: [
    CarouselModule,
    ButtonModule,
    DashboardComponent,
    PiezometreComponent,
    CompteurComponent,
    NbFormFieldModule,
    NbInputModule,
    WaterHistoryComponent,
  ],
})
export class DashboardModule {}
