<div class="dashboard-layout-p">
  <div
    class="cards-section"
    [nbSpinner]="isCardsLoading"
    nbSpinnerStatus="primary"
    nbSpinnerSize="large"
    nbSpinnerMessage="Chargement des données..."
  >
    <p-carousel
      *ngIf="filteredPiezometres.length > 4"
      [value]="filteredPiezometres"
      [numVisible]="4"
      [numScroll]="4"
      [circular]="true"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template pTemplate="item" let-piezometre>
        <div
          class="stat-card"
          [class.selected]="selectedPiezometreData?.id_piezometre === piezometre.id_piezometre"
          (click)="onCardClick(piezometre)"
        >
          <div class="titlea">{{ piezometre.libelle }}</div>
          <hr class="title-divider" />
          <div class="value-info">
            Matricule: {{ piezometre.matricule }}
          </div>
          <div class="value-info">
            Longueur câble: {{ piezometre.longueur_cable }} m
          </div>
          <div class="value-info">
            Profondeur d'installation: {{ piezometre.profondeur_installation }} m
          </div>
          <div class="communication-info">
            Dernière communication: {{ formatDate(piezometre.lastCommunicationDate) }}
          </div>
        </div>
      </ng-template>
    </p-carousel>

    <div class="cards-wrapper" *ngIf="filteredPiezometres.length <= 4">
      <div
        class="stat-card"
        *ngFor="let piezometre of filteredPiezometres; trackBy: trackByPiezo"
        [class.selected]="selectedPiezometreData?.id_piezometre === piezometre.id_piezometre"
        (click)="onCardClick(piezometre)"
      >
        <div class="titlea">{{ piezometre.libelle }}</div>
        <hr class="title-divider" />
        <div class="value-info">
          Matricule: {{ piezometre.matricule }}
        </div>
        <div class="value-info">
          Longueur câble: {{ piezometre.longueur_cable }} m
        </div>
        <div class="value-info">
          Profondeur d'installation: {{ piezometre.profondeur_installation }} m
        </div>
        <!-- <div class="communication-info">
          Dernière communication: {{ formatDate(piezometre.lastCommunicationDate) }}
        </div> -->
      </div>
    </div>
  </div>

  <div class="content-section">
    <div class="piezometre-content-display">
      <div class="piezometer-container">

        <div class="piezometer-title">
          {{ selectedPiezometreData?.libelle || 'EM500-SWL_001' }}
          <div class="piezometer-subtitle">
            {{ selectedPiezometreData?.matricule || 'N/A' }}
          </div>
        </div>
    

        <div class="piezometer-installation">

          <div class="brick-housing">
            <div class="cap-left"></div>
            <div class="tube-housing"></div>
            <div class="cap-right"></div>
            <div class="brick-pattern-left"></div>
            <div class="brick-pattern-right"></div>
          </div>
    
          <div class="ground-layer">
            <div class="layer-border-top"></div>
            <div class="layer-border-bottom"></div>
            <div class="dots"></div>
          </div>
          
          <div class="soil-layer">
            <div class="layer-section">
              <div class="layer-border"></div>
            </div>
            <div class="layer-section">
              <div class="layer-border"></div>
            </div>
            <div class="layer-section">
              <div class="layer-border"></div>
            </div>
            <div class="layer-section">
              <div class="layer-border"></div>
            </div>
            <div class="dots"></div>
          </div>
          
          <div class="aquifer-layer">
            <div class="dots"></div>
          </div>
    
          
          <div class="tube">
            <div class="measurement-marks">
              <div class="mark" *ngFor="let mark of depthMarks"></div>
            </div>
            <div class="water-column" [style.height]="waterLevel">
              <div class="current-level">{{ PiezoLast?.last || '175' }}m</div>
            </div>
            <div class="tube-tip"></div>
          </div>
        </div>
    
        
        <div class="info-content">
          <div class="info-row">
            <span class="label">Piézomètre:</span>
            <span class="value">{{ selectedPiezometreData?.libelle || 'EM500-SWL_001' }}</span>
          </div>
          <div class="info-row">
            <span class="label">Profondeur de l'eau:</span>
            <span class="value">{{ PiezoLast?.last || '175' }} m</span>
          </div>
          <div class="info-row">
            <span class="label">Dernière mesure:</span>
            <span class="value">{{ PiezoLast?.time | date:'dd-MM-yyyy HH:mm' || 'N/A' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="map-container" id="map1"></div>
  </div>


  <ngx-water-history
    [selectedPiezoId]="selectedPiezoId"
    [selectedMatricule]="selectedPiezoMatricule"
    [selectedPiezometreData]="selectedPiezometreData"
  >
  </ngx-water-history>
</div>