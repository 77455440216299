import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BilanRequest } from "../../types/bilan-request";
import { Observable } from "rxjs";
import { BilanResponse } from "../../types/bilan-response";
import { WaterSourceResponse } from "../../types/water-source-response";
import { StockageVolumeResponse } from "../../types/stockage-volume-response";
import { StockageNiveauResponse } from "../../types/stockage-niveau-response";
import { StockagePourcentageResponse } from "../../types/stockage-pourcentage-response";
import { WaterSourceMultipleResponse } from "../../types/water-source-multiple-response";
import { environment } from "../../../../environments/environment.prod";
import { RootObject } from "../../types/bilan-unte-response";

@Injectable({
  providedIn: "root",
})
export class BilanService {
  private readonly BILAN_DAY_API = environment.BILAN_DAY_API;
  private readonly BILAN_MONTH_API = environment.BILAN_MONTH_PI;
  private readonly STOCKAGE_API = environment.BILAN_STOCKAGE_API;
  private readonly REFOULEMENT_API = environment.REFOULEMENT_API;
  private readonly BILAN_EXP = environment.BILAN_EXP;
  public readonly WATER_SOURCE_MULTIPLE_API =
    environment.WATER_SOURCE_MULTIPLE_API;
  public readonly REFOULEMENT_ENSEMBLE_API =
    environment.REFOULEMENT_ENSEMBLE_API;

  private formatDate(date: Date): string {
    return date.toISOString().split("T")[0];
  }

  constructor(private http: HttpClient) {}

  getBilanDay(request: BilanRequest): Observable<WaterSourceResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterSourceResponse[]>(
      `${this.BILAN_DAY_API}/${startDate}/${endDate}`
    );
  }

  getBilanMonth(request: BilanRequest): Observable<WaterSourceResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterSourceResponse[]>(
      `${this.BILAN_MONTH_API}/${startDate}/${endDate}`
    );
  }

  getRefoulementDay(request: BilanRequest): Observable<WaterSourceResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterSourceResponse[]>(
      `${this.REFOULEMENT_API}/day/${startDate}/${endDate}`
    );
  }

  getRefoulementMonth(
    request: BilanRequest
  ): Observable<WaterSourceResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterSourceResponse[]>(
      `${this.REFOULEMENT_API}/month/${startDate}/${endDate}`
    );
  }

  StockagePerVolume(
    request: BilanRequest
  ): Observable<StockageVolumeResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<StockageVolumeResponse[]>(
      `${this.STOCKAGE_API}/volume/${startDate}/${endDate}`
    );
  }

  StockagePerNiveau(
    request: BilanRequest
  ): Observable<StockageNiveauResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<StockageNiveauResponse[]>(
      `${this.STOCKAGE_API}/niveau/${startDate}/${endDate}`
    );
  }

  StockagePerPourcentage(
    request: BilanRequest
  ): Observable<StockagePourcentageResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<StockagePourcentageResponse[]>(
      `${this.STOCKAGE_API}/pourcentage/${startDate}/${endDate}`
    );
  }

  WaterSourceMultiple(
    request: BilanRequest
  ): Observable<WaterSourceMultipleResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterSourceMultipleResponse[]>(
      `${this.WATER_SOURCE_MULTIPLE_API}day/${request.exp}/${startDate}/${endDate}`
    );
  }

  WaterSourceMultipleMonth(
    request: BilanRequest
  ): Observable<WaterSourceMultipleResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterSourceMultipleResponse[]>(
      `${this.WATER_SOURCE_MULTIPLE_API}mois/${request.exp}/${startDate}/${endDate}`
    );
  }

  RefoulementEnsemble(
    request: BilanRequest
  ): Observable<WaterSourceMultipleResponse[]> {
    return this.http.get<WaterSourceMultipleResponse[]>(
      `${this.REFOULEMENT_ENSEMBLE_API}day/${request.exp}/${request.startDate}/${request.endDate}`
    );
  }

  RefoulementEnsembleMonth(
    request: BilanRequest
  ): Observable<WaterSourceMultipleResponse[]> {
    return this.http.get<WaterSourceMultipleResponse[]>(
      `${this.REFOULEMENT_ENSEMBLE_API}month/${request.exp}/${request.startDate}/${request.endDate}`
    );
  }

  getBilanUnite(request: BilanRequest, period: "day" | "month"): Observable<RootObject> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<RootObject>(
      `${this.BILAN_EXP}/${period}/${request.exp}/${startDate}/${endDate}`
    );
  }
  
}
