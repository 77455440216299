<div class="dashboard-layout-c">
  <div
    class="cards-section"
    [nbSpinner]="isCardsLoading"
    nbSpinnerStatus="primary"
    nbSpinnerSize="large"
    nbSpinnerMessage="Chargement des données..."
  >
    <p-carousel
      *ngIf="filteredFeatures.length > 4"
      [value]="filteredFeatures"
      [numVisible]="4"
      [numScroll]="4"
      [circular]="true"
      [responsiveOptions]="responsiveOptions"
      [touchUI]="true"
    >
      <ng-template pTemplate="item" let-feature>
        <div
          class="stat-card"
          [class.selected]="feature.properties.matricule === selectedMatricule"
          (click)="onCardClick(feature)"
        >
          <div class="titlea">{{ feature.properties.matricule }}</div>
          <hr class="title-divider" />
          <div class="value-info">
            Dernière Valeur:
            {{ feature.derniere_val?.[0]?.cumul / 1000 | number: '1.2-2':'en-US' }}
            m³
          </div>
          <div class="max-info" style="color: #ff4444">
            MAX: {{ feature.max?.last / 1000 | number : "1.0-2" }} m³/h
          </div>
          <div class="communication-info">
            Dernière communication:
            {{ formatDate(feature.derniere_val?.[0]?.date) }} À
            {{ feature.derniere_val?.[0]?.hour }}:00
          </div>
        </div>
      </ng-template>
    </p-carousel>

    <div class="cards-wrapper" *ngIf="filteredFeatures.length <= 4">
      <div
        class="stat-card"
        *ngFor="let feature of filteredFeatures; trackBy: trackByCounter"
        [class.selected]="feature.properties.matricule === selectedMatricule"
        (click)="onCardClick(feature)"
      >
        <div class="titlea">{{ feature.properties.matricule }}</div>
        <hr class="title-divider" />
        <div class="value-info">
          Dernière Valeur:
          {{ feature.derniere_val?.[0]?.cumul / 1000 | number: '1.2-2':'en-US' }}
          m³
        </div>
        <div class="max-info" style="color: #ff4444">
          MAX: {{ feature.max?.last / 1000 | number : "1.0-2" }} m³/h
        </div>
        <div class="communication-info">
          Dernière communication:
          {{ formatDate(feature.derniere_val?.[0]?.date) }} À
          {{ feature.derniere_val?.[0]?.hour }}:00
        </div>
      </div>
    </div>
  </div>

  <div class="content-section-c">
    <div class="meter-display">
      <div class="header-section">
        <span (click)="openModalInfos()" style="cursor: pointer">
          <img
            src="../../../../assets/images/information-button.png"
            alt="information button"
          />
        </span>
        <h6 class="text_title">Puit: {{ selectedcmptr_m }}</h6>
      </div>

      <div class="meter-container">
        <div class="carre2"></div>
        <div class="carre3"></div>
        <div class="carre4"></div>
        <div class="carre1"></div>

        <div class="circle">
          <div class="circle__inner">
            <div class="circle__wrapper">
              <div class="circle__content">
                <div class="logo_circle">
                  <img [src]="logoPath" alt="Agro Metri" />
                </div>

                <input
                  class="form-control form_circle"
                  [value]="meterValue"
                  readonly
                />

                <div class="bottom_circle"></div>

                <div *ngIf="Lasttime" class="desc_circle">
                  <b style="font-size: 11px">Dernière communication</b>
                  <br />
                  {{ lastCommunication | date : "shortDate" }}
                  <br />
                  à {{ LastHour }}:00
                </div>

                <div class="gauge_circle">
                  <img class="img_c" [src]="gaugePath" alt="Gauge" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <table class="modern-table">
          <tr>
            <th>Débit maximal atteint à la dernière utilisation</th>
          </tr>
          <tr>
            <td>{{ max_table | number : "1.0-2" }} m³/h</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="map-container" id="map1"></div>
  </div>
</div>

<ngx-counter-history
  [Counter_Id]="selectedCounterId"
  [compteurMatricule]="selectedcmptr_m"
  [lastCommunicationDate]="lastCommunicationDate"
>
</ngx-counter-history>

<ngx-counter-modal
  [isOpen]="showModal"
  [title]="title"
  [id]="selectedCounterId"
  [image]="selectedImage"
  (closeModal)="closeModalInfos()"
>
  <ng-container *ngIf="selectedImage; else noImage">
    <img [src]="selectedImage" alt="Counter Image" />
  </ng-container>
  <ng-template #noImage>
    <p>No image available</p>
  </ng-template>
</ngx-counter-modal>
