import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { CounterPerHourResponse } from "../../types/counter-hour-respone";
import { CounterVolume } from "../../types/volume-request";
import { CounterPerMonthResponse } from "../../types/counter-month-response";
import { CounterResponse } from "../../types/counter-response";
import { DateFormatter } from "../../utils/format-date";
import { DailyVolumeResponse } from "../../types/daily-volume-response";
import { BasinDataResponse } from "../../types/basin-data-response";
import { PiezometreData } from "../../types";
import { environment } from "../../../../environments/environment.prod";
import { BasinVolume } from "../../types/basin-volume";

@Injectable({
  providedIn: "root",
})
export class DataService {
  private readonly VOLUEME_PER_DAY = environment.COUNTER_PER_DAY;
  private readonly PIEZO_PER_DAY = environment.PIEZOMETRE_DATA_API;
  private readonly VOLUEME_PER_HOUR = environment.COUNTER_PER_HOUR;
  private readonly VOLUEME_PER_MONTH = environment.COUNTER_PER_MONTH;
  private readonly COUNTERS_API = environment.COUNTERS_API;
  private readonly BILAN_API = environment.BILAN_STOCKAGE_API;

  constructor(private http: HttpClient) {}

  getAllCounters(): Observable<CounterResponse[]> {
    return this.http.get<CounterResponse[]>(`${this.COUNTERS_API}`);
  }

  VolumePerDay(request: CounterVolume): Observable<DailyVolumeResponse[]> {
    const startDate = DateFormatter.formatDate(request.startDate);
    const endDate = DateFormatter.formatDate(request.endDate);
    const url = `${this.VOLUEME_PER_DAY}${request.id}/${startDate}/${endDate}/${
      request.cumul ?? 0
    }`;
    return this.http.get<DailyVolumeResponse[]>(url);
  }

  VolumePerMonth(
    request: CounterVolume
  ): Observable<CounterPerMonthResponse[]> {
    const startDate = DateFormatter.formatDate(request.startDate);
    const endDate = DateFormatter.formatDate(request.endDate);
    return this.http.get<CounterPerMonthResponse[]>(
      `${this.VOLUEME_PER_MONTH}/${request.id}/${startDate}/${endDate}/${request.cumul}`
    );
  }

  VolumePerHour(request: CounterVolume): Observable<CounterPerHourResponse[]> {
    const startDate = DateFormatter.formatDate(request.startDate);
    const endDate = DateFormatter.formatDate(request.endDate);
    return this.http.get<CounterPerHourResponse[]>(
      `${this.VOLUEME_PER_HOUR}/${startDate}/${endDate}/${request.cumul}/${request.id}`
    );
  }

  BasinPerDay(request: CounterVolume): Observable<BasinDataResponse[]> {
    const startDate = DateFormatter.formatDate(request.startDate);
    const endDate = DateFormatter.formatDate(request.endDate);
    return this.http.get<BasinDataResponse[]>(
      `${this.BILAN_API}/volume/${startDate}/${endDate}`
    );
  }

  PiezoPerHour(request: CounterVolume): Observable<PiezometreData[]> {
    const startDate = DateFormatter.formatDate(request.startDate).toString();
    const endDate = DateFormatter.formatDate(request.endDate).toString();
    return this.http.get<PiezometreData[]>(
      `${this.PIEZO_PER_DAY}${startDate}/${endDate}/${request.id}/`
    );
  }

  exportToExcel(data: any[], fileName: string): void {
    if (!data || data.length === 0) {
      console.warn("No data available for export.");
      return;
    }

    const formattedData = fileName.includes("basin_")
      ? this.formatBasinDataForExport(data)
      : data;

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedData);

    const columnWidths = [
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    worksheet["!cols"] = columnWidths;

    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(dataBlob, `${fileName}.xlsx`);
  }

  private formatBasinDataForExport(data: BasinDataResponse[]): any[] {
    const formattedData: any[] = [];

    data.forEach((basinData) => {
      const rechargeArray = basinData.recharge || [];
      const destockageArray = basinData.destockage || [];

      const maxLength = Math.max(rechargeArray.length, destockageArray.length);

      for (let i = 0; i < maxLength; i++) {
        const rechargeEntry: BasinVolume | undefined = rechargeArray[i];
        const destockageEntry: BasinVolume | undefined = destockageArray[i];

        if (
          rechargeEntry &&
          destockageEntry &&
          rechargeEntry.time === destockageEntry.time
        ) {
          formattedData.push({
            "Index de la mesure": formattedData.length + 1,
            Exploitation: basinData.groupe,
            Bassin: basinData.matricule,
            Date: new Date(rechargeEntry.time).toLocaleString("fr-FR"),
            "Recharge (m³)": rechargeEntry.diff_jr_volume.toFixed(2),
            "Déstockage (m³)": destockageEntry.diff_jr_volume.toFixed(2),
            "Balance volumétrique": (
              rechargeEntry.diff_jr_volume + destockageEntry.diff_jr_volume
            ).toFixed(2),
          });
        }
      }
    });

    return formattedData.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  fetchDataForExport(
    component: string,
    duration: string,
    request: CounterVolume
  ): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let observable: Observable<any[]>;

      switch (component) {
        case "compteur":
          if (duration === "days") {
            observable = this.VolumePerDay(request);
          } else if (duration === "months") {
            observable = this.VolumePerMonth(request);
          } else {
            observable = this.VolumePerHour(request);
          }
          break;

        case "basin":
          observable = this.BasinPerDay(request);
          break;

        case "piezometre":
          observable = this.PiezoPerHour(request);
          break;

        default:
          return reject("Invalid component type");
      }

      observable.subscribe({
        next: (data) => resolve(data),
        error: (err) => {
          console.error("Error fetching data for export:", err);
          reject(err);
        },
      });
    });
  }
}
