import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { forkJoin, Observable } from "rxjs";
import { BasinResponse } from "../../types/basin-response";
import { WaterVolumeResponse } from "../../types/water-volume-response";
import { VolumeRequest } from "../../types/volume-rq";
import { WaterPourcentageResponse } from "../../types/water-pourcentage";
import { NiveauBasinResponse } from "../../types/niveau-response";
import { tap } from "rxjs/operators";
import { environment } from "../../../../environments/environment.prod";

@Injectable({
  providedIn: "root",
})
export class BasinService {
  private readonly BASIN_API = environment.BASIN_API;
  private readonly PERFORMANCE_WATER_VOLUME = environment.PERFORMANCE_WATER_API;
  private readonly PERFORMANCE_PER_DAY_WATER_VOLUME =
    environment.PERFORMANCE_PER_DAY_WATER_API;
  private readonly LAST_WATER_POURCENTAGE = environment.LAST_WATER_API;
  private readonly VOLUME_HOURLY = environment.COUNTER_PER_HOUR;
  private readonly BASIN_PER_EXPLOITATION = environment.BASIN_PER_EXPLOITATION;

  constructor(private http: HttpClient) {}

  private formatDate(date: Date): string {
    return date.toISOString().split("T")[0];
  }

  BasinStatus(): Observable<BasinResponse[]> {
    return this.http.get<BasinResponse[]>(this.BASIN_API);
  }

  BasinPerExploitatoin(exp: string): Observable<BasinResponse[]> {
    return this.http.get<BasinResponse[]>(`${this.BASIN_PER_EXPLOITATION}/${exp}`);
  }

  PerformanceVolumeWater(
    request: VolumeRequest
  ): Observable<WaterVolumeResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterVolumeResponse[]>(
      `${this.PERFORMANCE_WATER_VOLUME}/${request.id}/${startDate}/${endDate}/volume`
    );
  }

  PerformanceVolumeWaterPerDay(
    request: VolumeRequest
  ): Observable<WaterVolumeResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterVolumeResponse[]>(
      `${this.PERFORMANCE_PER_DAY_WATER_VOLUME}/${request.id}/${startDate}/${endDate}/volume`
    );
  }

  PerformancePourcentageBasinPerDay(
    request: VolumeRequest
  ): Observable<WaterPourcentageResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterPourcentageResponse[]>(
      `${this.PERFORMANCE_PER_DAY_WATER_VOLUME}/${request.id}/${startDate}/${endDate}/pourcentage`
    );
  }

  PerformanceNiveauBasinPerDay(
    request: VolumeRequest
  ): Observable<NiveauBasinResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<NiveauBasinResponse[]>(
      `${this.PERFORMANCE_PER_DAY_WATER_VOLUME}/${request.id}/${startDate}/${endDate}/niveau`
    );
  }

  private getCurrentDateRequest(): VolumeRequest {
    const today = new Date();
    return {
      id: null,
      startDate: today,
      endDate: today,
    };
  }

  fetchVolumePerHours(
    request: VolumeRequest
  ): Observable<WaterVolumeResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterVolumeResponse[]>(
      `${this.PERFORMANCE_WATER_VOLUME}/${request.id}/${startDate}/${endDate}/volume`
    );
  }

  fetchNiveauPerHours(
    request: VolumeRequest
  ): Observable<NiveauBasinResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<NiveauBasinResponse[]>(
      `${this.PERFORMANCE_WATER_VOLUME}/${request.id}/${startDate}/${endDate}/niveau`
    );
  }

  fetchPourcentagePerHours(
    request: VolumeRequest
  ): Observable<WaterPourcentageResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterPourcentageResponse[]>(
      `${this.PERFORMANCE_WATER_VOLUME}/${request.id}/${startDate}/${endDate}/pourcentage`
    );
  }

  lastPourcentageValue(id: number): Observable<WaterPourcentageResponse[]> {
    const request = this.getCurrentDateRequest();
    request.id = id;
    return this.fetchPourcentagePerHours(request);
  }

  lastVolumeValue(id: number): Observable<WaterVolumeResponse[]> {
    const request = this.getCurrentDateRequest();
    request.id = id;

    return this.fetchVolumePerHours(request);
  }

  lastNiveauValue(id: number): Observable<NiveauBasinResponse[]> {
    const request = this.getCurrentDateRequest();
    request.id = id;
    return this.fetchNiveauPerHours(request);
  }

  getBasinLatestData(id: number): Observable<any> {
    const today = new Date();
    const request = {
      id: id,
      startDate: today,
      endDate: today,
    };
    return forkJoin({
      percentage: this.fetchPourcentagePerHours(request),
      volume: this.fetchVolumePerHours(request),
      niveau: this.fetchNiveauPerHours(request),
    });
  }
}
