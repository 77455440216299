import {Component,Input,Output,EventEmitter,OnInit,OnChanges,SimpleChanges} from "@angular/core";
import { CounterService } from "../../../@core/services/counter/counter.service";

@Component({
  selector: "ngx-counter-modal",
  templateUrl: "./counter-modal.component.html",
  styleUrls: ["./counter-modal.component.scss"],
})
export class CounterModalComponent implements OnInit, OnChanges {
  @Input() isOpen = false;
  @Input() title = "Informations";
  @Input() id: number;
  @Input() image: string = "";
  @Output() closeModal = new EventEmitter<void>();

  counterData: any = null;
  showImageViewer = false;

  constructor(private counterService: CounterService) {}

  ngOnInit(): void {
    if (this.id) {
      this.loadCounterData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["isOpen"] && changes["isOpen"].currentValue) {
      this.loadCounterData();
    }
    if (changes["isOpen"] && !changes["isOpen"].currentValue) {
      this.showImageViewer = false;
    }
  }

  private loadCounterData(): void {
    if (!this.id) return;

    this.counterService.userCounterById(this.id).subscribe({
      next: (response: any) => {
        if (response && Array.isArray(response) && response.length > 0) {
          this.counterData = response[0];
        } else {
          this.counterData = null;
        }
      },
      error: (error) => {
        console.error("Error loading counter data:", error);
        this.counterData = null;
      },
    });
  }

  viewImage(): void {
    if (this.image) {
      this.showImageViewer = true;
    }
  }

  closeImage(): void {
    this.showImageViewer = false;
  }

  close(): void {
    this.counterData = null;
    this.showImageViewer = false;
    this.closeModal.emit();
  }
}
