import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CounterModalComponent } from "./counter-modal/counter-modal.component";
import { FormsModule } from "@angular/forms";
import { TableDataGraphicComponent } from './table-data-graphic/table-data-graphic.component';
import { NbCardModule, NbSpinnerModule } from "@nebular/theme";
import { NgxEchartsModule } from "ngx-echarts";
import { AlertmodalComponent } from './alertmodal/alertmodal.component';
import { AlertdetailsComponent } from './alertdetails/alertdetails.component';

@NgModule({
  imports: [FormsModule, CommonModule,
    NbCardModule,
    NbSpinnerModule,
    NgxEchartsModule
  ],
  declarations: [CounterModalComponent, TableDataGraphicComponent, AlertmodalComponent, AlertdetailsComponent],

  exports: [CounterModalComponent],
})
export class ModalsModule {}
