import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { AlertdetailsComponent } from "../alertdetails/alertdetails.component";
import { AlarmsService } from "../../../@core/services/alarms.service";
import { AlarmCounterRequest } from "../../../@core/types/alarm-counter-request";

@Component({
  selector: "ngx-alertmodal",
  templateUrl: "./alertmodal.component.html",
  styleUrls: ["./alertmodal.component.scss"],
})
export class AlertmodalComponent {
  @Input() rowData: any;
  @Output() onDetails = new EventEmitter<void>();

  constructor(
    private dialogService: NbDialogService,
    private alarmsService: AlarmsService
  ) {}

  openModal() {
    this.dialogService.open(AlertdetailsComponent, {
      context: {
        alert: this.rowData,
      },
    });

    const request: AlarmCounterRequest = {
      libelle: this.rowData.libelle,
      matricule: this.rowData.matricule,
      seen: this.rowData.seen,
      time: this.rowData.time,
      type: this.rowData.type,
      value: this.rowData.value,
    };

    this.alarmsService.AlertSeen(request).subscribe({
      next: () => this.onDetails.emit(),
      error: (err) => console.error("Error marking alert as seen:", err),
    });
  }
}
