import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { CounterService } from "../../../@core/services/counter/counter.service";
import * as L from "leaflet";
import { register } from "swiper/element/bundle";
import { PlotResponse } from "../../../@core/types/plot-response";
import { CustomMapOptions, Feature } from "../../../@core/types";
import { NbSpinnerService } from "@nebular/theme";
import { DomSanitizer } from "@angular/platform-browser";
import { DateFormatter } from "../../../@core/utils/format-date";

@Component({
  selector: "ngx-compteur",
  templateUrl: "./compteur.component.html",
  styleUrls: ["./compteur.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CompteurComponent implements OnInit, AfterViewInit {
  private map1: L.Map | undefined;
  selectedCounterId: number | null = null;

  private mapReady = false;
  private dataLoaded = false;
  private initialLoadComplete = false;
  @Input() exploitationOptions: string[] = [];
  public lastCommunicationDate: string = "Inconnue";
  @Input() selectedExploitation: string = "";
  filteredFeatures: Feature[] = [];

  @Output() counterSelected = new EventEmitter<number>();

  private markerGroup: {
    [key: string]: { marker: L.Marker; circle: L.Circle };
  } = {};
  private plotLayerGroup: L.LayerGroup | undefined;

  showModal: boolean = false;
  selectedImage: any = "";
  s_n: string = "";
  features: Feature[] = [];
  meterValue: string = "";
  lastCommunication: Date = new Date();
  maxFlow: number = 0;
  logoPath: string = "assets/images/logo-metri.png";
  gaugePath: string = "assets/images/gauge.png";
  title: string = "";
  selectedcmptr_m: string = "";
  max_table: number = 0;
  Lasttime: string = "";
  LastHour: string = "";
  LastValue: number | null = null;
  Ploat: PlotResponse = null;
  private _selectedCounter: number | null = null;
  selectedMatricule: string = "";
  isCardsLoading: boolean = true;

  responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];



  @Input()
  set selectedCounter(value: number | null) {
    if (value !== this._selectedCounter) {
      this._selectedCounter = value;
      if (value !== null) {
        this.handleCounterSelection(value);
      }
    }
  }

  constructor(
    private counterService: CounterService,
    private spinnerService: NbSpinnerService,
    private sanitizer: DomSanitizer
  ) {
    register();
  }

  ngOnInit(): void {
    if (this.selectedExploitation) {
      this.loadCounterStatus();
    }
    this.getPlot();
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.mapReady = true;
    this.handleInitialSelection();

    const swiperElements = document.querySelectorAll("swiper-container");
    swiperElements.forEach((swiperEl: any) => {
      if (swiperEl?.swiper) {
        swiperEl.swiper.update();
      }
    });
  }

  private handleInitialSelection(): void {
    if (this.mapReady && this.dataLoaded && !this.initialLoadComplete) {
      this.initialLoadComplete = true;

      if (this._selectedCounter !== null) {
        const selectedFeature = this.filteredFeatures.find(
          (feature) => feature.properties.id_compteur === this._selectedCounter
        );

        if (selectedFeature) {
          setTimeout(() => {
            this.onCardClick(selectedFeature);
            this.highlightMarker(selectedFeature.properties.matricule);
          }, 100);
        }
      }
    }
  }

  handleCounterSelection(counterId: number) {
    const selectedFeature = this.filteredFeatures.find(
      (feature) => feature.properties.id_compteur === counterId
    );

    if (selectedFeature) {
      this.onCardClick(selectedFeature);
    }
  }

  formatDate(date: string | Date): string {
    return DateFormatter.convertToFrenchDate(date);
  }

  private initMap(): void {
    const mapOptions: CustomMapOptions = {
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: "topleft" as L.ControlPosition,
        title: "Plein écran",
        titleCancel: "Quitter le plein écran",
      },
    };

    this.map1 = L.map("map1", mapOptions).setView([32.2863, -6.5847], 15);

    L.tileLayer(
      "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      { attribution: "Agro" }
    ).addTo(this.map1);

    L.tileLayer(
      "https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}",
      { attribution: "Lora" }
    ).addTo(this.map1);

    this.setupMapControls();
  }

  private setupMapControls(): void {
    if (!this.map1) return;

    const PrintControl = L.Control.extend({
      options: { position: "topleft" },
      onAdd: (map) => {
        const container = L.DomUtil.create(
          "div",
          "leaflet-control-zoom leaflet-bar leaflet-control"
        );
        const button = L.DomUtil.create(
          "a",
          "leaflet-control-print",
          container
        );
        button.title = "Imprimer";
        button.innerHTML = '<i class="fas fa-print"></i>';

        L.DomEvent.on(button, "click", (e) => {
          L.DomEvent.preventDefault(e);
          L.DomEvent.stopPropagation(e);
          this.printMap();
        });

        return container;
      },
    });

    new PrintControl().addTo(this.map1);

    const legend = new L.Control({ position: "bottomleft" });
    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "legend");
      div.innerHTML = `
        <div class="legend-content">
          <div class="legend-item">
            <img src="assets/images/water-meter.png" alt="Compteur" />
            <span>Compteur</span>
          </div>
          <div class="legend-item">
            <img src="assets/images/sea-level.png" alt="Piézomètre" />
            <span>Piézomètre</span>
          </div>
          <div class="legend-item">
            <img src="assets/images/washbasin.png" alt="Bassin" />
            <span>Bassin</span>
          </div>
        </div>
      `;
      return div;
    };
    legend.addTo(this.map1);

    this.plotLayerGroup = L.layerGroup().addTo(this.map1);

    const CoordinatesControl = L.Control.extend({
      options: { position: "bottomright" },
      onAdd: () => {
        const container = L.DomUtil.create("div", "coordinates-control");
        Object.assign(container.style, {
          background: "rgba(255, 255, 255, 0.8)",
          padding: "5px 10px",
          margin: "10px",
          borderRadius: "4px",
          fontSize: "12px",
          fontFamily: "Arial",
        });
        container.innerHTML = "Lng: 0 Lat: 0";
        return container;
      },
    });

    new CoordinatesControl().addTo(this.map1);

    this.map1.on("mousemove", (e: L.LeafletMouseEvent) => {
      const container = document.querySelector(".coordinates-control");
      if (container) {
        container.innerHTML = `Lng: ${e.latlng.lng.toFixed(
          4
        )} Lat: ${e.latlng.lat.toFixed(4)}`;
      }
    });
  }

  private printMap(): void {
    if (!this.map1) return;

    const style = document.createElement("style");
    style.innerHTML = `
      @media print {
        body * { visibility: hidden; }
        #map1, #map1 * { visibility: visible; }
        #map1 {
          position: fixed !important;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
        }
        .leaflet-control-container { display: none !important; }
      }
    `;

    document.head.appendChild(style);
    window.print();
    document.head.removeChild(style);
  }

  getPlot() {
    this.counterService.parcelles().subscribe({
      next: (response) => {
        this.Ploat = response;
        this.addPlotsToMap();
      },
      error: (err) => console.error("Error fetching plot data", err),
    });
  }

  private addPlotsToMap(): void {
    if (!this.map1 || !this.Ploat || !this.plotLayerGroup) return;

    this.plotLayerGroup.clearLayers();

    const plotStyle = {
      color: "#FFFFFF",
      weight: 2,
      opacity: 0.8,
      fillOpacity: 0.2,
      fillColor: "#106A77",
    };

    const geoJsonLayer = L.geoJSON(this.Ploat as GeoJSON.FeatureCollection, {
      style: () => plotStyle,
      onEachFeature: (feature, layer) => {
        const popupContent = `
          <strong>Compteur ID:</strong> ${
            feature.properties?.id_compteur || "N/A"
          }<br>
          <strong>Matricule:</strong> ${
            feature.properties?.matricule || "N/A"
          }<br>
          <strong>Last Value:</strong> ${
            feature.properties?.last_value || "N/A"
          } m³
        `;
        layer.bindPopup(popupContent);
      },
    });

    geoJsonLayer.addTo(this.plotLayerGroup);

    if (geoJsonLayer.getBounds().isValid()) {
      this.map1.fitBounds(geoJsonLayer.getBounds());
    }
  }

  loadCounterStatus(): void {
    this.isCardsLoading = true;
    this.spinnerService.load();

    this.features = [];
    this.filteredFeatures = [];
    this.clearMarkers();

    this.counterService
      .counterPerExploitation_mob(this.selectedExploitation)
      .subscribe({
        next: (response: any[]) => {
          if (response && response.length > 0) {
            const piezometreResponse = response[0];
            if (piezometreResponse && piezometreResponse.features) {
              this.features = piezometreResponse.features;
              this.filteredFeatures = this.filterFeaturesByExploitation(
                piezometreResponse.features
              );

              if (this.filteredFeatures.length > 0) {
                this.onCardClick(this.filteredFeatures[0]);
              }

              this.addAllMarkers();
              this.dataLoaded = true;
              this.handleInitialSelection();
            }
          }

          this.isCardsLoading = false;
          this.spinnerService.clear();

          setTimeout(() => {
            const swiperElements =
              document.querySelectorAll("swiper-container");
            swiperElements.forEach((swiperEl: any) => {
              if (swiperEl?.swiper) {
                swiperEl.swiper.update();
              }
            });
          }, 100);
        },
        error: (err) => {
          console.error("[Compteur] Error:", err);
          this.isCardsLoading = false;
          this.spinnerService.clear();
        },
      });
  }

  private filterFeaturesByExploitation(features: Feature[]): Feature[] {
    if (!this.selectedExploitation) {
      return features;
    }
    return features.filter(
      (feature) => feature.properties.nom_groupe === this.selectedExploitation
    );
  }

  private addAllMarkers(): void {
    if (!this.map1) return;

    this.clearMarkers();

    this.filteredFeatures.forEach((feature) => {
      if (feature.geometry?.coordinates) {
        const [lng, lat] = feature.geometry.coordinates;
        const id = feature.properties.matricule;
        const config = this.getMarkerConfig(feature);
        const matriculeClass = id.toLowerCase().replace(/\s+/g, "-");

        const icon = L.divIcon({
          className: `custom-marker marker-${matriculeClass}`,
          html: `
            <div class="marker-container">
              <img src="assets/images/water-meter.png" alt="marker" class="marker-image">
              <div class="marker-label">${feature.properties.matricule}</div>
            </div>
          `,
          iconSize: [60, 60],
          iconAnchor: [30, 30],
        });

        const marker = L.marker([lat, lng], { icon }).addTo(this.map1!);

        const lastValue = (feature.derniere_val?.[0]?.cumul ?? 0) / 1000;
        const popupContent = `
          <strong>Compteur ID:</strong> ${feature.properties.id_compteur}<br>
          <strong>Matricule:</strong> ${feature.properties.matricule}<br>
          <strong>Libelle:</strong> ${feature.properties.libelle}<br>
          <strong>Dernière valeur:</strong> ${lastValue.toFixed(2)} m³/h
        `;
        marker.bindPopup(popupContent);

        const circle = L.circle([lat, lng], {
          color: config.circleColor,
          fillColor: config.circleColor,
          fillOpacity: 0.2,
          radius: config.circleRadius,
          weight: 2,
        }).addTo(this.map1!);

        this.markerGroup[id] = { marker, circle };
        marker.on("click", () => this.onMarkerClick(feature));
      }
    });

    this.updateMapBounds();
  }

  private getMarkerConfig(feature: Feature) {
    return {
      circleColor: "#4CAF50",
      circleRadius: 20,
    };
  }

  handleExploitationSelection(exploitation: string): void {
    this.selectedExploitation = exploitation;
    if (exploitation) {
      this.loadCounterStatus();
    } else {
      this.selectedCounterId = null;
      this.resetUI();
    }
  }

  private filterFeatures(): void {
    if (!this.features || this.features.length === 0) {
      this.filteredFeatures = [];
      return;
    }

    this.filteredFeatures = this.filterFeaturesByExploitation(this.features);

    this.clearMarkers();
    this.addAllMarkers();

    if (this.selectedCounterId) {
      const selectedFeature = this.filteredFeatures.find(
        (feature) => feature.properties.id_compteur === this.selectedCounterId
      );
      if (selectedFeature) {
        this.onCardClick(selectedFeature);
      } else if (this.filteredFeatures.length > 0) {
        this.onCardClick(this.filteredFeatures[0]);
      } else {
        this.resetUI();
      }
    } else if (this.filteredFeatures.length > 0) {
      this.onCardClick(this.filteredFeatures[0]);
    } else {
      this.resetUI();
    }
  }

  private clearMarkers(): void {
    Object.values(this.markerGroup).forEach(({ marker, circle }) => {
      marker.remove();
      circle.remove();
    });
    this.markerGroup = {};
  }

  private updateMapBounds(): void {
    if (!this.map1 || this.filteredFeatures.length === 0) return;

    const bounds = L.latLngBounds(
      this.filteredFeatures.map((feature) => {
        const [lng, lat] = feature.geometry.coordinates;
        return [lat, lng];
      })
    );

    this.map1.fitBounds(bounds, { padding: [50, 50] });

    if (this.filteredFeatures.length === 1) {
      const [lng, lat] = this.filteredFeatures[0].geometry.coordinates;
      this.map1.setView([lat, lng], 18);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["selectedExploitation"]) {
      this.filterFeatures();
    }
  }

  private resetUI(): void {
    this.selectedcmptr_m = "";
    this.title = "";
    this.selectedCounterId = null;
    this.meterValue = "";
    this.lastCommunication = new Date();
    this.Lasttime = "";
    this.LastHour = "";
    this.max_table = 0;
    this.LastValue = null;
    this.maxFlow = 0;
  }

  private highlightMarker(matricule: string): void {
    if (!matricule || !this.markerGroup[matricule]) return;

    Object.values(this.markerGroup).forEach(({ circle, marker }) => {
      circle.setStyle({
        fillOpacity: 0.2,
        weight: 2,
      });
      circle.setRadius(20);

      const el = marker.getElement();
      if (el) el.classList.remove("pulse");
    });

    const markerInfo = this.markerGroup[matricule];
    if (markerInfo) {
      const feature = this.filteredFeatures.find(
        (f) => f.properties.matricule === matricule
      );

      if (feature) {
        const config = this.getMarkerConfig(feature);
        markerInfo.circle.setStyle({
          color: config.circleColor,
          fillColor: config.circleColor,
          fillOpacity: 0.4,
          weight: 3,
        });
        markerInfo.circle.setRadius(20);

        const markerElement = markerInfo.marker.getElement();
        if (markerElement) {
          markerElement.classList.add("pulse");
        }
      }
    }
  }

  private formatNumber(value: number): string {
    return new Intl.NumberFormat("fr-FR").format(value) + " m³";
  }

  trackByCounter(index: number, feature: Feature): number {
    return feature.properties.id_compteur || index;
  }

  onCardClick(feature: Feature): void {
    this.selectedMatricule = feature.properties.matricule;
    this.updateUIWithCounter(feature);
    this.s_n = feature.properties.s_n;
    if (this.map1 && feature.geometry?.coordinates) {
      const [lng, lat] = feature.geometry.coordinates;

      setTimeout(() => {
        this.map1?.setView([lat, lng], 19);
        this.highlightMarker(feature.properties.matricule);
      }, 100);

      this.selectedCounterId = feature.properties.id_compteur;
      this.counterSelected.emit(feature.properties.id_compteur);
    }
  }

  private updateUIWithCounter(feature: Feature): void {
    this.selectedcmptr_m = feature.properties.matricule;
    this.title = `Puit: ${feature.properties.matricule}`;
    this.selectedCounterId = feature.properties.id_compteur;

    const lastValue = (feature.derniere_val?.[0]?.cumul ?? 0) / 1000;
    this.meterValue = this.formatNumber(lastValue);

    if (feature.derniere_val?.[0]) {
      this.lastCommunication = new Date(feature.derniere_val[0].date);
      this.Lasttime = feature.derniere_val[0].time;
      this.LastHour = feature.derniere_val[0].hour;
      this.LastValue = feature.derniere_val[0].cumul / 1000;
    }

    this.maxFlow = (feature.max?.last ?? 0) / 1000;
    this.max_table = (feature.max?.last ?? 0) / 1000;
  }

  private onMarkerClick(feature: Feature): void {
    this.onCardClick(feature);
  }

  closeModalInfos(): void {
    this.showModal = false;
    this.selectedImage = "";
  }

  openModalInfos(): void {
    if (this.s_n) {
      this.showModal = true;
      this.loadModalImage(this.s_n);
    }
  }

  private loadModalImage(sn: string): void {
    this.counterService.getCaptImage(sn).subscribe({
      next: (imageBlob) => {
        const objectURL = URL.createObjectURL(imageBlob);
        this.selectedImage = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      },
      error: (err) => {
        console.error("Failed to fetch image:", err);
        this.selectedImage = "";
      },
    });
  }

  ngOnDestroy(): void {
    if (this.map1) {
      this.map1.remove();
    }
    const swiperEl = document.querySelector("swiper-container");
    if (swiperEl) {
      swiperEl.swiper?.destroy();
    }
  }
}
