<div class="dashboard-layout-b">
  <div class="cards-section">
    <p-carousel
      *ngIf="filteredBasins.length > 4"
      [value]="filteredBasins"
      [numVisible]="4"
      [numScroll]="4"
      [circular]="true"
      [autoplayInterval]="3000"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template pTemplate="item" let-basin>
        <div
          class="stat-card"
          [class.selected]="selectedCardId === basin.id_capteur"
          (click)="onCardClick(basin.id_capteur)"
        >
          <div class="titlea">{{ basin.libelle }} <br /></div>
          <hr class="title-divider" />
          <div class="communication-info">
            Date d'installation:
            {{ basin["date installation"] | date : "shortDate" }} <br />
          </div>

          <div class="value-info">
            Volume totale {{ basin.volume_total }} m³ <br>
          </div>
          
          <div class="value-info">
            Taux de remplissage: {{ selectedPourcentage }}
          </div>
        </div>
      </ng-template>
    </p-carousel>

    <div class="cards-wrapper" *ngIf="filteredBasins.length <= 4">
      <div
        class="stat-card"
        *ngFor="let basin of filteredBasins"
        [class.selected]="selectedCardId === basin.id_capteur"
        (click)="onCardClick(basin.id_capteur)"
      >
        <div class="titlea">{{ basin.libelle }} <br /></div>
        <hr class="title-divider" />
        <div class="communication-info">
          Date d'installation:
          {{ basin["date installation"] | date : "shortDate" }} <br />
          Volume totale {{ basin.volume_total }} m³ <br>
          Taux de remplissage: {{ selectedPourcentage }}
        </div>
      </div>
    </div>
  </div>


  <div class="content-section-b">
    <div class="basin-content-display">
      <div class="progress-container">
        <div class="progress" role="progressbar" [attr.data-value]="waterLevel">
          <div class="basin-shape">
            <div
              class="spinner-container"
              *ngIf="isLoading || waterLevel === null"
            >
              <div class="water-spinner">
                <div class="wave wave1"></div>
                <div class="wave wave2"></div>
                <div class="wave wave3"></div>
              </div>
              <div class="loading-text">Chargement...</div>
            </div>
            <div
              class="progress-inner"
              *ngIf="!isLoading && waterLevel !== null"
            >
              <div class="water-level" [style.height]="waterLevel + '%'">
                <div class="wave-container">
                  <div class="wave wave1"></div>
                  <div class="wave wave2"></div>
                  <div class="wave wave3"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="progress-label" *ngIf="!isLoading && waterLevel !== null">
            <strong>{{ waterLevel }}%</strong><br />
            <span class="time-label">{{ currentTime | date : "medium" }}</span>
          </div>
        </div>
      </div>

      <div class="info-content">
        <div class="info-row">
          <span class="label">Dernière mesure:</span>
          <span class="value">{{
            currentTime ? (currentTime | date : "dd-MM-yyyy HH:mm") : "N/A"
          }}</span>
        </div>
        <div class="info-row">
          <span class="label">Niveau d'eau:</span>
          <span class="value">{{ selectedNiveau || "N/A" }}</span>
        </div>
        <div class="info-row">
          <span class="label">Volume d'eau:</span>
          <span class="value">{{ selectedVolume || "N/A" }}</span>
        </div>
        <div class="info-row">
          <span class="label">Taux de remplissage:</span>
          <span class="value">{{ selectedPourcentage || "N/A" }}</span>
        </div>
      </div>
    </div>

    <div class="map-container" id="map1"></div>
  </div>
</div>

<ngx-water-performance
  [id]="selectedId"
  [basinMatricule]="selectedBasinMatricule"
  (volumeUpdated)="onVolumeUpdated($event)"
  (niveauUpdated)="onNiveauUpdated($event)"
  (pourcentageUpdated)="onPourcentageUpdated($event)"
>
</ngx-water-performance>
