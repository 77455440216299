import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment.prod";
import { BehaviorSubject, Observable } from "rxjs";
import { AlarmCounterResponse } from "../types/alarms-counter-response";

@Injectable({
  providedIn: "root",
})
export class AlarmsService {
  private readonly ALARME_COUNTER = environment.ALARME_COUNTER_API;
  private readonly ALERT_SEEN = environment.ALERT_SEEN;
  private readonly ARCHIVE_SEEN = environment.ARCHIVE_API;
  private readonly SEE_ALL = environment.SEE_ALL_API;

  private alertsCount = new BehaviorSubject<number>(0);
  alertsCount$ = this.alertsCount.asObservable();

  constructor(private http: HttpClient) {}

  getCounterAlarms(): Observable<AlarmCounterResponse[]> {
    return this.http.get<AlarmCounterResponse[]>(this.ALARME_COUNTER);
  }

  getArchivedCounters(): Observable<AlarmCounterResponse[]> {
    return this.http.get<AlarmCounterResponse[]>(`${this.ARCHIVE_SEEN}`);
  }

  AlertSeen(AlarmCounterRequest): Observable<void> {
    return this.http.post<void>(this.ALERT_SEEN, AlarmCounterRequest);
  }

  markerAllAsSeen(): Observable<void> {
    return this.http.get<void>(this.SEE_ALL);
  }
  updateAlertsCount(count: number) {
    this.alertsCount.next(count);
  }
}
