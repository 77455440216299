import {Component,OnInit,Output,ViewChild,EventEmitter} from "@angular/core";
import { CounterResponse } from "../../@core/types/counter-response";
import { SharedService } from "../../@shared/services/shared-service.service";
import { BasinResponse, PiezometreStatusResponse } from "../../@core/types";
import { CompteurComponent } from "./compteur/compteur.component";

@Component({
  selector: "ngx-dashboard",
  styleUrls: ["./dashboard.component.scss"],
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit {
  counters: CounterResponse[] = [];
  allCounters: CounterResponse[] = [];
  basins: BasinResponse[] = [];
  @Output() exploitationOptionsChange = new EventEmitter<string[]>();
  piezos: PiezometreStatusResponse[] = [];
  @ViewChild(CompteurComponent) compteurComponent!: CompteurComponent;
  navigationItems: Array<{
    id: string;
    icon: string;
    label: string;
    show?: boolean;
    disabled?: boolean;
  }> = [];

  selectedCounterId: number | null = null;
  selectedBasinId: number | null = null;
  selectedPiezoId: number | null = null;
  selectedPiezoMatricule: string = "";
  selectedBasinMatricule: string = "";
  selectedComponent: string = "compteur";
  selectedExploitation: string = "";

  isLoading: boolean = true;
  exploitationOptions: string[] = [];

  defaultCounter: CounterResponse | null = null;
  defaultBasin: BasinResponse | null = null;
  defaultPiezo: PiezometreStatusResponse | null = null;

  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {
    this.fetchAllData();
  }

  private componentExploitations: {
    compteur: Set<string>;
    basin: Set<string>;
    piezometre: Set<string>;
  } = {
    compteur: new Set(),
    basin: new Set(),
    piezometre: new Set(),
  };

  private async fetchAllData() {
    this.isLoading = true;
    try {
      await Promise.all([
        this.fetchCounters(),
        this.fetchBasins(),
        this.fetchPiezo(),
      ]);

      this.updateNavigationItems();
      this.setInitialComponent();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.isLoading = false;
    }
  }

  private updateNavigationItems() {
    const baseItems = [
      {
        id: "compteur",
        icon: "water-meter.png",
        label: "Compteur",
        disabled: !this.componentExploitations.compteur.has(
          this.selectedExploitation
        ),
      },
      {
        id: "piezometre",
        icon: "sea-level.png",
        label: "Piézomètre",
        disabled: !this.componentExploitations.piezometre.has(
          this.selectedExploitation
        ),
      },
      {
        id: "basin",
        icon: "washbasin.png",
        label: "Bassin",
        disabled: !this.componentExploitations.basin.has(
          this.selectedExploitation
        ),
      },
      { id: "vitesse", icon: "flash.png", label: "Vitesse", disabled: true },
      {
        id: "Qualite deau",
        icon: "quality.png",
        label: "Qualité de l'eau",
        disabled: true,
      },
    ];

    this.navigationItems = baseItems;
  }

  isComponentVisible(componentType: string): boolean {
    return this.selectedComponent === componentType;
  }

  private setInitialComponent() {
    const firstAvailableComponent = this.navigationItems.find(
      (item) => !item.disabled
    );
    if (firstAvailableComponent) {
      this.selectComponent(firstAvailableComponent.id);
    }
  }

  private syncCounterSelection() {
    if (this.selectedExploitation) {
      this.counters = this.allCounters.filter(
        (counter) => counter.nom_groupe === this.selectedExploitation
      );

      if (this.counters.length > 0) {
        const currentCounterExists = this.counters.some(
          (counter) => counter.id_compteur === this.selectedCounterId
        );

        if (!currentCounterExists) {
          this.selectedCounterId = this.counters[0].id_compteur;
        }
      } else {
        this.selectedCounterId = null;
      }
    }
  }

  private updateExploitationOptions() {
    const allExploitations = new Set([
      ...Array.from(this.componentExploitations.compteur),
      ...Array.from(this.componentExploitations.basin),
      ...Array.from(this.componentExploitations.piezometre),
    ]);

    this.exploitationOptions = Array.from(allExploitations).sort((a, b) =>
      a.localeCompare(b)
    );

    if (this.exploitationOptions.length > 0) {
      if (!this.selectedExploitation) {
        this.selectedExploitation = this.exploitationOptions[0];
      }
      this.syncCounterSelection();
      this.exploitationOptionsChange.emit(this.exploitationOptions);
      this.updateNavigationItems();
    }
  }

  private lastSelectedCounterId: number | null = null;

  selectComponent(component: string) {
    const previousComponent = this.selectedComponent;
    this.selectedComponent = component;

    this.selectedCounterId = null;
    this.selectedBasinId = null;
    this.selectedPiezoId = null;
    switch (component) {
      case "compteur":
        if (this.counters.length > 0) {
          this.selectedCounterId =
            this.lastSelectedCounterId || this.counters[0].id_compteur;
        }
        break;
      case "basin":
        const filteredBasins = this.selectedExploitation
          ? this.basins.filter(
              (b) => b.nom_groupe === this.selectedExploitation
            )
          : this.basins;

        if (filteredBasins.length > 0) {
          this.selectedBasinId = filteredBasins[0].id_capteur;
          this.selectedBasinMatricule = filteredBasins[0].matricule;
        } else {
          this.selectedBasinId = null;
          this.selectedBasinMatricule = "";
        }
        break;

      case "piezometre":
        if (this.piezos.length > 0) {
          this.selectedPiezoId = this.piezos[0].id_piezometre;
        }
        break;
    }

    this.updateNavigationItems();
  }

  async fetchCounters() {
    return new Promise<void>((resolve, reject) => {
      this.sharedService.getCounters().subscribe({
        next: (data) => {
          this.allCounters = data;
          this.counters = data;

          data.forEach((counter) => {
            if (counter.nom_groupe) {
              this.componentExploitations.compteur.add(counter.nom_groupe);
            }
          });

          this.updateExploitationOptions();
          if (this.counters.length > 0) {
            this.defaultCounter = this.counters[0];
            this.selectedCounterId = this.defaultCounter.id_compteur;
          }
          resolve();
        },
        error: (err) => {
          console.error("Error fetching counters:", err);
          reject(err);
        },
      });
    });
  }

  async fetchBasins() {
    return new Promise<void>((resolve, reject) => {
      this.sharedService.getBasins().subscribe({
        next: (data) => {
          this.basins = data;

          data.forEach((basin) => {
            if (basin.nom_groupe) {
              this.componentExploitations.basin.add(basin.nom_groupe);
            }
          });

          if (this.basins.length > 0) {
            this.defaultBasin = this.basins[0];
            this.selectedBasinId = this.defaultBasin.id_capteur;
            this.selectedBasinMatricule = this.defaultBasin.matricule;
          }
          resolve();
        },
        error: (err) => {
          console.error("Error fetching basins:", err);
          reject(err);
        },
      });
    });
  }

  async fetchPiezo() {
    return new Promise<void>((resolve, reject) => {
      this.sharedService.getPiezo().subscribe({
        next: (data) => {
          this.piezos = data;

          data.forEach((piezo) => {
            if (piezo.nom_groupe) {
              this.componentExploitations.piezometre.add(piezo.nom_groupe);
            }
          });

          if (this.piezos.length > 0) {
            this.defaultPiezo = this.piezos[0];
            this.selectedPiezoId = this.defaultPiezo.id_piezometre;
          }
          resolve();
        },
        error: (err) => {
          console.error("Error fetching piezometers:", err);
          reject(err);
        },
      });
    });
  }

  onExploitationSelect(event: Event) {
    const exploitation = (event.target as HTMLSelectElement).value;
    this.selectedExploitation = exploitation;

    this.updateNavigationItems();

    if (
      this.navigationItems.find((item) => item.id === this.selectedComponent)
        ?.disabled
    ) {
      const firstAvailable = this.navigationItems.find(
        (item) => !item.disabled
      );
      if (firstAvailable) {
        this.selectComponent(firstAvailable.id);
      }
    }

    this.counters = this.allCounters.filter(
      (counter) => counter.nom_groupe === exploitation
    );

    if (this.counters.length > 0) {
      this.selectedCounterId = this.counters[0].id_compteur;
    } else {
      this.selectedCounterId = null;
    }

    if (this.compteurComponent) {
      this.compteurComponent.handleExploitationSelection(exploitation);
    }
  }

  updateSelectedCounter(counterId: number) {
    this.selectedCounterId = counterId;
    this.lastSelectedCounterId = counterId;
    setTimeout(() => {
      const selectElement = document.querySelector(
        'select.select-control[name="counter"]'
      ) as HTMLSelectElement;
      if (selectElement) {
        selectElement.value = counterId.toString();
      }
    });
  }

  updateSelectedBasin(basinId: number) {
    this.selectedBasinId = basinId;
    setTimeout(() => {
      const selectElement = document.querySelector(
        'select[name="basin"]'
      ) as HTMLSelectElement;
      if (selectElement) {
        selectElement.value = basinId.toString();
      }
    });
  }

  onItemSelect(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedId = parseInt(selectElement.value, 10);

    if (isNaN(selectedId)) return;

    switch (this.selectedComponent) {
      case "basin":
        this.selectedBasinId = selectedId;
        const selectedBasin = this.basins.find(
          (basin) => basin.id_capteur === selectedId
        );
        if (selectedBasin) {
          this.selectedBasinMatricule = selectedBasin.matricule;
        }
        this.selectedComponent = "basin";
        break;

      case "compteur":
        this.selectedCounterId = selectedId;
        this.lastSelectedCounterId = selectedId;
        break;

      case "piezometre":
        this.selectedPiezoId = selectedId;
        this.selectedComponent = "piezometre";
        break;
    }
  }

  resetToDefault() {
    this.selectedExploitation = "";
    this.counters = this.allCounters;
    if (this.counters.length > 0) {
      this.selectedCounterId = this.counters[0].id_compteur;
    } else {
      this.selectedCounterId = null;
    }
  }
}
