import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CounterPiezometreResponse } from "../../types/counter-piezometre-response";
import { UserCounterResponse } from "../../types/user-counter.response";
import { VolumeRequest } from "../../types/volume-rq";
import { CounterDataRespose } from "../../types/counter-data-response";
import { DateFormatter } from "../../utils/format-date";
import { CounterHourlyResponse } from "../../types/counter-daily-response";
import { CounterHourlyRequest } from "../../types/counter-hourly-request";
import { PlotResponse } from "../../types/plot-response";
import { CounterHourlyEntry } from "../../types/counter-hourly-response";
import { CounterPerMonthResponse } from "../../types/counter-month-response";
import { environment } from "../../../../environments/environment.prod";
import { CounterResponse } from "../../types/counter-response";

@Injectable({
  providedIn: "root",
})
export class CounterService {
  private readonly COUNTER_API = environment.COUNTER_API;
  private readonly USER_COUNTER_API = environment.COUNTER_PER_ID;
  private readonly COUNTER_DATA_API = environment.COUNTER_PER_DAY;
  private readonly COUNTER_HOURLY_API = environment.COUNTER_HOURLY;
  private readonly PARCELLES_API = environment.PARCELLES_api;
  private readonly VOLUME_HOURLY = environment.COUNTER_PER_HOUR;
  private readonly COUNTER_MONTH = environment.COUNTER_PER_MONTH;
  private readonly COUNTER_PER_EXPLOITATION = environment.COUNTER_PER_EXPLOITATION;
  private readonly COUNTER_PER_EXPLOITATION_MOB = environment.COUNTER_PER_EXPLOITATION_MOB;
  private readonly CAPT_IMAGE = environment.CAPT_IMAGES;


  constructor(private http: HttpClient) {}

  getCaptImage(sn: string): Observable<Blob> {
    return this.http.get(`${this.CAPT_IMAGE}${sn}`, { responseType: 'blob' });
  }
  
  parcelles(): Observable<PlotResponse> {
    return this.http.get<PlotResponse>(this.PARCELLES_API);
  }

  counterPerExploitation(exp: string): Observable<CounterResponse[]> {
    return this.http.get<CounterResponse[]>(
      `${this.COUNTER_PER_EXPLOITATION}/${exp}`
    );
  }

  counterPerExploitation_mob(exb : string) : Observable<CounterPiezometreResponse[]>{
    return this.http.get<CounterPiezometreResponse[]>(`${this.COUNTER_PER_EXPLOITATION_MOB}/${exb}`);
  }

  hourlyCounterData(
    request: CounterHourlyRequest
  ): Observable<CounterHourlyResponse[]> {
    const date = new Date(request.date);
    const formattedDate = DateFormatter.formatDate(date);
    return this.http.get<CounterHourlyResponse[]>(
      this.COUNTER_HOURLY_API +
        request.id +
        "/" +
        formattedDate +
        "/" +
        "no-cumul"
    );
  }

  hourlyCounterDtaNoCumul(
    request: CounterHourlyRequest
  ): Observable<CounterHourlyResponse[]> {
    const date = new Date(request.date);
    const formattedDate = DateFormatter.formatDate(date);
    return this.http.get<CounterHourlyResponse[]>(
      this.COUNTER_HOURLY_API +
        request.id +
        "/" +
        formattedDate +
        "/" +
        "no-cumul"
    );
  }

  counterDataCumul(request: VolumeRequest): Observable<CounterDataRespose[]> {
    const startDate = DateFormatter.formatDate(request.startDate);
    const endDate = DateFormatter.formatDate(request.endDate);
    return this.http.get<CounterDataRespose[]>(
      this.COUNTER_DATA_API +
        request.id +
        "/" +
        startDate +
        "/" +
        endDate +
        "/" +
        "cumul"
    );
  }

  counterData(request: VolumeRequest): Observable<CounterDataRespose[]> {
    const startDate = DateFormatter.formatDate(request.startDate);
    const endDate = DateFormatter.formatDate(request.endDate);
    return this.http.get<CounterDataRespose[]>(
      this.COUNTER_DATA_API +
        request.id +
        "/" +
        startDate +
        "/" +
        endDate +
        "/" +
        "normal"
    );
  }

  counterStatus(): Observable<CounterPiezometreResponse[]> {
    return this.http.get<CounterPiezometreResponse[]>(this.COUNTER_API);
  }

  userCounterById(id: number): Observable<UserCounterResponse> {
    return this.http.get<UserCounterResponse>(this.USER_COUNTER_API + "/" + id);
  }

  VolumeHourly(request: VolumeRequest): Observable<CounterHourlyEntry> {
    const startDate = DateFormatter.formatDate(request.startDate);
    const endDate = DateFormatter.formatDate(request.endDate);
    return this.http.get<CounterHourlyEntry>(
      `${this.VOLUME_HOURLY}/${startDate}/${endDate}/normal/${request.id}`
    );
  }

  VolumeHourly1(request: VolumeRequest): Observable<CounterHourlyEntry[]> {
    const startDate = DateFormatter.formatDate(request.startDate);
    const endDate = DateFormatter.formatDate(request.endDate);
    return this.http.get<CounterHourlyEntry[]>(
      `${this.VOLUME_HOURLY}/${startDate}/${endDate}/normal/${request.id}`
    );
  }

  VolumeMonthly(request: VolumeRequest): Observable<CounterPerMonthResponse> {
    const startDate = DateFormatter.formatDate(request.startDate);
    const endDate = DateFormatter.formatDate(request.endDate);
    return this.http.get<CounterPerMonthResponse>(
      `${this.COUNTER_MONTH}/${request.id}/${startDate}/${endDate}/normal`
    );
  }
}
