import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { CounterResponse } from "../../@core/types/counter-response";
import { BasinResponse, PiezometreStatusResponse } from "../../@core/types";
import { LastValue } from "../../@core/types/last-value-response";
import { BilanService } from "../../@core/services/bilan/bilan.service";
import { BilanRequest } from "../../@core/types/bilan-request";
import { tap, map, shareReplay } from "rxjs/operators";
import { WaterSourceMultipleResponse } from "../../@core/types/water-source-multiple-response";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private readonly COUNTER_API = environment.COUNTERS_INFOS;
  private readonly WATER_SOURCE_MULTIPLE_API =
    environment.WATER_SOURCE_MULTIPLE_API;
  private readonly BASIN_API = environment.BASIN_API;
  private readonly Piezo_API = environment.PIEZOMETRE_API;
  private cache: { [key: string]: Observable<any> } = {};
  private dataSubject = new BehaviorSubject<any>(null);
  public data$ = this.dataSubject.asObservable();

  private baseUrl = "http://196.206.231.215:3030";
  private lastValueSubject = new BehaviorSubject<LastValue[]>([]);
  lastValue$ = this.lastValueSubject.asObservable();

  constructor(private http: HttpClient, private bilanService: BilanService) {}

  getCounters(): Observable<CounterResponse[]> {
    return this.http.get<CounterResponse[]>(this.COUNTER_API);
  }

  getBasins(): Observable<BasinResponse[]> {
    return this.http.get<BasinResponse[]>(this.BASIN_API);
  }

  getPiezo(): Observable<PiezometreStatusResponse[]> {
    return this.http.get<PiezometreStatusResponse[]>(this.Piezo_API);
  }

  getLastValue(basinId: number): Observable<LastValue[]> {
    const url = `${this.baseUrl}/last_niveau_volume_data/${basinId}/pourcentage/`;
    return this.http.get<LastValue[]>(url);
  }

  updateLastValue(basinId: number): void {
    this.getLastValue(basinId).subscribe({
      next: (data) => {
        this.lastValueSubject.next(data);
      },
      error: (error) => {
        console.error("Error fetching last value:", error);
        this.lastValueSubject.next([]);
      },
    });
  }

  getCurrentValue(): LastValue[] {
    return this.lastValueSubject.getValue();
  }

  loadData(
    type: string,
    dateRange: { start: Date; end: Date },
    isDaily: boolean,
    isEnsemble: boolean = false,
    exploitation?: string
  ) {
    if (isEnsemble && !exploitation) {
      return of([]);
    }

    const cacheKey = this.getCacheKey(
      dateRange,
      isDaily,
      isEnsemble,
      exploitation
    );

    if (!this.cache[cacheKey]) {
      const request: BilanRequest = {
        startDate: dateRange.start,
        endDate: dateRange.end,
        exp: exploitation,
      };

      let apiCall: Observable<any>;

      if (isEnsemble) {
        apiCall = isDaily
          ? this.WaterSourceMultiple(request)
          : this.WaterSourceMultipleMonth(request);
      } else {
        apiCall = isDaily
          ? this.bilanService.getBilanDay(request)
          : this.bilanService.getBilanMonth(request);
      }

      this.cache[cacheKey] = apiCall.pipe(shareReplay(1));
    }

    return this.cache[cacheKey].pipe(
      map((response) => {
        if (isEnsemble) {
          return Array.isArray(response) ? response : [response];
        } else {
          return response.filter(
            (item: any) => item.type?.toLowerCase() === type.toLowerCase()
          );
        }
      })
    );
  }

  private getCacheKey(
    dateRange: { start: Date; end: Date },
    isDaily: boolean,
    isEnsemble: boolean = false,
    exploitation?: string
  ): string {
    const baseKey = `${dateRange.start}-${dateRange.end}-${isDaily}`;
    if (isEnsemble) {
      return `${baseKey}-ensemble-${exploitation}`;
    }
    return `${baseKey}-unite`;
  }

  clearCache(dateRange?: { start: Date; end: Date }) {
    if (dateRange) {
      Object.keys(this.cache).forEach((key) => {
        if (key.startsWith(`${dateRange.start}-${dateRange.end}`)) {
          delete this.cache[key];
        }
      });
    } else {
      this.cache = {};
    }
  }

  private formatDate(date: Date): string {
    return date.toISOString().split("T")[0];
  }

  WaterSourceMultiple(
    request: BilanRequest
  ): Observable<WaterSourceMultipleResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterSourceMultipleResponse[]>(
      `${this.WATER_SOURCE_MULTIPLE_API}day/${request.exp}/${startDate}/${endDate}`
    );
  }

  WaterSourceMultipleMonth(
    request: BilanRequest
  ): Observable<WaterSourceMultipleResponse[]> {
    const startDate = this.formatDate(request.startDate);
    const endDate = this.formatDate(request.endDate);
    return this.http.get<WaterSourceMultipleResponse[]>(
      `${this.WATER_SOURCE_MULTIPLE_API}month/${request.exp}/${startDate}/${endDate}`
    );
  }
}
