export class DateRangeValidator {
  static validateDateRange(range: { start: Date; end: Date }): boolean {
    if (!range?.start || !range?.end) return false;
    
    const startDate = new Date(range.start);
    const endDate = new Date(range.end);
    const today = new Date();
    
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return false;
    }
    
    today.setHours(23, 59, 59, 999);
    return startDate <= endDate && endDate <= today;
  }
}