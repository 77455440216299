import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import * as L from 'leaflet';
import { PiezometreService } from '../../../@core/services/piezometre/piezometre.service';
import { CounterService } from '../../../@core/services/counter/counter.service';
import { PiezometreStatusResponse } from '../../../@core/types/piezometre-response';
import { PlotResponse } from '../../../@core/types/plot-response';
import { CustomMapOptions } from '../../../@core/types';
import { NbSpinnerService } from '@nebular/theme';
import { DateFormatter } from '../../../@core/utils/format-date';
import { LastPiezometreResponse } from '../../../@core/types/piezo-last-response';

@Component({
  selector: 'ngx-piezometre',
  templateUrl: './piezometre.component.html',
  styleUrls: ['./piezometre.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PiezometreComponent implements OnInit, AfterViewInit {
  private map1: L.Map | undefined;
  private plotLayerGroup: L.LayerGroup | undefined;
  private markerGroup: {
    [key: string]: { marker: L.Marker; circle: L.Circle };
  } = {};
  
  private mapReady = false;
  private dataLoaded = false;
  private initialLoadComplete = false;

  PiezometreData: PiezometreStatusResponse[] = [];
  filteredPiezometres: PiezometreStatusResponse[] = [];
  selectedPiezometreData: PiezometreStatusResponse | null = null;
  Ploat: PlotResponse = null;
  
  PiezoLast: LastPiezometreResponse | null = null;
  
  waterLevel: string = '0%';
  
  get selectedPiezoId(): number | null {
    return this.selectedPiezometreData?.id_point_d_eau || null;
  }
  
  get selectedPiezoMatricule(): string {
    return this.selectedPiezometreData?.matricule || '';
  }

  
  @Input() selectedExploitation: string = '';
  @Input() exploitationOptions: string[] = [];
  @Output() piezometreSelected = new EventEmitter<number>();
  
  isCardsLoading: boolean = true;
  selectedPiezometreId: number | null = null;
  initialPiezoId: number | null = null;

  responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  depthMarks = Array(10)
  .fill(0)
  .map((_, i) => ({
    position: i * 10,
    label: (200 - i * 20).toString(),
  }));


  @Input()
  set selectedPiezometre(value: number | null) {
    if (value !== null) {
      this.initialPiezoId = value;
      this.handlePiezometreSelection(value);
    }
  }

  constructor(
    private piezometreService: PiezometreService,
    private counterService: CounterService,
    private spinnerService: NbSpinnerService
  ) {}

  ngOnInit(): void {
    this.loadPiezometreStatus();
    this.getPlot();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initMap();
      this.mapReady = true;
  
      if (this.selectedPiezometreData?.libelle) {
        this.highlightMarker(this.selectedPiezometreData.libelle);
      }
  
      if (this.filteredPiezometres.length > 0) {
        this.addAllMarkers();
      }
    }, 100);
  }
  private handleInitialSelection(): void {
    if (this.mapReady && this.dataLoaded && !this.initialLoadComplete) {
      this.initialLoadComplete = true;

      if (this.initialPiezoId !== null) {
        const selectedPiezo = this.filteredPiezometres.find(
          (piezo) => piezo.id_piezometre === this.initialPiezoId
        );

        if (selectedPiezo) {
          setTimeout(() => {
            this.onCardClick(selectedPiezo);
            this.highlightMarker(selectedPiezo.libelle);
          }, 100);
        }
      }
    }
  }

  loadPiezometreStatus(): void {
    this.isCardsLoading = true;
    this.spinnerService.load();

    if (this.selectedExploitation) {
      this.piezometreService.PiezoPerExploitation(this.selectedExploitation).subscribe({
        next: (data) => {
          this.handlePiezometreData(data);
        },
        error: (err) => this.handleError(err),
      });
    } else {
      this.piezometreService.PiezometreStatus().subscribe({
        next: (data) => {
          this.handlePiezometreData(data);
        },
        error: (err) => this.handleError(err),
      });
    }
  }

  private handlePiezometreData(data: PiezometreStatusResponse[]): void {
    this.PiezometreData = data;
    this.filteredPiezometres = data;
    this.addAllMarkers();

    if (this.initialPiezoId !== null) {
      this.handlePiezometreSelection(this.initialPiezoId);
    } else if (this.filteredPiezometres.length > 0) {
      this.handlePiezometreSelection(this.filteredPiezometres[0].id_piezometre);
    }

    this.dataLoaded = true;
    this.handleInitialSelection();
    this.isCardsLoading = false;
    this.spinnerService.clear();
  }

  private handleError(err: any): void {
    console.error('Error in piezometre operations:', err);
    this.isCardsLoading = false;
    this.spinnerService.clear();
  }

  private initMap(): void {
    const mapElement = document.getElementById('map1');
    if (!mapElement) {
      console.error('Map container not found');
      return;
    }
  
    const mapOptions: CustomMapOptions = {
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: 'topleft' as L.ControlPosition,
        title: 'Plein écran',
        titleCancel: 'Quitter le plein écran',
      },
    };
  
    try {
      this.map1 = L.map('map1', mapOptions).setView([32.2862, -6.5499], 15);
      this.initBaseLayers();
      this.initMapControls();
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  }

  private initMapControls(): void {
    if (!this.map1) return;

    this.addPrintControl();
    this.addLegendControl();
    this.addCoordinatesControl();
  }



  private initBaseLayers(): void {
    if (!this.map1) return;

    L.tileLayer(
      'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      { attribution: 'Agro' }
    ).addTo(this.map1);

    L.tileLayer(
      'https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}',
      { attribution: 'Lora' }
    ).addTo(this.map1);

    this.plotLayerGroup = L.layerGroup().addTo(this.map1);
  }


  onCardClick(piezometre: PiezometreStatusResponse): void {
    this.selectedPiezometreData = piezometre;
    this.selectedPiezometreId = piezometre.id_piezometre;
    this.piezometreSelected.emit(piezometre.id_piezometre);

    if (this.map1) {
      const lat = parseFloat(piezometre.latitude);
      const lng = parseFloat(piezometre.longitude);
      
      setTimeout(() => {
        this.map1?.setView([lat, lng], 19);
        this.highlightMarker(piezometre.libelle);
      }, 100);
    }

    this.fetchLastPiezoValue(piezometre.matricule);
  }

  formatDate(date: string | Date): string {
    return DateFormatter.convertToFrenchDate(date);
  }

  trackByPiezo(index: number, piezo: PiezometreStatusResponse): number {
    return piezo.id_piezometre;
  }

  private setupMapControls(): void {
    if (!this.map1) return;

    this.addPrintControl();
    this.addLegendControl();
    this.addCoordinatesControl();
  }

  private addPrintControl(): void {
    if (!this.map1) return;

    const PrintControl = L.Control.extend({
      options: { position: 'topleft' },
      onAdd: () => {
        const container = L.DomUtil.create(
          'div',
          'leaflet-control-zoom leaflet-bar leaflet-control'
        );
        const button = L.DomUtil.create(
          'a',
          'leaflet-control-print',
          container
        ) as HTMLAnchorElement;
        button.href = '#';
        button.title = 'Imprimer';
        button.innerHTML = '<i class="fas fa-print"></i>';

        L.DomEvent.on(button, 'click', (e) => {
          L.DomEvent.preventDefault(e);
          L.DomEvent.stopPropagation(e);
          this.printMap();
        });

        return container;
      },
    });

    new PrintControl().addTo(this.map1);
  }

  private addLegendControl(): void {
    if (!this.map1) return;
    
    const legend = new L.Control({ position: 'bottomleft' });
    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'legend');
      div.innerHTML = `
        <div class="legend-content">
          <div class="legend-item">
            <img src="assets/images/water-meter.png" alt="Compteur" />
            <span>Compteur</span>
          </div>
          <div class="legend-item">
            <img src="assets/images/sea-level.png" alt="Piézomètre" />
            <span>Piézomètre</span>
          </div>
          <div class="legend-item">
            <img src="assets/images/washbasin.png" alt="Bassin" />
            <span>Bassin</span>
          </div>
        </div>
      `;
      return div;
    };
    legend.addTo(this.map1);
  }

  private addCoordinatesControl(): void {
    if (!this.map1) return;

    const CoordinatesControl = L.Control.extend({
      options: { position: 'bottomright' },
      onAdd: () => {
        const container = L.DomUtil.create('div', 'coordinates-control');
        Object.assign(container.style, {
          background: 'rgba(255, 255, 255, 0.8)',
          padding: '5px 10px',
          margin: '10px',
          borderRadius: '4px',
          fontSize: '12px',
          fontFamily: 'Arial',
        });
        container.innerHTML = 'Lng: 0 Lat: 0';
        return container;
      },
    });

    new CoordinatesControl().addTo(this.map1);
    this.setupCoordinatesUpdate();
  }

  private setupCoordinatesUpdate(): void {
    this.map1?.on('mousemove', (e: L.LeafletMouseEvent) => {
      const container = document.querySelector('.coordinates-control');
      if (container) {
        container.innerHTML = `Lng: ${e.latlng.lng.toFixed(4)} Lat: ${e.latlng.lat.toFixed(4)}`;
      }
    });
  }

  private printMap(): void {
    if (!this.map1) return;

    const style = document.createElement('style');
    style.innerHTML = `
      @media print {
        body * { visibility: hidden; }
        #map1, #map1 * { visibility: visible; }
        #map1 {
          position: fixed !important;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
        }
        .leaflet-control-container { display: none !important; }
      }
    `;

    document.head.appendChild(style);
    window.print();
    document.head.removeChild(style);
  }

  private addAllMarkers(): void {
    if (!this.map1) return;

    this.clearMarkers();
    this.initializeSelection();
    this.createMarkers();
    this.highlightInitialSelection();
  }

  private clearMarkers(): void {
    Object.values(this.markerGroup).forEach(({ marker, circle }) => {
      marker.remove();
      circle.remove();
    });
    this.markerGroup = {};
  }

  private initializeSelection(): void {
    if (!this.selectedPiezometreData && this.PiezometreData.length > 0) {
      this.selectedPiezometreData = this.PiezometreData[0];
    }
  }

  private createMarkers(): void {
    this.filteredPiezometres.forEach((piezometre) => {
      if (this.isValidCoordinates(piezometre)) {
        this.createMarker(piezometre);
      }
    });
  }

  private isValidCoordinates(piezometre: PiezometreStatusResponse): boolean {
    return Boolean(piezometre.latitude && piezometre.longitude);
  }

  private createMarker(piezometre: PiezometreStatusResponse): void {
    const id = piezometre.libelle || '';
    const isSelected = this.selectedPiezometreData?.id_piezometre === piezometre.id_piezometre;

    const marker = this.createLeafletMarker(piezometre, isSelected);
    const circle = this.createLeafletCircle(piezometre, isSelected);

    this.markerGroup[id] = { marker, circle };
    marker.on('click', () => this.onCardClick(piezometre));
  }

  private createLeafletMarker(
    piezometre: PiezometreStatusResponse,
    isSelected: boolean
  ): L.Marker {
    const matriculeClass = piezometre.libelle?.toLowerCase().replace(/\s+/g, '-') || '';

    const icon = L.divIcon({
      className: `custom-marker marker-${matriculeClass}`,
      html: `
        <div class="marker-container ${isSelected ? 'selected' : ''}">
          <img src="assets/images/sea-level.png" alt="marker" class="marker-image">
          <div class="marker-label">${piezometre.libelle}</div>
        </div>
      `,
      iconSize: [60, 60],
      iconAnchor: [30, 30],
    });

    const marker = L.marker(
      [parseFloat(piezometre.latitude), parseFloat(piezometre.longitude)],
      {
        icon,
        zIndexOffset: isSelected ? 1000 : 0,
      }
    ).addTo(this.map1!);

    const popupContent = this.createPiezometrePopupContent(piezometre);
    marker.bindPopup(popupContent, {
      maxWidth: 300,
      className: 'custom-popup',
    });

    return marker;
  }

  private createPiezometrePopupContent(piezometre: PiezometreStatusResponse): string {
    return `
      <div class="piezometre-popup">
        <h4>${piezometre.libelle || 'N/A'}</h4>
        <ul>
          <li><strong>Matricule:</strong> ${piezometre.matricule || 'N/A'}</li>
          <li><strong>Nom groupe:</strong> ${piezometre.nom_groupe || 'N/A'}</li>
          <li><strong>Id Piezometre:</strong> ${piezometre.id_piezometre || 'N/A'}</li>
        </ul>
      </div>
    `;
  }

  private createLeafletCircle(
    piezometre: PiezometreStatusResponse,
    isSelected: boolean
  ): L.Circle {
    return L.circle(
      [parseFloat(piezometre.latitude), parseFloat(piezometre.longitude)],
      {
        color: isSelected ? '#4CAF50' : '#4CAF50',
        fillColor: '#4CAF50',
        fillOpacity: isSelected ? 0.4 : 0.2,
        radius: isSelected ? 40 : 30,
        weight: isSelected ? 3 : 2,
      }
    ).addTo(this.map1!);
  }

  private highlightMarker(libelle: string): void {
    if (!libelle || !this.markerGroup[libelle]) return;

    this.resetAllMarkers();
    this.highlightSelectedMarker(libelle);
  }

  private resetAllMarkers(): void {
    Object.values(this.markerGroup).forEach(({ marker, circle }) => {
      circle.setStyle({
        color: '#4CAF50',
        fillColor: '#4CAF50',
        fillOpacity: 0.2,
        weight: 2,
      });

      marker.setZIndexOffset(0);
      const markerElement = marker.getElement();
      if (markerElement) {
        const container = markerElement.querySelector('.marker-container');
        if (container) {
          container.classList.remove('selected');
        }
      }
    });
  }

  private highlightSelectedMarker(libelle: string): void {
    const { marker, circle } = this.markerGroup[libelle];

    circle.setStyle({
      color: '#4CAF50',
      fillColor: '#4CAF50',
      fillOpacity: 0.4,
      weight: 3,
    });

    marker.setZIndexOffset(1000);
    const markerElement = marker.getElement();
    if (markerElement) {
      const container = markerElement.querySelector('.marker-container');
      if (container) {
        container.classList.add('selected');
      }
    }
  }

  private highlightInitialSelection(): void {
    if (this.selectedPiezometreData?.libelle) {
      setTimeout(() => {
        this.highlightMarker(this.selectedPiezometreData!.libelle);
        if (this.selectedPiezometreData && this.map1) {
          this.map1.setView(
            [
              parseFloat(this.selectedPiezometreData.latitude),
              parseFloat(this.selectedPiezometreData.longitude),
            ],
            18
          );
        }
      }, 100);
    }
  }

  getPlot(): void {
    this.counterService.parcelles().subscribe({
      next: (response) => {
        this.Ploat = response;
        this.addPlotsToMap();
      },
      error: (err) => console.error('Error fetching plot data', err),
    });
  }

  private addPlotsToMap(): void {
    if (!this.map1 || !this.Ploat || !this.plotLayerGroup) return;

    this.plotLayerGroup.clearLayers();

    const plotStyle = {
      color: '#FFFFFF',
      weight: 2,
      opacity: 0.8,
      fillOpacity: 0.2,
      fillColor: '#106A77',
    };

    const geoJsonLayer = L.geoJSON(this.Ploat as GeoJSON.FeatureCollection, {
      style: () => plotStyle,
      onEachFeature: (feature, layer) => {
        const popupContent = this.createPlotPopupContent(feature);
        layer.bindPopup(popupContent, {
          maxWidth: 300,
          className: 'custom-popup',
        });
      },
    }).addTo(this.plotLayerGroup);

    if (geoJsonLayer.getBounds().isValid()) {
      this.map1.fitBounds(geoJsonLayer.getBounds());
    }
  }

  private createPlotPopupContent(feature: any): string {
    return `
      <div class="plot-popup">
        <h4 class="plot-title">Plot Information</h4>
        <div class="plot-info">
          <p><strong>Culture:</strong> ${feature.properties?.culture || 'Unknown'}</p>
        </div>
      </div>
    `;
  }

  handlePiezometreSelection(value: number): void {
    if (this.PiezometreData.length > 0) {
      const piezo = this.PiezometreData.find((p) => p.id_piezometre === value);
      if (piezo) {
        this.selectedPiezometreData = piezo;
        this.highlightMarker(piezo.libelle);
        this.centerMapOnPiezo(piezo);
        this.fetchLastPiezoValue(piezo.matricule);
      }
    }
  }

  private centerMapOnPiezo(piezo: PiezometreStatusResponse): void {
    if (this.map1) {
      this.map1.setView(
        [parseFloat(piezo.latitude), parseFloat(piezo.longitude)],
        18
      );
    }
  }

  fetchLastPiezoValue(matricule: string): void {
    this.piezometreService.LastPiezoValue(matricule).subscribe({
      next: (response) => {
        this.waterLevel = `${(response.last / 200) * 40}%`;
      },
      error: (err) => console.error('Error fetching last piezometre value', err),
    });
  }

  ngOnDestroy(): void {
    if (this.map1) {
      this.map1.remove();
    }
  }
}